import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { set, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillXCircleFill } from 'react-icons/bs';
import { FaArrowLeft } from 'react-icons/fa';
import moment from 'moment';

import { Button, Context, FormInput, Modal, Select } from '../../../components';
import { useAxios } from '../../../hooks';
import EditWhoWantScoreTable from './EditWhoWantScoreTable';
import { formInputsData } from './formInputsData';
import WhoWantScoreProgramInputs from './ProgramInputs';

const EditWhoWantScore = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);

	const [gameFormData, setGameFormData] = useState({
		title: '',
		description: '',
		// dateBegin: '',
		// dateEnd: '',
		remainTime: null,
		inGameDescription: '',
		active: false,
		buttonText: '',
		backgroundPattern: '',
		trueAnswerText: '',
		wrongAnswerText: '',
		timeoutAnswerText: '',
	});
	const [currentImage, setCurrentImage] = useState([]);
	const [context] = useContext(Context);

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
	};
	const {
		register: registerSaveGame,
		unregister: unregisterSaveGame,
		watch: watchSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm();

	// program modal
	const [showProgram, setShowProgram] = React.useState(true);
	const [selectedWeekDays, setSelectedWeekDays] = useState('');
	const [selectedMonthDays, setSelectedMonthDays] = useState('');
	const [selectedWeekType, setSelectedWeekType] = useState('');
	const [selectedScoreDate, setSelectedScoreDate] = useState([]);
	const programType = watchSaveGame('type', 'SINGLE');
	const frequency = watchSaveGame('definitionFrequencyType', 'GÜNLÜK');

	const handleMonthDayChange = (day) => {
		if (selectedMonthDays.includes(day)) {
			setSelectedMonthDays('');
		} else {
			setSelectedMonthDays(day);
		}
	};

	useEffect(() => {
		if (frequency !== 'WEEKLY') {
			setSelectedWeekDays('');
		}
	}, [frequency]);

	const handleWeekTypeChange = (type) => {
		console.log(type);
		if (type === 'SAME_QUESTIONS_EVERY_WEEK') {
			setSelectedWeekDays('');
		}
		setSelectedWeekType(type);
	};

	const handleWeekDayChange = (day) => {
		if (selectedWeekDays.includes(day)) {
			setSelectedWeekDays(selectedWeekDays.filter((d) => d !== day));
		} else {
			setSelectedWeekDays([...selectedWeekDays, day]);
		}
	};

	const pageTitle = id ? 'Kim x Puan İster Düzenle' : 'Kim x Puan İster Ekle';

	const formatDate = (date) => {
		const parsedDate = new Date(date);

		if (Number.isNaN(parsedDate.getTime())) {
			return '';
		}

		return parsedDate.toISOString().slice(0, 16);
	};

	const getGames = (gameId) => {
		if (gameId) {
			api.getGames.fetch({
				method: 'GET',
				url: `/game/admin/wws/${gameId}`,
			});
		}
	};

	useEffect(() => {
		getGames(id);
		if (id) {
			setShowProgram(false);
		}
	}, [id]);

	useEffect(() => {
		if (api.getGames.error) toast.error(api.getGames.error.message);
	}, [api.getGames.error]);

	useEffect(() => {
		const data = api.getGames.response?.data;
		if (data) {
			setGameFormData({
				id: data.id || null,
				title: data.title || '',
				description: data.description || '',
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				remainTime: data.remainTime || null,
				inGameDescription: data.inGameDescription || '',
				active: data.active || false,
				buttonText: data.buttonText || '',
				backgroundPattern: data.backgroundPattern || '',
				trueAnswerText: data.trueAnswerText || '',
				wrongAnswerText: data.wrongAnswerText || '',
				timeoutAnswerText: data.timeoutAnswerText || '',
				bonusScore: data.bonusScore || null,
			});

			if (data?.icon) {
				const images = [];
				images.push(data?.icon);
				setCurrentImage(images);
			}
		}
	}, [api.getGames.response?.data]);

	const handleChange = ({ target: { name, value } }) => {
		setGameFormData((prev) => ({ ...prev, [name]: value }));
	};

	const removeImage = (index) => {
		setCurrentImage((prev) => prev.filter((_, i) => i !== index));
	};

	const handleSubmit = (data) => {
		const formData = new FormData();
		let gameData = {};
		const [image] = currentImage;

		if (currentImage.length && currentImage[0]) {
			if (typeof currentImage[0] === 'string') {
				gameFormData.contents = [currentImage[0]];
			} else if (currentImage[0] instanceof File) {
				formData.append('icon', image);
			}
		}
		formData.append(
			'saveAdminWhoWantsScoreDTO',
			JSON.stringify(gameFormData)
		);

		const tenantName =
			localStorage.getItem('tenant') || context?.signed?.data?.tenantName;

		if (programType === 'MULTIPLE') {
			const {
				active,
				backgroundPattern,
				buttonText,
				title,
				description,
				type,
				remainTime,
				brandId,
				inGameDescription,
				trueAnswerText,
				wrongAnswerText,
				timeoutAnswerText,
				questionTitle,
				questionDescription,
				...filteredData
			} = data;
			gameData = {
				...filteredData,
				dateBegin: data?.dateBegin
					? moment.utc(data?.dateBegin).format()
					: '',
				dateEnd: data?.dateEnd
					? moment.utc(data?.dateEnd).format()
					: '',
				...(selectedWeekDays ? { selectedDays: selectedWeekDays } : {}),
				...(selectedWeekType
					? { weeklyFrequencyType: selectedWeekType }
					: {}),
				...(selectedMonthDays
					? { monthlyFrequencyType: selectedMonthDays }
					: {}),
			};
			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/create/program/wws',
				data: gameData,
				headers: {
					'Content-Type': 'application/json',
					'X-Tenant': tenantName,
				},
			});
		} else {
			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/save/wws',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
					'X-Tenant': tenantName,
				},
			});
		}
	};

	const handleDelete = (gameId) => {
		api.removeGame.fetch({
			method: 'POST',
			url: '/game/admin/delete/wws',
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify(gameId),
		});
	};

	useEffect(() => {
		if (api.removeGame.response) {
			setCurrentImage([]);
			setIsModalOpen(false);
			setGameFormData([]);
			toast.success('Başarıyla Silindi.');
			navigate(-1);
		}
	}, [api.removeGame.response]);

	useEffect(() => {
		if (api.saveGame.response) {
			setCurrentImage([]);
			setIsModalOpen(false);
			setGameFormData([]);
			toast.success('Kaydedildi.');
			navigate(-1);
		}
	}, [api.saveGame.response]);

	useEffect(() => {
		resetSaveGame(gameFormData);
	}, [gameFormData]);

	return (
		<div
			className="container mx-auto p-4 m-4"
			style={{ overflow: 'scroll' }}
		>
			<div className="flex  items-center gap-4">
				<button
					type="button"
					className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-full flex items-center gap-2"
					onClick={() => navigate(-1)}
				>
					<FaArrowLeft />
					Geri
				</button>
				<h1 className="text-sm lg:text-xl font-bold">{pageTitle}</h1>
			</div>

			<form onSubmit={handleSubmitSaveGame(handleSubmit)} noValidate>
				<div className="flex justify-end mt-4 lg:px-20 pt-4 space-x-4">
					<Button
						type="submit"
						variant="primary"
						className="py-2 px-4"
					>
						Kaydet
					</Button>
					{id && (
						<Button
							variant="danger"
							className="py-2 px-4"
							onClick={() => setIsConfirmationModalOpen(true)}
						>
							Sil
						</Button>
					)}
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-2 md:px-20 pt-4 my-4">
					{showProgram && (
						<Select
							name="type"
							label="Oyun Türü"
							grid
							options={[
								{
									name: 'Tekli',
									value: 'SINGLE',
								},
								{
									name: 'Çoklu',
									value: 'MULTIPLE',
								},
							]}
							register={registerSaveGame}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							value={programType}
							errors={errorsSaveGame}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					)}
					{programType === 'MULTIPLE' && (
						<WhoWantScoreProgramInputs
							errorsSaveGame={errorsSaveGame}
							registerSaveGame={registerSaveGame}
							selectedWeekDays={selectedWeekDays}
							handleWeekDayChange={handleWeekDayChange}
							selectedMonthDays={selectedMonthDays}
							handleMonthDayChange={handleMonthDayChange}
							selectedScoreDate={selectedScoreDate}
							setSelectedScoreDate={setSelectedScoreDate}
							selectedWeekType={selectedWeekType}
							handleWeekTypeChange={handleWeekTypeChange}
							frequency={frequency}
							programType={programType}
							watchSaveGame={watchSaveGame}
							setValueSaveGame={setValueSaveGame}
						/>
					)}
					{programType === 'SINGLE' && (
						<>
							{formInputsData.map(({ label, name, type }) => (
								<FormInput
									{...registerSaveGame(name, {
										required: 'Bu alan zorunludur.',
									})}
									errors={errorsSaveGame}
									key={name}
									grid
									type={type}
									label={label}
									name={name}
									placeholder="Lütfen giriniz."
									value={gameFormData[name] || ''}
									onChange={(e) => {
										handleChange(e);
										setValueSaveGame(name, e.target.value);
									}}
								/>
							))}

							<Select
								grid
								label="Durum"
								name="active"
								options={[
									{ name: 'Aktif', value: true },
									{ name: 'Pasif', value: false },
								]}
								value={gameFormData.active}
								onChange={handleChange}
							/>

							<div className="mb-4">
								<div className="group">
									<div className="grid grid-cols-3 items-center">
										<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
											Simge
										</label>
										<div className="col-span-2">
											<div className="mb-4 grid grid-cols-4 gap-4">
												{currentImage.length > 0 ? (
													currentImage.map(
														(image, index) => (
															<div
																className="relative inline-block"
																key={index}
															>
																<img
																	src={
																		typeof image ===
																		'object'
																			? URL.createObjectURL(
																					image
																				)
																			: image
																	}
																	alt=""
																	className="aspect-square h-full w-full rounded-lg object-cover shadow bg-slate-50"
																/>
																<button
																	type="button"
																	className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																	onClick={() =>
																		removeImage(
																			index
																		)
																	}
																>
																	<BsFillXCircleFill className="h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
																</button>
															</div>
														)
													)
												) : (
													<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
														Görsel Bulunamadı
													</div>
												)}
											</div>
											<div className="relative">
												<input
													type="file"
													accept="image/*"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setCurrentImage([
																...currentImage,
																event.target
																	.files[0],
															]);
														}
													}}
													className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
												/>
												<button
													type="button"
													className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-sm text-white bg-blue-500 hover:bg-blue-600"
												>
													Yeni Görsel Ekle
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</form>
			<EditWhoWantScoreTable
				setGameFormData={setGameFormData}
				gameFormData={api.getGames.response?.data}
				setIsModalOpen={setIsModalOpen}
				isModalOpen={isModalOpen}
				getGames={getGames}
			/>
			<Modal
				show={isConfirmationModalOpen}
				title="Uyarı"
				onClose={() => setIsConfirmationModalOpen(false)}
			>
				<div className="p-4">
					<p className="text-sm text-slate-500">
						Oyun silindikten sonra geri getirilemez. Onaylıyor
						musunuz?
					</p>
				</div>

				<div className="flex justify-end p-4 space-x-2">
					<Button
						variant="white"
						onClick={() => setIsConfirmationModalOpen(false)}
					>
						Vazgeç
					</Button>
					<Button
						variant="danger"
						onClick={() => {
							handleDelete(gameFormData?.id);
							setIsConfirmationModalOpen(false);
						}}
					>
						Evet
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default EditWhoWantScore;
