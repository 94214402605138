import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import { IoInformationOutline } from 'react-icons/io5';

import {
	Button,
	Input,
	Modal,
	Page,
	Table,
	Tooltip,
	Time,
	Item,
	Select,
	Switch,
	Pagination,
} from '../../components';
import { useAxios } from '../../hooks';
import DatePicker from '../../components/DatePicker';

const StaticPages = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const editorRef = React.useRef(null);
	const [editorValue, setEditorValue] = React.useState();

	const [infoText] = React.useState(`
		<hr>
		<h2>Başlık</h2>
		<h3>İçerik</h3>
		<hr>
	`);

	const {
		register: registerSaveStaticPage,
		watch: watchSaveStaticPage,
		setValue: setValueSaveStaticPage,
		handleSubmit: handleSubmitSaveStaticPage,
		reset: resetSaveStaticPage,
		formState: { errors: errorsSaveStaticPage },
	} = useForm();

	const api = {
		getStaticPageCategories: useAxios(),
		getStaticPages: useAxios(),
		saveStaticPage: useAxios(),
		removeStaticPage: useAxios(),
	};

	const [staticPages, setStaticPages] = React.useState([]);

	// Pagination states
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getStaticPages.response?.data) {
			console.log(api.getStaticPages.response.data);
			setStaticPages(api.getStaticPages.response.data);
			setTotalPages(api.getStaticPages.response.data.totalPages || 1);
		}
	}, [api.getStaticPages.response?.data]);

	/* getStaticPageCategories */

	const getStaticPageCategories = () => {
		api.getStaticPageCategories.fetch({
			method: 'GET',
			url: '/employee/admin/get/all/footerAboutUs',
		});
	};

	React.useEffect(() => {
		if (api.getStaticPageCategories.response) {
			console.log(api.getStaticPageCategories.response);
		}
	}, [api.getStaticPageCategories.response]);

	React.useEffect(() => {
		if (api.getStaticPageCategories.error) {
			toast.error(api.getStaticPageCategories.error.message);
		}
	}, [api.getStaticPageCategories.error]);

	React.useEffect(() => {
		getStaticPageCategories();
	}, []);

	/* getStaticPages */

	const getStaticPages = () => {
		api.getStaticPages.fetch({
			method: 'GET',
			url: `/employee/admin/get/all/aboutUs?page=${page}&size=${size}`,
		});
	};

	React.useEffect(() => {
		if (api.getStaticPages.response) {
			console.log(api.getStaticPages.response);
		}
	}, [api.getStaticPages.response]);

	React.useEffect(() => {
		if (api.getStaticPages.error) {
			toast.error(api.getStaticPages.error.message);
		}
	}, [api.getStaticPages.error]);

	React.useEffect(() => {
		getStaticPages();
	}, [page, size]);

	/* saveStaticPage */

	const saveStaticPage = async (data) => {
		console.log(editorRef);
		const body = {
			...data,
			...(data.id ? { id: parseInt(data.id, 10) } : null),
			description: data.description,
			active: !!(data.active === 'true' || data.active === true),
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
		};

		api.saveStaticPage.fetch({
			method: 'POST',
			url: '/employee/admin/saveAboutUs',
			data: body,
		});
	};

	React.useEffect(() => {
		if (api.saveStaticPage.response) {
			resetSaveStaticPage();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getStaticPages();
		}
	}, [api.saveStaticPage.response]);

	React.useEffect(() => {
		if (api.saveStaticPage.error) {
			toast.error(api.saveStaticPage.error.message);
		}
	}, [api.saveStaticPage.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getStaticPages.response) {
			const { id } = showEdit;
			const item =
				api.getStaticPages.response.data.content[
					api.getStaticPages.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ footerAboutUsId: item.footerAboutUsId },
				{ name: item.name },
				{ title: item.title },
				{ active: item.active },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
			];

			values.forEach((value) => {
				setValueSaveStaticPage(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			setEditorValue(item.description);
		}
	}, [showEdit.id && api.getStaticPages.response]);

	/* removeStaticPage */

	const removeStaticPage = (id) => {
		api.removeStaticPage.fetch({
			method: 'POST',
			url: '/employee/admin/deleteAboutUs',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeStaticPage.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getStaticPages();
		}
	}, [api.removeStaticPage.response]);

	React.useEffect(() => {
		if (api.removeStaticPage.error) {
			toast.error(api.removeStaticPage.error.message);
		}
	}, [api.removeStaticPage.error]);

	/* return */

	return (
		<Page>
			<Page.Header header="Metinler" title="Tüm Statik Sayfalar">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={
					api.getStaticPages.loading ||
					api.getStaticPageCategories.loading
				}
				show={
					!api.getStaticPages.loading &&
					api.getStaticPages.response &&
					!api.getStaticPageCategories.loading &&
					api.getStaticPageCategories.response
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Sayfa',
								'Kategori',
								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{staticPages?.content?.length &&
								staticPages?.content.map((item, index) => (
									<Table.Row key={index}>
										<Table.Column>
											<span className="whitespace-nowrap text-xs font-normal text-slate-400">
												{item.id}
											</span>
										</Table.Column>
										<Table.Column>
											<Item
												title={item.name}
												description={item.title}
												className="w-48 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
											/>
										</Table.Column>
										<Table.Column>
											<div className="flex items-center gap-1 truncate whitespace-nowrap text-xs">
												{item.aboutUsCategoryName}
											</div>
										</Table.Column>
										<Table.Column>
											<Time
												dateBegin={moment(
													item.dateBegin
												).format('DD.MM.YYYY')}
												timeBegin={moment(
													item.dateBegin
												).format('HH:mm:ss')}
												dateEnd={moment(
													item.dateEnd
												).format('DD.MM.YYYY')}
												timeEnd={moment(
													item.dateEnd
												).format('HH:mm:ss')}
											/>
										</Table.Column>
										<Table.Column>
											<Switch
												id={item.id}
												active={item.active}
												list={staticPages.content}
												onChange={setStaticPages}
												saveList={saveStaticPage}
												customPopUpText={false}
											/>
										</Table.Column>
										<Table.Column className="text-right [&_button]:w-20">
											<Button
												size="xs"
												variant="secondary-outline"
												className="inline-flex"
												onClick={() => {
													setShowEdit({
														id: item.id,
													});
												}}
											>
												Düzenle
											</Button>
										</Table.Column>
									</Table.Row>
								))}
						</Table.Body>
					</Table>

					<div className="mt-4">
						<Pagination
							currentPage={page}
							totalPages={totalPages}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={size}
							onPageSizeChange={(newSize) => {
								setSize(newSize);
								setPage(0);
							}}
						/>
					</div>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getStaticPages.loading ||
					api.saveStaticPage.loading ||
					api.removeStaticPage.loading ||
					api.getStaticPageCategories.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setEditorValue();
					resetSaveStaticPage();
				}}
			>
				<form
					onSubmit={handleSubmitSaveStaticPage(saveStaticPage)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						<Select
							name="footerAboutUsId"
							label="Kategori"
							grid
							options={[
								...[{ name: 'Lütfen seçiniz.', value: '' }],
								...(api.getStaticPageCategories.response?.data
									?.content?.length
									? api.getStaticPageCategories.response.data.content.map(
											(item) => ({
												name: item.title,
												value: item.id,
											})
										)
									: []),
							]}
							defaultValue=""
							register={registerSaveStaticPage}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveStaticPage}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<Input
							type="text"
							name="name"
							label="İsim"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveStaticPage}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveStaticPage}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="text"
							name="title"
							label="Başlık"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveStaticPage}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveStaticPage}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<div className="group">
							<div className="grid grid-cols-3 items-center">
								<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
									Metin
								</label>
								<div className="col-span-2">
									<Editor
										apiKey="w6m1gkj9ggeablptn3x0iq29ndl147gv2bcervbedhxksafy"
										initialValue={editorValue}
										onInit={(evt, editor) =>
											(editorRef.current = editor)
										}
										init={{
											height: 240,
											menubar: true,
											plugins: ['code'],
											toolbar:
												'undo redo | blocks | bold italic | code',
											content_style:
												'body { font-size:14px }',
										}}
									/>
								</div>
							</div>
						</div>

						<div className="group">
							<div className="grid grid-cols-3 items-center">
								<div className="col-span-2 col-start-2">
									<Tooltip text={infoText} position="top">
										<button
											type="button"
											className="inline-flex items-center gap-2 text-sm text-slate-600"
										>
											<span className="min-w-8 min-h-8 flex items-center justify-center rounded-full bg-slate-900">
												<IoInformationOutline
													className="text-white"
													size={20}
												/>
											</span>
											Akordiyon yapmak için 2 tane hr
											tagleri arasında h2 ve h3
											etiketlerini kullanabilirsiniz
										</button>
									</Tooltip>
								</div>
							</div>
						</div>
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveStaticPage}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveStaticPage}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<DatePicker
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveStaticPage('dateBegin')}
							setValue={setValueSaveStaticPage}
							register={registerSaveStaticPage}
							errors={errorsSaveStaticPage}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveStaticPage('dateEnd')}
							setValue={setValueSaveStaticPage}
							register={registerSaveStaticPage}
							errors={errorsSaveStaticPage}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveStaticPage}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeStaticPage(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveStaticPage).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default StaticPages;
