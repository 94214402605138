import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { Context, Layout } from './components';
import {
	AcademyCategories,
	AcademyTopics,
	Announcements,
	Brands,
	BrandStyles,
	ContentCategories,
	Contents,
	Dashboard,
	EmployeePhotos,
	Employees,
	ForumBadWords,
	Forums,
	GameLuckyWhell,
	GameGuessPicture,
	GamePuzzleMatch,
	GameScoreHunt,
	GameWordPuzzle,
	GameWhoWantScore,
	NotFound,
	Orders,
	PageDescriptions,
	PlatformParameters,
	PlatformManagement,
	ProductCategories,
	Products,
	ProgramManagement,
	ProgramManagementEdit,
	ProgramTenantComponents,
	ResetPassword,
	Scores,
	ScoreCurrency,
	SignIn,
	SignUp,
	StaticPageCategories,
	StaticPages,
	Surveys,
} from './pages';

import './App.css';
import EditWhoWantScore from './pages/GameWhoWantScore/Edit/EditWhoWantScore';
import ProgramDasboard from './pages/ProgramDasboard';
import EditProgramComponents from './pages/ProgramComponents/Edit/EditProgramComponents';
import ProgramComponents from './pages/ProgramComponents';
import ContentsMagazine from './pages/Contents/Magazine';
import ProductOrders from './pages/Orders/ProductOrders';

const App = () => {
	const [state, setState] = React.useState({});
	const value = React.useMemo(() => [state, setState], [state]);

	if (Object.entries(state).length === 0) {
		const signed = JSON.parse(localStorage.getItem('signed'));
		if (
			signed?.time &&
			signed?.data?.expireSecond &&
			signed?.data?.expireSecond >= (Date.now() - signed.time) / 1000 + 60
		) {
			setState({
				signed,
				state: {},
			});
		} else {
			localStorage.removeItem('signed');
			localStorage.removeItem('tenant');
		}
	}

	return (
		<Context.Provider value={value}>
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<NotFound />} />
					<Route path="/uye-ol" element={<SignUp />} />
					<Route path="/giris-yap" element={<SignIn />} />
					<Route
						path="/parolami-unuttum"
						element={<ResetPassword />}
					/>
					<Route path="/" element={<Layout.Private />}>
						{/* anasayfa */}
						<Route index element={<Dashboard />} />
						{/* program yönetimi */}
						<Route
							path="program-yonetimi"
							element={<ProgramManagement />}
						/>
						<Route
							path="program-yonetimi-duzenle"
							element={<ProgramManagementEdit />}
						/>
						<Route
							path="program-yonetimi-duzenle/:id"
							element={<ProgramManagementEdit />}
						/>
						{/* puanli urunler */}
						<Route path="puanli-urunler" element={<Products />} />
						<Route
							path="puanli-urun-kategorileri"
							element={<ProductCategories />}
						/>
						{/* siparisler */}
						<Route path="puanpara" element={<ScoreCurrency />} />
						{/* siparisler */}
						<Route path="odul-siparislerim" element={<Orders />} />
						<Route
							path="urun-siparislerim"
							element={<ProductOrders />}
						/>
						{/* uye islemleri */}
						<Route path="uyeler" element={<Employees />} />
						<Route
							path="uye-fotograf-onaylari"
							element={<EmployeePhotos />}
						/>
						{/* puan islemleri */}
						<Route path="puanlar" element={<Scores />} />
						{/* anketler */}
						<Route path="duyurular" element={<Announcements />} />
						{/* anketler */}
						<Route path="anketler" element={<Surveys />} />
						{/* forum */}
						<Route path="forumlar" element={<Forums />} />
						<Route
							path="forum-filtreli-kelimeler"
							element={<ForumBadWords />}
						/>
						{/* markalar */}
						<Route path="markalar" element={<Brands />} />
						<Route path="marka-urunleri" element={<BrandStyles />} />
						{/* icerikler */}
						<Route path="icerikler-tv" element={<Contents />} />
						<Route
							path="icerikler-dergi"
							element={<ContentsMagazine />}
						/>
						<Route
							path="icerik-kategorileri"
							element={<ContentCategories />}
						/>
						{/* akademi */}
						<Route
							path="egitim-konulari"
							element={<AcademyTopics />}
						/>
						<Route
							path="egitim-kategorileri"
							element={<AcademyCategories />}
						/>
						{/* metinler */}
						<Route
							path="sayfa-aciklamalari"
							element={<PageDescriptions />}
						/>
						<Route
							path="statik-sayfalar"
							element={<StaticPages />}
						/>
						<Route
							path="statik-sayfa-kategorileri"
							element={<StaticPageCategories />}
						/>
						{/* oyunlar */}
						<Route path="esini-bul" element={<GamePuzzleMatch />} />
						<Route path="puan-avi" element={<GameScoreHunt />} />
						<Route
							path="kelime-bulmaca"
							element={<GameWordPuzzle />}
						/>
						<Route path="soru-carki" element={<GameLuckyWhell />} />
						<Route
							path="resmi-tahmin-et"
							element={<GameGuessPicture />}
						/>
						<Route
							path="kim-x-puan-ister"
							element={<GameWhoWantScore />}
						/>
						<Route
							path="edit-who-want-score/:id"
							element={<EditWhoWantScore />}
						/>
						<Route
							path="edit-who-want-score"
							element={<EditWhoWantScore />}
						/>
						{/* program-bilesenleri */}
						<Route
							path="program-bilesenleri"
							element={<ProgramComponents />}
						/>
						<Route
							path="duzenle-program-bilesenleri/:id"
							element={<EditProgramComponents />}
						/>
						<Route
							path="ekle-program-bilesenleri"
							element={<EditProgramComponents />}
						/>
						{/* platform parametreleri */}
						<Route
							path="/platform-parametreleri"
							element={<PlatformParameters />}
						/>
						<Route
							path="/program-dashboard"
							element={<ProgramDasboard />}
						/>
						{/* For All Users */}
						<Route
							path="/platform-yonetimi"
							element={<PlatformManagement />}
						/>
						<Route
							path="bilesenler"
							element={<ProgramTenantComponents />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>

			<Toaster
				position="top-center"
				toastOptions={{
					success: {
						className: 'bg-green-500 text-white text-sm rounded-lg',
						icon: false,
					},
					error: {
						className:
							'bg-red-500 text-white text-sm rounded-lg w-auto max-w-full cursor-pointer',
						icon: false,
					},
				}}
			/>

			{process.env.NODE_ENV === 'development' && (
				<div className="text-white bg-red-500 fixed bottom-2 right-2 flex h-8 w-8 items-center justify-center rounded-md text-sm">
					<div className="hidden max-sm:block">N</div>
					<div className="hidden sm:max-md:block">sm</div>
					<div className="hidden md:max-lg:block">md</div>
					<div className="hidden lg:max-xl:block">lg</div>
					<div className="hidden xl:max-2xl:block">xl</div>
					<div className="hidden 2xl:block">2xl</div>
				</div>
			)}
		</Context.Provider>
	);
};

export default App;
