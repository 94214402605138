import { useState, useEffect } from 'react';

const usePermission = () => {
	const [userPermissions, setUserPermissions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const storedPermissions = localStorage.getItem('userPermissions');

		if (storedPermissions) {
			try {
				const parsedPermissions = JSON.parse(storedPermissions);
				setUserPermissions(parsedPermissions);
			} catch (error) {
				console.error('Yetkiler yüklenirken hata oluştu:', error);
				setUserPermissions([]);
			}
		}
		setIsLoading(false);
	}, []);

	const hasPermission = (permission) => {
		if (isLoading) return true;
		if (!userPermissions || userPermissions.length === 0) return false;
		return userPermissions.some(
			(p) => p?.componentName === permission && p?.active
		);
	};

	return { hasPermission, isLoading };
};

export default usePermission;
