import {
	IoLayersOutline,
	IoGiftOutline,
	IoCartOutline,
	IoPersonCircleOutline,
	IoCheckboxOutline,
	IoCogOutline,
	IoDocumentTextOutline,
	IoChatbubblesOutline,
	IoMegaphoneOutline,
	IoCubeOutline,
	IoSchoolOutline,
	IoGameControllerOutline,
	IoReorderThreeOutline,
	IoExtensionPuzzleSharp,
} from 'react-icons/io5';
import { BiSolidComponent } from 'react-icons/bi';

export const getMenuItems = (superAdmin, permissions = []) => {
	const hasPermission = (permission) =>
		permissions?.some((p) => p.componentName === permission && p.active);
	const allMenuItems = [
		{
			items: [
				{
					name: 'Anasayfa',
					icon: <IoLayersOutline />,
					url: '/',
				},
				{
					name: 'Platform Parametreleri',
					icon: <IoReorderThreeOutline />,
					url: '/platform-parametreleri',
				},
			],
		},
		...(superAdmin === 'SUPERADMIN'
			? [
					{
						name: 'Ayarlar',
						items: [
							{
								name: 'Program Yönetimi',
								icon: <IoCogOutline />,
								url: '/program-yonetimi',
							},
							{
								name: 'Platform Yönetimi',
								icon: <IoCogOutline />,
								url: '/platform-yonetimi',
							},
							// {
							// 	name: 'Program Bileşenleri',
							// 	icon: <BiSolidComponent />,
							// 	url: '/program-bilesenleri',
							// },
						],
					},
				]
			: [
					{
						name: 'Ayarlar',
						items: [
							{
								name: 'Bileşenler',
								icon: <IoExtensionPuzzleSharp />,
								url: '/bilesenler',
							},
							{
								name: 'Platform Yönetimi',
								icon: <IoCogOutline />,
								url: '/platform-yonetimi',
							},
						],
					},
				]),
		...(hasPermission('ÜRÜNLER')
			? [
					{
						name: 'Katalog',
						items: [
							{
								name: 'Puanlı Ürünler',
								icon: <IoGiftOutline />,
								items: [
									{
										name: 'Tüm Ürünler',
										url: '/puanli-urunler',
									},
									{
										name: 'Yeni Ürün Ekle',
										url: '/puanli-urunler?edit',
									},
									{
										name: 'Ürün Kategorileri',
										url: 'puanli-urun-kategorileri',
									},
									{
										name: 'Yeni Kategori Ekle',
										url: 'puanli-urun-kategorileri?edit',
									},
								],
							},
							{
								name: 'Siparişler',
								icon: <IoCartOutline />,
								items: [
									{
										name: 'Ödül Siparişlerim',
										url: '/odul-siparislerim',
									},
									{
										name: 'Ürün Siparişlerim',
										url: '/urun-siparislerim',
									},
									{
										name: 'Yeni Sipariş Ekle',
										url: '/odul-siparislerim?edit',
									},
								],
							},
							{
								name: 'Puan - Para',
								icon: <IoCartOutline />,
								items: [
									{
										name: 'Tüm Puan - Para Ayarları',
										url: '/puanpara',
									},
									{
										name: 'Yeni Ayar Ekle',
										url: '/puanpara?edit',
									},
								],
							},
						],
					},
				]
			: []),
		{
			name: 'Üye',
			items: [
				{
					name: 'Üye İşlemleri',
					icon: <IoPersonCircleOutline />,
					items: [
						{
							name: 'Tüm Üyeler',
							url: '/uyeler',
						},
						{
							name: 'Yeni Üye Ekle',
							url: '/uyeler?edit',
						},
						{
							name: 'Fotoğraf Onayları',
							url: '/uye-fotograf-onaylari',
						},
					],
				},
				// {
				// 	name: 'Puan İşlemleri',
				// 	icon: <IoTrophyOutline />,
				// 	items: [
				// 		{
				// 			name: 'Puan Hareketleri',
				// 			url: '/puanlar',
				// 		},
				// 	],
				// },
			],
		},
		{
			name: 'Portal',
			items: [
				...(hasPermission('DUYURU')
					? [
							{
								name: 'Duyurular',
								icon: <IoMegaphoneOutline />,
								items: [
									{
										name: 'Tüm Duyurular',
										url: '/duyurular',
									},
									{
										name: 'Yeni Duyuru Ekle',
										url: '/duyurular?edit',
									},
								],
							},
						]
					: []),
				...(hasPermission('ANKET')
					? [
							{
								name: 'Anketler',
								icon: <IoCheckboxOutline />,
								items: [
									{
										name: 'Tüm Anketler',
										url: '/anketler',
									},
									{
										name: 'Yeni Anket Ekle',
										url: '/anketler?edit',
									},
								],
							},
						]
					: []),
				{
					name: 'İçerikler',
					icon: <IoDocumentTextOutline />,
					items: [
						{
							name: 'TV İçerikleri',
							url: '/icerikler-tv',
						},
						{
							name: 'Dergi İçerikleri',
							url: '/icerikler-dergi',
						},
						{
							name: 'İçerik Kategorileri',
							url: '/icerik-kategorileri',
						},
						{
							name: 'Yeni Kategori Ekle',
							url: '/icerik-kategorileri?edit',
						},
					],
				},
				...(hasPermission('BİZİM MEYDAN')
					? [
							{
								name: 'Forum',
								icon: <IoChatbubblesOutline />,
								items: [
									{
										name: 'Tüm Konular',
										url: '/forumlar',
									},
									{
										name: 'Yeni Konu Ekle',
										url: '/forumlar?edit',
									},
									{
										name: 'Filtreli Kelimeler',
										url: '/forum-filtreli-kelimeler',
									},
									{
										name: 'Yeni Filtreli Kelime Ekle',
										url: '/forum-filtreli-kelimeler?edit',
									},
								],
							},
						]
					: []),
			],
		},
		{
			name: 'Metinler',
			icon: <IoDocumentTextOutline />,
			items: [
				{
					name: 'Tüm Statik Sayfalar',
					url: '/statik-sayfalar',
				},
				{
					name: 'Statik Sayfa Kategorileri',
					url: '/statik-sayfa-kategorileri',
				},
				{
					name: 'Tüm Sayfa Açıklamaları',
					url: '/sayfa-aciklamalari',
				},
			],
		},
		...(hasPermission('MARKALAR')
			? [
					{
						name: 'Kurumsal',
						items: [
							{
								name: 'Markalar',
								icon: <IoCubeOutline />,
								items: [
									{
										name: 'Tüm Markalar',
										url: '/markalar',
									},
									{
										name: 'Marka Ürünleri',
										url: '/marka-urunleri',
									},
									{
										name: 'Yeni Marka Ekle',
										url: '/markalar?edit',
									},
								],
							},
						],
					},
				]
			: []),
		{
			name: 'Akademi',
			icon: <IoSchoolOutline />,
			items: [
				{
					name: 'Tüm Konular',
					url: '/egitim-konulari',
				},
				{
					name: 'Yeni Konu Ekle',
					url: '/egitim-konulari?edit',
				},
				{
					name: 'Eğitim Kategorileri',
					url: '/egitim-kategorileri',
				},
				{
					name: 'Yeni Kategori Ekle',
					url: '/egitim-kategorileri?edit',
				},
			],
		},
		{
			name: 'Oyunlar',
			icon: <IoGameControllerOutline />,
			items: [
				...(hasPermission('EŞLE KAZAN')
					? [
							{
								name: 'Eşini Bul',
								url: '/esini-bul',
							},
						]
					: []),
				...(hasPermission('KELİME AVI')
					? [
							{
								name: 'Kelime Bulmaca',
								url: '/kelime-bulmaca',
							},
						]
					: []),
				...(hasPermission('PUAN AVI')
					? [
							{
								name: 'Puan Avı',
								url: '/puan-avi',
							},
						]
					: []),
				...(hasPermission('RESMİ TAHMİN ET')
					? [
							{
								name: 'Resmi Tahmin Et',
								url: '/resmi-tahmin-et',
							},
						]
					: []),
				...(hasPermission('ŞANS ÇARKI')
					? [
							{
								name: 'Soru Çarkı',
								url: '/soru-carki',
							},
						]
					: []),
				...(hasPermission('KİM 1000 PUAN İSTER')
					? [
							{
								name: 'Kim X Puan İster',
								url: '/kim-x-puan-ister',
							},
						]
					: []),
			],
		},
	];

	return allMenuItems;
};
