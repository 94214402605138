import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/dark.css';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { IoAlertCircle } from 'react-icons/io5';
import moment from 'moment';

const DatePicker = ({
	name,
	label,
	grid,
	errors,
	className,
	size = 'lg',
	placeholder,
	disabled,
	readOnly,
	setValue,
	value,
}) => {
	const sizes = {
		xs: 'h-7 px-2 text-xs',
		sm: 'h-9 px-2.5 text-sm',
		md: 'h-10 px-3 text-sm',
		lg: 'h-12 px-3.5 text-base',
		xl: 'h-14 px-4 text-lg',
	};

	const [selectedDate, setSelectedDate] = useState(
		value ? new Date(value) : null
	);

	useEffect(() => {
		if (value) {
			setSelectedDate(new Date(value));
		}
	}, [value]);

	return (
		<div
			className={`group 
                ${errors && errors[name] ? 'invalid' : ''} 
                ${(className && className.group) || ''}
            `}
		>
			<div className={grid ? 'grid grid-cols-3 items-center' : ''}>
				{label && (
					<label
						className={`flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500 
                            ${grid ? 'col-span-1' : 'mb-2'}
                            ${(className && className.label) || ''}
                        `}
					>
						{label}
					</label>
				)}
				<Flatpickr
					key={selectedDate}
					value={
						value
							? new Date(value)
							: moment(Date.now()).format('YYYY-MM-DD HH:mm')
					}
					name={name}
					onChange={(date) => {
						setSelectedDate(date);
						setValue(name, date[0]);
					}}
					options={{
						dateFormat: 'Y-m-d H:i',
						enableTime: true,
						locale: Turkish,
					}}
					className={`relative z-10 rounded-lg border-2 placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-red-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500 
                        ${grid ? 'col-span-2' : 'w-full'}
                        ${sizes[size]}
                        ${(className && className.input) || ''}
                        custom-flatpickr
                    `}
					placeholder={placeholder}
					disabled={disabled}
					readOnly={readOnly}
				/>
			</div>
			{errors && errors[name] && (
				<div
					className={`
                        relative -top-2 inline-flex gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500
                        ${grid ? 'left-1/3 w-2/3' : 'w-full'}
                    `}
				>
					<IoAlertCircle className="h-5 w-5 shrink-0" />
					{errors[name].message}
				</div>
			)}
		</div>
	);
};

export default DatePicker;
