/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAxios } from '../../hooks';
import ProgramManagementTable from './ProgramManagementTable';
import { Context, Page } from '../../components';

const ProgramManagement = () => {
	const [showEdit, setShowEdit] = useState(false);
	const navigate = useNavigate();
	const [context, setContext] = useContext(Context);
	const superAdmin = context?.signed?.data?.employeeFirm?.role;

	useEffect(() => {
		if (superAdmin !== 'SUPERADMIN') {
			navigate('/');
		}
	}, [superAdmin]);

	const api = {
		getProgramList: useAxios(),
		saveProgram: useAxios(),
		removeProgram: useAxios(),
		getBrandStyles: useAxios(),
	};

	const getProgramList = () => {
		api.getProgramList.fetch({
			method: 'GET',
			url: '/management/admin/get/all/programManagement',
		});
	};

	useEffect(() => {
		if (api.getProgramList.error) {
			toast.error(api.getProgramList.error.message);
		}
	}, [api.getProgramList.error]);

	useEffect(() => {
		getProgramList();
	}, []);

	useEffect(() => {
		if (api.saveProgram.response) {
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getProgramList();
		}
	}, [api.saveProgram.response]);

	return (
		<Page className="p-4 md:p-6 lg:p-8">
			<Page.Header
				header="Ayarlar"
				title="Program Yönetimi"
				className="flex flex-col md:flex-row items-center justify-between gap-4"
			>
				<Page.Header.Add
					show
					onClick={() => navigate('/program-yonetimi-duzenle')}
					className="w-full md:w-auto"
				/>
			</Page.Header>
			<Page.Body
				className="w-full"
				loading={
					api.getProgramList.loading || api.getBrandStyles.loading
				}
				show={
					!api.getProgramList.loading && api.getProgramList.response
				}
			>
				<div className="overflow-x-auto">
					<ProgramManagementTable
						saveProgram={() => setShowEdit(true)}
						api={api}
						setShowEdit={setShowEdit}
						className="min-w-[300px] w-full"
					/>
				</div>
			</Page.Body>
			<Page.Footer className="mt-4" />
		</Page>
	);
};

export default ProgramManagement;
