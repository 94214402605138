import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Filler,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2'; // Hem Bar hem Pie burada tek satırda import edilsin
import { Button, Context, Page } from '../../components';
import { useAxios } from '../../hooks';

ChartJS.register(
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	PointElement,
	LineElement,
	Filler
);

const ProgramDashboard = () => {
	const [dashboardItems, setDashboardItems] = React.useState();
	const [activeComponentList, setActiveComponentList] = React.useState();
	const [context] = useContext(Context);
	const superAdmin = context?.signed?.data?.employeeFirm?.role;
	const navigate = useNavigate();

	useEffect(() => {
		if (superAdmin !== 'SUPERADMIN') {
			navigate('/');
		}
	}, [superAdmin]);

	const api = {
		getDashboard: useAxios(),
		getActiveComponent: useAxios(),
	};

	const hasPermission = (permission) =>
		activeComponentList?.some(
			(p) => p.componentName === permission && p.active
		);

	const getDashboard = () => {
		api.getDashboard.fetch({
			method: 'GET',
			url: '/management/admin/get/program/dashboard',
		});
	};

	const getActiveComponentList = () => {
		api.getActiveComponent.fetch({
			method: 'GET',
			url: '/management/get/active/componentList',
		});
	};

	React.useEffect(() => {
		getDashboard();
		getActiveComponentList();
	}, []);

	React.useEffect(() => {
		if (api.getActiveComponent.response?.data) {
			setActiveComponentList(api.getActiveComponent?.response?.data);
		}
	}, [api.getActiveComponent.response?.data]);

	React.useEffect(() => {
		if (api.getDashboard.response?.data) {
			const { data } = api.getDashboard.response;
			setDashboardItems([
				{
					id: 'anket',
					name: 'Anket',
					active: hasPermission('ANKET'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Anket
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalSurveySize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeSurveySize ||
																0,
															data.passiveSurveySize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Anket
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeSurveySize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeSurveySize ||
																0,
															data.totalSurveySize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Anket
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveSurveySize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveSurveySize ||
																0,
															data.activeSurveySize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Toplam Kazanılacak puan
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalSurveyScoreSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: [
													'Aktif Puan',
													'Pasif Puan',
												],
												datasets: [
													{
														data: [
															data.activeSurveyScoreSize ||
																0,
															data.passiveSurveyScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalSurveySize ===
								data.activeSurveySize ? (
									<p className="text-green-600 text-sm">
										Anketleriniz Tanımlandı
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Anket listenizdeki tüm Anketler
										aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/anketler');
										}}
									>
										Anketler
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/anketler?edit');
										}}
									>
										Anket Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'kelime-bulmaca',
					name: 'Kelime Bulmaca',
					active: hasPermission('KELİME AVI'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Kelime Bulmaca
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalHangmanSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeHangmanSize ||
																0,
															data.passiveHangmanSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Kelime Bulmaca
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeHangmanSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeHangmanSize ||
																0,
															data.passiveHangmanSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Kelime Bulmaca
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveHangmanSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveHangmanSize ||
																0,
															data.activeHangmanSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Toplam Kazanılacak puan
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalHangmanScoreSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: [
													'Aktif Puan',
													'Pasif Puan',
												],
												datasets: [
													{
														data: [
															data.activeHangmanScoreSize ||
																0,
															data.passiveHangmanScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalHangmanSize ===
								data.activeHangmanSize ? (
									<p className="text-green-600 text-sm">
										Kelime Bulmaca tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Kelime Bulmaca listenizdeki tüm kelime
										bulmaca oyunları aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/kelime-bulmaca');
										}}
									>
										Kelime Bulmaca Oyunları
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/kelime-bulmaca?edit');
										}}
									>
										Kelime Bulmaca Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'resim-tahmin',
					name: 'Resmi Tahmin Et',
					active: hasPermission('RESMİ TAHMİN ET'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Resmi Tahmin Et
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalGuessPictureSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeGuessPictureSize ||
																0,
															data.passiveGuessPictureSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Resmi Tahmin Et
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeGuessPictureSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeGuessPictureSize ||
																0,
															data.passiveGuessPictureSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Resmi Tahmin Et
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveGuessPictureSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveGuessPictureSize ||
																0,
															data.activeGuessPictureSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Toplam Kazanılacak puan
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalGuessPictureScoreSize ||
												0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: [
													'Aktif Puan',
													'Pasif Puan',
												],
												datasets: [
													{
														data: [
															data.activeGuessPictureScoreSize ||
																0,
															data.passiveGuessPictureScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalGuessPictureSize ===
								data.activeGuessPictureSize ? (
									<p className="text-green-600 text-sm">
										Resmi Tahmin Et oyunları tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Resmi Tahmin Et listenizdeki tüm Resmi
										Tahmin Et oyunları aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/resmi-tahmin-et');
										}}
									>
										Resmi Tahmin Et Oyunları
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/resmi-tahmin-et?edit');
										}}
									>
										Resmi Tahmin Et Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'soru-carki',
					name: 'Soru Çarkı',
					active: hasPermission('ŞANS ÇARKI'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Soru Çarkı
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalLuckyWheelSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeLuckyWheelSize ||
																0,
															data.passiveLuckyWheelSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Soru Çarkı
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeLuckyWheelSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeLuckyWheelSize ||
																0,
															data.totalLuckyWheelSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Soru Çarkı
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveLuckyWheelSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveLuckyWheelSize ||
																0,
															data.activeLuckyWheelSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalLuckyWheelSize ===
								data.activeLuckyWheelSize ? (
									<p className="text-green-600 text-sm">
										Soru Çarkı oyunları tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Soru Çarkı listenizdeki tüm Soru Çarkı
										oyunları aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/soru-carki');
										}}
									>
										Soru Çarkı Oyunları
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/soru-carki?edit');
										}}
									>
										Soru Çarkı Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'puan-avi',
					name: 'Puan Avı',
					active: hasPermission('PUAN AVI'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Puan Avı
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalScoreHuntSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeScoreHuntSize ||
																0,
															data.passiveScoreHuntSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Puan Avı
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeScoreHuntSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeScoreHuntSize ||
																0,
															data.totalScoreHuntSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Puan Avı
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveScoreHuntSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveScoreHuntSize ||
																0,
															data.activeScoreHuntSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Toplam Kazanılacak puan
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalScoreHuntScoreSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeScoreHuntScoreSize ||
																0,
															data.passiveScoreHuntScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalScoreHuntSize ===
								data.activeScoreHuntSize ? (
									<p className="text-green-600 text-sm">
										Puan Avı oyunları tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Puan Avı oyunları listenizdeki tüm Puan
										Avı oyunları aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/puan-avi');
										}}
									>
										Puan Avı Oyunları
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/puan-avi?edit');
										}}
									>
										Puan Avı Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'who-wants-score',
					name: 'Kim X Puan İster',
					active: hasPermission('KİM 1000 PUAN İSTER'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Kim X Puan İster
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalWhoWantsScoreSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeWhoWantsScoreSize ||
																0,
															data.passiveWhoWantsScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Kim X Puan İster
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeWhoWantsScoreSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeWhoWantsScoreSize ||
																0,
															data.totalWhoWantsScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Kim X Puan İster
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveWhoWantsScoreSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveWhoWantsScoreSize ||
																0,
															data.activeWhoWantsScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalWhoWantsScoreSize ===
								data.activeWhoWantsScoreSize ? (
									<p className="text-green-600 text-sm">
										Kim X Puan İster oyunları tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Kim X Puan İster listenizdeki tüm
										oyunlar aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/kim-x-puan-ister');
										}}
									>
										Kim X Puan İster Oyunları
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/edit-who-want-score');
										}}
									>
										Kim X Puan İster Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'puzzle-match',
					name: 'Puzzle Eşleştirme',
					active: hasPermission('EŞLE KAZAN'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Eşini Bul
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalPuzzleMatchSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activePuzzleMatchSize ||
																0,
															data.passivePuzzleMatchSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Eşini Bul
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activePuzzleMatchSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activePuzzleMatchSize ||
																0,
															data.passivePuzzleMatchSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Eşini Bul
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passivePuzzleMatchSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passivePuzzleMatchSize ||
																0,
															data.activePuzzleMatchSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Toplam Kazanılacak puan
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalPuzzleMatchScoreSize ||
												0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: [
													'Aktif Puan',
													'Pasif Puan',
												],
												datasets: [
													{
														data: [
															data.activePuzzleMatchScoreSize ||
																0,
															data.passivePuzzleMatchScoreSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalPuzzleMatchSize ===
								data.activePuzzleMatchSize ? (
									<p className="text-green-600 text-sm">
										Eşini Bul oyunları tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Eşini Bul listenizdeki tüm oyunlar
										aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/esini-bul');
										}}
									>
										Eşini Bul Oyunları
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/esini-bul?edit');
										}}
									>
										Eşini Bul Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'markalar',
					name: 'Markalar',
					active: hasPermission('MARKALAR'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Markalar
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalBrandSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeBrandSize ||
																0,
															data.passiveBrandSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Markalar
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeBrandSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeBrandSize ||
																0,
															data.passiveBrandSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Markalar
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveBrandSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveBrandSize ||
																0,
															data.totalBrandSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalBrandSize ===
								data.activeBrandSize ? (
									<p className="text-green-600 text-sm">
										Markalarınız tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Markalar listenizdeki tüm Markalar
										aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/markalar');
										}}
									>
										Markalar
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/markalar?edit');
										}}
									>
										Marka Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'forum',
					name: 'Forum',
					active: hasPermission('FORUM'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Forum
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalForumSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeForumSize ||
																0,
															data.passiveForumSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Forum
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeForumSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeForumSize ||
																0,
															data.totalForumSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Forum
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveForumSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveForumSize ||
																0,
															data.activeForumSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalForumSize ===
								data.activeForumSize ? (
									<p className="text-green-600 text-sm">
										Forumlar tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Forumlar listenizdeki tüm Forumlar
										aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/forumlar');
										}}
									>
										Forumlar
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/forumlar?edit');
										}}
									>
										Forum Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'dergi',
					name: 'Dergi',
					active: hasPermission('DERGİ'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Dergi
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalMagazineSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeMagazineSize ||
																0,
															data.passiveMagazineSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif Dergi
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeMagazineSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeMagazineSize ||
																0,
															data.totalMagazineSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif Dergi
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveMagazineSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveMagazineSize ||
																0,
															data.activeMagazineSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalMagazineSize ===
								data.activeMagazineSize ? (
									<p className="text-green-600 text-sm">
										Dergiler tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										Dergiler listenizdeki tüm Dergi
										içerikleri aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/icerikler');
										}}
									>
										Dergiler
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/icerikler?edit');
										}}
									>
										Dergi Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'tv',
					name: 'TV',
					active: hasPermission('TV'),
					content: (
						<div className="flex flex-col">
							<div className="flex flex-wrap md:flex-nowrap gap-4 min-h-[260px] md:min-h-[160px]">
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											TV
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.totalTvSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Pasif'],
												datasets: [
													{
														data: [
															data.activeTvSize ||
																0,
															data.passiveTvSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(239 68 68)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Aktif TV
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.activeTvSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Aktif', 'Toplam'],
												datasets: [
													{
														data: [
															data.activeTvSize ||
																0,
															data.passiveTvSize ||
																0,
														],
														backgroundColor: [
															'rgb(22 163 74)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
								<div className="flex-1 flex items-center gap-4 rounded-lg p-6 shadow bg-white min-h-[160px]">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Pasif TV
										</h6>
										<div className="truncate text-3xl font-medium">
											{data.passiveTvSize || 0}
										</div>
									</div>
									<div className="ml-auto shrink-0">
										<Doughnut
											options={{
												maintainAspectRatio: false,
												plugins: {
													legend: {
														display: false,
													},
												},
											}}
											data={{
												labels: ['Pasif', 'Toplam'],
												datasets: [
													{
														data: [
															data.passiveTvSize ||
																0,
															data.activeTvSize ||
																0,
														],
														backgroundColor: [
															'rgb(239 68 68)',
															'rgb(226 232 240)',
														],
														borderWidth: 0,
													},
												],
											}}
											className="-my-12 h-32 w-32"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-between w-full py-4">
								{data.totalTvSize === data.activeTvSize ? (
									<p className="text-green-600 text-sm">
										TV'ler tanımlandı.
									</p>
								) : (
									<p className="text-red-600 text-sm">
										TV'ler listenizdeki tüm Tv İçerikler
										aktifleştirilmelidir!
									</p>
								)}

								<div className="mt-2 md:mt-0">
									<Button
										size="xs"
										className="inline-flex mr-3"
										onClick={() => {
											navigate('/icerikler');
										}}
									>
										TV'ler
									</Button>

									<Button
										size="xs"
										className="inline-flex"
										variant="primary"
										onClick={() => {
											navigate('/icerikler?edit');
										}}
									>
										TV Ekle
									</Button>
								</div>
							</div>
						</div>
					),
				},
			]);
		}
	}, [api.getDashboard.response]);

	return (
		<Page>
			<Page.Header header="Ayarlar" title="Program Yönetimi Dashboard" />
			<Page.Body loading={false} show>
				<Page.Dashboard items={dashboardItems} size="xxl" />
			</Page.Body>
			<Page.Footer />
		</Page>
	);
};

export default ProgramDashboard;
