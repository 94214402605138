import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAxios } from '../../hooks';
import ProgramComponentsTable from './ProgramComponentsTable';
import { Page } from '../../components';

const ProgramComponents = () => {
	const [showEdit, setShowEdit] = useState(false);
	const navigate = useNavigate();

	const api = {
		getComponentList: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	const getGames = () => {
		api.getComponentList.fetch({
			method: 'GET',
			url: '/management/superadmin/get/component/list',
		});
	};

	useEffect(() => {
		if (api.getComponentList.error) {
			toast.error(api.getComponentList.error.message);
		}
	}, [api.getComponentList.error]);

	useEffect(() => {
		getGames();
	}, []);

	useEffect(() => {
		if (api.saveGame.response) {
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	return (
		<Page className="p-4 md:p-6 lg:p-8">
			<Page.Header
				header="Program"
				title="Program Bileşenleri"
				className="flex flex-col md:flex-row items-center justify-between gap-4"
			>
				<Page.Header.Add
					show
					onClick={() => navigate('/ekle-program-bilesenleri')}
					className="w-full md:w-auto"
				/>
			</Page.Header>
			<Page.Body
				className="w-full"
				loading={
					api.getComponentList.loading || api.getBrandStyles.loading
				}
				show={
					!api.getComponentList.loading &&
					api.getComponentList.response
				}
			>
				<div className="overflow-x-auto">
					<ProgramComponentsTable
						saveGame={() => setShowEdit(true)}
						api={api}
						setShowEdit={setShowEdit}
						className="min-w-[300px] w-full"
					/>
				</div>
			</Page.Body>
			<Page.Footer className="mt-4" />
		</Page>
	);
};

export default ProgramComponents;
