import React from 'react';
import { BiDownload } from 'react-icons/bi';
import Button from '../../../Button';

const Template = ({ fileUrl, fileName, show, className, buttonText }) => {
	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = fileName || 'template.xlsx'; // Varsayılan dosya adı
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		show && (
			<Button variant="tertiary" className={className} size="sm" onClick={handleDownload}>
				<BiDownload className="h-4 w-4" />
				{buttonText}
			</Button>
		)
	);
};

export default Template;
