import React from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import {
	Button,
	Switch,
	Page,
	Table,
	Modal,
	Input,
	Context,
	Select,
} from '../../components';
import { useAxios } from '../../hooks';

const PlatformParameters = () => {
	const [parameters, setParameters] = React.useState([]);
	const [context] = React.useContext(Context);
	const [showEdit, setShowEdit] = React.useState(false);
	const [platformParameters, setPlatformParameters] = React.useState([
		{
			name: 'Puan + Para',
			value: 'SCORE_CURRENCY_CONVERSION',
		},
		{
			name: 'Ürün Satış',
			value: 'PRODUCT_SALES',
		},
	]);

	const {
		register: registerSaveParam,
		handleSubmit: handleSubmitSaveParams,
		reset: resetSaveParam,
		formState: { errorsSaveParam },
	} = useForm();

	const api = {
		getParameters: useAxios(),
		saveParameter: useAxios(),
		removeParam: useAxios(),
	};

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getParameters.response?.data?.content) {
			setParameters(api.getParameters.response.data.content);
		}
	}, [api.getParameters.response]);

	// Debug için response'u kontrol et
	React.useEffect(() => {
		if (api.getParameters.response) {
			// console.log('Parameters State:', parameters);
		}
	}, [api.getParameters.response, parameters]);

	// Parametreleri getirme
	const getParameters = () => {
		console.log('getParameters çağrıldı'); // Debug log 1
		api.getParameters.fetch({
			method: 'GET',
			url: '/management/admin/getPlatformParameters',
			params: {
				page: 0,
				size: 9999,
			},
			headers: {
				'X-Tenant': localStorage.getItem('tenant')
					? localStorage.getItem('tenant')
					: context?.signed?.data?.tenantName,
			},
		});
	};

	React.useEffect(() => {
		getParameters();
	}, []);

	React.useEffect(() => {
		if (api.getParameters.response) {
			console.log(api.getParameters.response);
		}
	}, [api.getParameters.response]);

	React.useEffect(() => {
		if (api.getParameters.error) {
			toast.error(api.getParameters.error.message);
		}
	}, [api.getParameters.error]);

	// Parameters state'ini kontrol et
	React.useEffect(() => {
		console.log('Current parameters:11', parameters); // Debug log 4
	}, [parameters]);

	// Hata kontrolü
	React.useEffect(() => {
		if (api.getParameters.error) {
			console.error('API Error:', api.getParameters.error); // Debug log 6
			toast.error(api.getParameters.error.message);
		}
	}, [api.getParameters.error]);

	console.log('API Response Structure:', {
		hasData: !!api.getParameters.response?.data,
		isArray: Array.isArray(api.getParameters.response?.data),
		hasPageContent: !!api.getParameters.response?.data?.page?.content,
		hasContent: !!api.getParameters.response?.data?.content,
		fullResponse: api.getParameters.response,
	});

	// Parametre kaydetme
	const saveParameter = async (data) => {
		const body = {
			...data,
			active: !!(data.active === 'true' || data.active === true),
			platformParametersType: data.name,
		};

		api.saveParameter.fetch({
			method: 'POST',
			url: '/management/admin/save/scoreCurrencyToPlatformParameters',
			data: body,
			headers: {
				'X-Tenant': localStorage.getItem('tenant')
					? localStorage.getItem('tenant')
					: context?.signed?.data?.tenantName,
			},
		});
	};

	React.useEffect(() => {
		if (api.saveParameter.response) {
			resetSaveParam();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getParameters();
		}
	}, [api.saveParameter.response]);

	React.useEffect(() => {
		if (showEdit.id && api.getParameters.response) {
			const { id } = showEdit;
			const item =
				api.getParameters.response.data.content[
					api.getParameters.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
		}
	}, [showEdit, api.getParameters.response]);

	/* remove forum */

	const removeParam = (id) => {
		api.removeParam.fetch({
			method: 'POST',
			url: `/management/admin/delete/platformParameter/?platformParameterId=${id}`,
		});
	};

	React.useEffect(() => {
		if (api.removeParam.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getParameters();
		}
	}, [api.removeParam.response]);

	React.useEffect(() => {
		if (api.removeParam.error) {
			toast.error(api.removeParam.error.message);
		}
	}, [api.removeParam.error]);

	// Switch komponenti için ayrı bir save metodu ekleyelim
	const handleSwitchChange = async (id) => {
		// Mevcut parametreyi bul
		const currentParam = parameters.find((param) => param.id === id);
		if (currentParam) {
			const body = {
				id: currentParam.id,
				platformParametersType: currentParam.platformParametersType,
				active: !currentParam.active, // Mevcut durumun tersini gönder
			};

			api.saveParameter.fetch({
				method: 'POST',
				url: '/management/admin/save/scoreCurrencyToPlatformParameters',
				data: body,
				headers: {
					'X-Tenant': localStorage.getItem('tenant')
						? localStorage.getItem('tenant')
						: context?.signed?.data?.tenantName,
				},
			});
		}
	};

	return (
		<Page>
			<Page.Header header="Ayarlar" title="Platform Parametreleri" />
			<Page.Body
				loading={api.getParameters.loading}
				show={!api.getParameters.loading}
			>
				<Page.Content>
					<Table>
						<Table.Head data={['Id', 'Parametre Tipi', 'Durum']} />
						<Table.Body>
							{parameters?.length > 0 ? (
								parameters?.map((item, index) => (
									<Table.Row key={index}>
										<Table.Column>
											<span className="whitespace-nowrap text-xs font-normal text-slate-400">
												{item.id}
											</span>
										</Table.Column>
										<Table.Column>
											{platformParameters.find(
												(i) =>
													i.value ===
													item.platformParametersType
											)?.name ||
												item.platformParametersType}
										</Table.Column>
										<Table.Column>
											<Switch
												id={item.id}
												active={item.active}
												onChange={() =>
													handleSwitchChange(item.id)
												}
												customPopUpText
											/>
										</Table.Column>
									</Table.Row>
								))
							) : (
								<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
									Herhangi bir data bulunamadı.
								</div>
							)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />
			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getParameters.loading ||
					api.saveParameter.loading ||
					api.removeParam.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveParam();
				}}
			>
				<form
					onSubmit={handleSubmitSaveParams(saveParameter)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						<Select
							name="name"
							label="Tip"
							grid
							options={platformParameters}
							defaultValue
							register={registerSaveParam}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveParam}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveParam}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeParam(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								errorsSaveParam &&
								Object.keys(errorsSaveParam).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default PlatformParameters;
