import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Button,
	Context,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Switch,
	Table,
	Time,
	Pagination,
} from '../../components';
import { useAxios } from '../../hooks';
import GuessPictureProgramInputs from './ProgramInputs';
import DatePicker from '../../components/DatePicker';
import usePermission from '../../hooks/usePermission';

const GameGuessPicture = () => {
	const [context] = React.useContext(Context);
	const [showEdit, setShowEdit] = React.useState(false);
	const [choices, setChoices] = React.useState([]);
	const [trueChoice, setTrueChoice] = React.useState(0);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [params, setParams] = useSearchParams();
	const [withTime, setWithTime] = React.useState(false);

	// Pagination state'lerini ekleyelim
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	const {
		register: registerSaveGame,
		unregister: unregisterSaveGame,
		watch: watchSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm();

	// program modal
	const [showProgram, setShowProgram] = React.useState(true);
	const [selectedWeekDays, setSelectedWeekDays] = useState('');
	const [selectedMonthDays, setSelectedMonthDays] = useState('');
	const [selectedWeekType, setSelectedWeekType] = useState('');
	const [selectedScoreDate, setSelectedScoreDate] = useState([]);
	const programType = watchSaveGame('type', 'SINGLE');
	const frequency = watchSaveGame('definitionFrequencyType', 'GÜNLÜK');

	const handleMonthDayChange = (day) => {
		console.log(day);
		if (selectedMonthDays.includes(day)) {
			setSelectedMonthDays('');
		} else {
			setSelectedMonthDays(day);
		}
	};

	useEffect(() => {
		if (frequency !== 'WEEKLY') {
			setSelectedWeekDays('');
		}
	}, [frequency]);

	const handleWeekTypeChange = (type) => {
		console.log(type);
		if (type === 'SAME_QUESTIONS_EVERY_WEEK') {
			setSelectedWeekDays('');
		}
		setSelectedWeekType(type);
	};

	const handleWeekDayChange = (day) => {
		if (selectedWeekDays.includes(day)) {
			setSelectedWeekDays(selectedWeekDays.filter((d) => d !== day));
		} else {
			setSelectedWeekDays([...selectedWeekDays, day]);
		}
	};

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	const [games, setGames] = React.useState([]); // Listeyi state olarak tut

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getGames.response?.data) {
			setGames(api.getGames.response.data);
			setTotalPages(api.getGames.response.data.totalPages || 1);
		}
	}, [api.getGames.response?.data]);

	/* getGames */

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: `/game/admin/getGuessPictureList?page=${page}&size=${size}`,
		});
	};

	React.useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	React.useEffect(() => {
		getGames();
	}, [page, size]);

	/* saveGame */
	const saveGame = async (data, isSwitch) => {
		console.log(data);
		const formData = new FormData();
		const choicesResponse = [];
		let surveyData = {};

		if (isSwitch !== 'switch' && programType === 'SINGLE') {
			// Seçenek sayısı kontrolü
			if (choices.length < 2 || choices.length > 4) {
				toast.error('Seçenek sayısı 2 ile 4 arasında olmalıdır.');
				return;
			}

			choices.forEach((choice, index) => {
				choicesResponse.push({
					id: choice.id || undefined,
					description:
						data.guessPictureChoiceDTOList[index].description,
					isTrue: choice.isTrue,
				});
			});
		}

		formData.append(
			'saveGuessPictureDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				withTime,
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				guessPictureChoiceDTOList:
					choicesResponse.length > 0
						? choicesResponse
						: data.guessPictureChoiceDTOList,
				...(currentImage.length &&
				currentImage[0] &&
				typeof currentImage[0] === 'string'
					? { contents: [currentImage[0]] }
					: null),
			})
		);

		if (programType === 'MULTIPLE') {
			const {
				active,
				backgroundPattern,
				buttonText,
				title,
				description,
				type,
				inGameDescription,
				trueAnswerText,
				wrongAnswerText,
				timeoutAnswerText,
				questionTitle,
				questionDescription,
				...filteredData
			} = data;
			surveyData = {
				...filteredData,
				dateBegin: data.dateBegin
					? moment.utc(data.dateBegin).format()
					: '',
				dateEnd: data.dateEnd ? moment.utc(data.dateEnd).format() : '',
				...(selectedWeekDays ? { selectedDays: selectedWeekDays } : {}),
				...(selectedWeekType
					? { weeklyFrequencyType: selectedWeekType }
					: {}),
				...(selectedMonthDays
					? { monthlyFrequencyType: selectedMonthDays }
					: {}),
			};
		}

		if (
			currentImage.length &&
			currentImage[0] &&
			currentImage[0] instanceof File
		) {
			formData.append('icon', currentImage[0]);
		}

		if (programType === 'MULTIPLE') {
			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/create/program/guessPicture',
				data: surveyData,
				headers: {
					'Content-Type': 'application/json',
					'X-Tenant': localStorage.getItem('tenant')
						? localStorage.getItem('tenant')
						: context?.signed?.data?.tenantName,
				},
			});
		} else {
			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/saveGuessPicture',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
					'X-Tenant': localStorage.getItem('tenant')
						? localStorage.getItem('tenant')
						: context?.signed?.data?.tenantName,
				},
			});
		}
	};

	React.useEffect(() => {
		if (api.saveGame.response) {
			resetSaveGame();
			setChoices([]);
			setTrueChoice(0);
			setCurrentImage([]);
			setShowEdit(false);
			setShowProgram(true);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	React.useEffect(() => {
		if (api.saveGame.error) {
			toast.error(api.saveGame.error.message);
		}
	}, [api.saveGame.error]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
			setShowProgram(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getGames.response) {
			const { id } = showEdit;
			const item =
				api.getGames.response.data.content[
					api.getGames.response.data.content.findIndex(
						(object) => object.id === id
					)
				];

			// values array'ini oluştururken undefined değerleri filtrele
			const values = [
				{ title: item.title },
				{ description: item.description },
				{ buttonText: item.buttonText },
				{ inGameDescription: item.inGameDescription },
				{ backgroundPattern: item.backgroundPattern },
				{ trueAnswerText: item.trueAnswerText },
				{ wrongAnswerText: item.wrongAnswerText },
				{ timeoutAnswerText: item.timeoutAnswerText },
				{ withTime: item.withTime },
				// Koşullu değeri doğrudan object olarak ekle
				item.withTime && item.remainTime
					? { remainTime: item.remainTime }
					: null,
				{ score: item.score },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
				{ questionTitle: item.questionTitle },
				{ questionDescription: item.questionDescription },
			].filter(Boolean); // null ve undefined değerleri filtrele

			// Her bir değeri form state'e set et
			values.forEach((value) => {
				if (value && Object.keys(value).length > 0) {
					setValueSaveGame(
						Object.keys(value)[0],
						Object.values(value)[0],
						{ shouldValidate: true }
					);
				}
			});

			if (item.guessPictureChoiceDTOList) {
				const guessPictureChoiceDTOList = [];
				item.guessPictureChoiceDTOList.forEach((value, index) => {
					guessPictureChoiceDTOList.push({
						id: value.id,
						description: value.description,
						isTrue: value.isTrue,
					});
					if (value.isTrue) {
						setTrueChoice(index);
					}
				});
				setChoices(guessPictureChoiceDTOList);
			}

			if (item.icon) {
				const images = [];
				images.push(item.icon);
				setCurrentImage(images);
			}
		}
	}, [showEdit.id && api.getGames.response]);

	/* removeGame */

	const removeGame = (id) => {
		api.removeGame.fetch({
			method: 'POST',
			url: '/game/admin/delete/guessPicture',
			data: id,
		});
	};

	const handleAddChoice = () => {
		if (choices.length >= 4) {
			toast.error('En fazla 4 seçenek eklenebilir.');
			return;
		}
		const newChoice = {
			description: '',
			isTrue: false,
		};
		setChoices([...choices, newChoice]);
		setValueSaveGame(
			`guessPictureChoiceDTOList.${choices.length}.description`,
			''
		);
	};

	const handleRemoveChoice = (index) => {
		if (choices.length <= 2) {
			toast.error('En az 2 seçenek olmalıdır.');
			return;
		}
		const choicesTemp = [...choices];
		choicesTemp.splice(index, 1);

		// Önce eski form değerini unregister et
		unregisterSaveGame(`guessPictureChoiceDTOList.${index}.description`);

		// Yeni choices array'ini set et
		setChoices(choicesTemp);

		// Kalan seçeneklerin form değerlerini güncelle
		choicesTemp.forEach((choice, newIndex) => {
			setValueSaveGame(
				`guessPictureChoiceDTOList.${newIndex}.description`,
				choice.description
			);
		});
	};

	React.useEffect(() => {
		if (api.removeGame.response) {
			setShowEdit(false);
			setShowProgram(true);
			toast.success('Silindi.');
			getGames();
		}
	}, [api.removeGame.response]);

	React.useEffect(() => {
		if (api.removeGame.error) {
			toast.error(api.removeGame.error.message);
		}
	}, [api.removeGame.error]);

	const { hasPermission, isLoading } = usePermission();

	if (!isLoading && !hasPermission('RESMİ TAHMİN ET')) {
		return <Navigate to="/" />;
	}

	return (
		<Page>
			<Page.Header header="Oyunlar" title="Resmi Tahmin Et">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getGames.loading}
				show={!api.getGames.loading && api.getGames.response}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Oyun', 'Tarih', 'Durum', '']}
						/>
						<Table.Body>
							{games?.content?.length &&
								games?.content.map((item, index) => (
									<Table.Row key={index}>
										<Table.Column>
											<span className="text-slate-400 whitespace-nowrap text-xs font-normal">
												{item.id}
											</span>
										</Table.Column>
										<Table.Column>
											<Item
												image={
													item.icon
														? item.icon
														: require('../../assets/logo-connection.png')
												}
												title={item.title}
												description={item.description}
												className="w-96 [&_img]:bg-white [&_img]:object-contain [&_img]:p-2"
											/>
										</Table.Column>
										<Table.Column>
											<Time
												dateBegin={moment(
													item.dateBegin
												).format('DD.MM.YYYY')}
												timeBegin={moment(
													item.dateBegin
												).format('HH:mm:ss')}
												dateEnd={moment(
													item.dateEnd
												).format('DD.MM.YYYY')}
												timeEnd={moment(
													item.dateEnd
												).format('HH:mm:ss')}
											/>
										</Table.Column>
										<Table.Column>
											<Switch
												id={item.id}
												active={item.active}
												list={games.content}
												onChange={setGames}
												saveList={saveGame}
												customPopUpText={false}
											/>
										</Table.Column>
										<Table.Column className="text-right [&_button]:w-20">
											<Button
												size="xs"
												variant="secondary-outline"
												className="inline-flex"
												onClick={() => {
													setShowEdit({
														id: item.id,
													});
													setShowProgram(false);
												}}
											>
												Düzenle
											</Button>
										</Table.Column>
									</Table.Row>
								))}
						</Table.Body>
					</Table>

					<div className="mt-4">
						<Pagination
							currentPage={page}
							totalPages={totalPages}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={size}
							onPageSizeChange={(newSize) => {
								setSize(newSize);
								setPage(0);
							}}
						/>
					</div>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getGames.loading ||
					api.saveGame.loading ||
					api.removeGame.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setShowProgram(true);
					setCurrentImage([]);
					resetSaveGame();
				}}
			>
				<form onSubmit={handleSubmitSaveGame(saveGame)} noValidate>
					<div className="px-6">
						{showProgram && (
							<Modal.Section title="Oyun Türü">
								<Select
									name="type"
									label="Oyun Türü"
									grid
									options={[
										{
											name: 'Tekli',
											value: 'SINGLE',
										},
										{
											name: 'Çoklu',
											value: 'MULTIPLE',
										},
									]}
									register={registerSaveGame}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									value={programType}
									errors={errorsSaveGame}
									size="lg"
									className={{ select: '!text-sm' }}
								/>

								{programType === 'MULTIPLE' && (
									<GuessPictureProgramInputs
										errorsSaveGame={errorsSaveGame}
										registerSaveGame={registerSaveGame}
										selectedWeekDays={selectedWeekDays}
										handleWeekDayChange={
											handleWeekDayChange
										}
										selectedMonthDays={selectedMonthDays}
										handleMonthDayChange={
											handleMonthDayChange
										}
										selectedScoreDate={selectedScoreDate}
										setSelectedScoreDate={
											setSelectedScoreDate
										}
										selectedWeekType={selectedWeekType}
										handleWeekTypeChange={
											handleWeekTypeChange
										}
										frequency={frequency}
										programType={programType}
										watchSaveGame={watchSaveGame}
										setValueSaveGame={setValueSaveGame}
									/>
								)}
							</Modal.Section>
						)}
						{programType === 'SINGLE' && (
							<div>
								<Modal.Section title="İçerik">
									<Input
										type="text"
										name="title"
										label="Başlık"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="description"
										label="Açıklama"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="number"
										name="score"
										label="Puan"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>

									<DatePicker
										name="dateBegin"
										label="Başlangıç Tarihi"
										grid
										placeholder="Lütfen giriniz."
										value={watchSaveGame('dateBegin')}
										setValue={setValueSaveGame}
										register={registerSaveGame}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<DatePicker
										name="dateEnd"
										label="Bitiş Tarihi"
										grid
										placeholder="Lütfen giriniz."
										value={watchSaveGame('dateEnd')}
										setValue={setValueSaveGame}
										register={registerSaveGame}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>

									<Select
										name="active"
										label="Oyun Türü"
										grid
										options={[
											{
												name: 'Süresiz',
												value: false,
											},
											{
												name: 'Süreli',
												value: true,
											},
										]}
										onChange={(e) => {
											if (e.target.value === 'true') {
												setWithTime(true);
											} else {
												setWithTime(false);
												unregisterSaveGame(
													'remainTime'
												);
											}
										}}
										defaultValue={withTime}
										size="lg"
										className={{ select: '!text-sm' }}
									/>

									{withTime === true && (
										<Input
											type="text"
											name="remainTime"
											label="Kalan Süre"
											grid
											placeholder="Lütfen giriniz."
											register={registerSaveGame}
											validation={{
												required: withTime
													? 'Bu alan zorunludur.'
													: false,
											}}
											errors={errorsSaveGame}
											size="lg"
											className={{ input: '!text-sm' }}
										/>
									)}

									<Input
										type="text"
										name="inGameDescription"
										label="Oyun İçi Açıklama"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Select
										name="active"
										label="Durum"
										grid
										options={[
											{
												name: 'Aktif',
												value: true,
											},
											{
												name: 'Pasif',
												value: false,
											},
										]}
										defaultValue
										register={registerSaveGame}
										size="lg"
										className={{ select: '!text-sm' }}
									/>
								</Modal.Section>

								<Modal.Section title="Görünüm">
									<Input
										type="text"
										name="buttonText"
										label="Düğme Metni"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="backgroundPattern"
										label="Arkaplan Rengi"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										defaultValue="#"
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="trueAnswerText"
										label="Doğru Yanıt Metni"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="wrongAnswerText"
										label="Yanlış Yanıt Metni"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="timeoutAnswerText"
										label="Zaman Aşımı Metni"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
								</Modal.Section>

								<Modal.Section title="Soru">
									<Input
										type="text"
										name="questionTitle"
										label="Soru Başlığı"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="questionDescription"
										label="Soru Açıklaması"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
								</Modal.Section>

								<Modal.Section title="Seçenekler">
									{choices.length ? (
										choices.map((choice, choiceIndex) => (
											<div
												className="relative pr-8"
												key={choiceIndex}
											>
												<Input
													type="text"
													name={`guessPictureChoiceDTOList.${choiceIndex}.description`}
													label={`Seçenek ${choiceIndex + 1}`}
													grid
													placeholder="Lütfen giriniz."
													defaultValue={
														choice.description
													}
													register={registerSaveGame}
													validation={{
														required:
															'Bu alan zorunludur.',
													}}
													errors={errorsSaveGame}
													size="lg"
													className={{
														input: '!text-sm',
													}}
													onChange={(e) => {
														const updatedChoices = [
															...choices,
														];
														updatedChoices[
															choiceIndex
														] = {
															...updatedChoices[
																choiceIndex
															],
															description:
																e.target.value,
														};
														setChoices(
															updatedChoices
														);
													}}
												/>
												<div className="mb-4 mt-2 grid grid-cols-3">
													<div className="col-span-2 col-start-2 flex items-center gap-2 text-sm">
														<input
															className="cursor-pointer"
															id={`checkbox-${choiceIndex}`}
															type="checkbox"
															checked={
																trueChoice ===
																choiceIndex
															}
															onChange={(
																event
															) => {
																if (
																	event.target
																		.checked
																) {
																	setTrueChoice(
																		choiceIndex
																	);
																	const updatedChoices =
																		choices.map(
																			(
																				c,
																				idx
																			) => ({
																				...c,
																				isTrue:
																					idx ===
																					choiceIndex,
																			})
																		);
																	setChoices(
																		updatedChoices
																	);
																}
															}}
														/>
														<label
															className="cursor-pointer"
															htmlFor={`checkbox-${choiceIndex}`}
														>
															Doğru yanıt
														</label>
													</div>
												</div>
												<button
													className="absolute right-0 top-3.5"
													type="button"
													onClick={() =>
														handleRemoveChoice(
															choiceIndex
														)
													}
												>
													<BsFillXCircleFill className="translate bg-white border-white text-red-500 h-5 w-5 rounded-full border-2" />
												</button>
											</div>
										))
									) : (
										<div className="text-slate-400 bg-slate-50 rounded-md p-3 text-center text-xs">
											Seçenek bulunamadı
										</div>
									)}

									<button
										className={`text-white bg-blue-500 flex h-9 w-full items-center
											justify-center whitespace-nowrap rounded-lg border-2 border-transparent px-3.5 text-center
											text-sm font-normal leading-none transition duration-300 hover:bg-blue-600
										`}
										type="button"
										onClick={() => {
											handleAddChoice();
										}}
									>
										Yeni Seçenek Ekle
									</button>
								</Modal.Section>

								<Modal.Section title="Görsel">
									{/* images start */}
									<div className="group">
										<div className="grid grid-cols-3 items-center">
											<label className="text-slate-700 col-span-1 flex gap-1 text-sm font-normal">
												Simge
											</label>
											<div className="col-span-2">
												<div className="mb-4 grid grid-cols-4 gap-4">
													{currentImage &&
													currentImage.length ? (
														currentImage.map(
															(image, index) => (
																<div
																	className="relative inline-block"
																	key={index}
																>
																	<img
																		src={
																			typeof image ===
																			'object'
																				? window.URL.createObjectURL(
																						image
																					)
																				: image
																		}
																		key={
																			index
																		}
																		alt=""
																		className="bg-slate-50 aspect-square h-full
															w-full rounded-lg object-cover shadow"
																	/>
																	<button
																		type="button"
																		className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																		onClick={() =>
																			removeImage(
																				index
																			)
																		}
																	>
																		<BsFillXCircleFill
																			className="translate bg-white border-white
															text-red-500 h-5 w-5 rounded-full border-2"
																		/>
																	</button>
																</div>
															)
														)
													) : (
														<div
															className="text-slate-400 bg-slate-50 col-span-4 rounded-md
											p-3 text-center text-xs"
														>
															Görsel Bulunamadı
														</div>
													)}
												</div>
												<div className="relative">
													<input
														type="file"
														accept="image/*"
														onChange={(event) => {
															if (
																event?.target
																	?.files?.[0]
															) {
																setCurrentImage(
																	[
																		...currentImage,
																		event
																			.target
																			.files[0],
																	]
																);
															}
														}}
														className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
													/>
													{!currentImage.length && (
														<div
															className={`text-white bg-blue-500 flex h-9 w-full items-center
                                                        justify-center whitespace-nowrap rounded-lg border-2 border-transparent px-3.5 text-center
                                                        text-sm
                                                        font-normal leading-none transition duration-300 hover:bg-blue-600
                                                    `}
														>
															Yeni Görsel Ekle
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									{/* images end */}
								</Modal.Section>
							</div>
						)}
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveGame}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="border-slate-100 flex items-center justify-center gap-3 border-t-2 px-4 py-3">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeGame(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveGame).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default GameGuessPicture;
