import React from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAxios } from '../../hooks';
import {
	Button,
	Input,
	Modal,
	Page,
	Select,
	Table,
	Pagination,
	Badge,
} from '../../components';

const ScoreCurrency = () => {
	// const [dashboardItems, setDashboardItems] = React.useState();
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();
	// State for pagination
	const [page, setPageNumber] = React.useState(0);
	const [size, setPageSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	const {
		register: registersaveScoreCurrency,
		setValue: setValuesaveScoreCurrency,
		handleSubmit: handleSubmitsaveScoreCurrency,
		reset: resetsaveScoreCurrency,
		watch,
		formState: { errors: errorssaveScoreCurrency },
	} = useForm();

	const api = {
		getConversions: useAxios(),
		saveScoreCurrency: useAxios(),
	};

	const getConversions = () => {
		api.getConversions.fetch({
			method: 'GET',
			url: '/management/admin/scoreCurrencyList',
			params: {
				page,
				size,
			},
		});
	};

	const saveScoreCurrency = async (data) => {
		const body = {
			...data,

			value: parseFloat(data.value),
			usageType: data.usageType,
			description: data.description,
			minimumScoreRatioForPayment: parseInt(
				data.minimumScoreRatioForPayment,
				10
			),
		};
		api.saveScoreCurrency.fetch({
			method: 'POST',
			url: '/management/admin/saveCurrencyConversion',
			data: body,
		});
	};

	React.useEffect(() => {
		getConversions();
	}, []);

	React.useEffect(() => {
		getConversions();
	}, [page, size]);

	React.useEffect(() => {
		if (api.getConversions.response) {
			setTotalPages(api.getConversions.response.data.totalPages);
		}
	}, [api.getConversions.response]);

	React.useEffect(() => {
		if (api.getConversions.error) {
			toast.error(api.getConversions.error.message);
		}
	}, [api.getConversions.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getConversions.response) {
			const { id } = showEdit;
			const item =
				api.getConversions.response.data.content[
					api.getConversions.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ value: item.value },
				{ usageType: item.usageType },
				{
					minimumScoreRatioForPayment:
						item.minimumScoreRatioForPayment,
				},
				{ description: item.description },
			];

			values.forEach((value) => {
				setValuesaveScoreCurrency(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEdit && api.getConversions.response]);

	React.useEffect(() => {
		if (api.saveScoreCurrency.response) {
			resetsaveScoreCurrency();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getConversions();
		}
	}, [api.saveScoreCurrency.response]);

	React.useEffect(() => {
		if (api.saveScoreCurrency.error) {
			toast.error(api.saveScoreCurrency.error.message);
		}
	}, [api.saveScoreCurrency.error]);

	return (
		<Page>
			<Page.Header header="Para - Puan" title="Tüm Para - Puan Ayarları">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getConversions.loading}
				show={
					api.getConversions.response &&
					api.getConversions.loading === false
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'TL Değeri',
								'İşlem Tipi',
								'Minimum Puan Yüzdesi',
								'Açıklama',
								'',
							]}
						/>
						<Table.Body>
							{api.getConversions.response?.data?.content
								?.length &&
								api.getConversions.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column className="text-base font-medium">
												{parseFloat(item.value).toFixed(
													1
												)}{' '}
											</Table.Column>
											<Table.Column>
												<Badge
													variant={
														{
															ORDER: 'Sipariş',
															DISCOUNT: 'İndirim',
														}[item.usageType]
													}
												>
													{
														{
															ORDER: 'Sipariş',
															DISCOUNT: 'İndirim',
														}[item.usageType]
													}
												</Badge>
											</Table.Column>
											<Table.Column className="text-base font-medium">
												{
													item.minimumScoreRatioForPayment
												}
											</Table.Column>
											<Table.Column className="w-48 !whitespace-normal text-xs text-slate-400">
												{item.description}
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
					<Pagination
						currentPage={page}
						totalPages={totalPages}
						onPageChange={(newPage) => setPageNumber(newPage)}
						pageSize={size}
						onPageSizeChange={(newSize) => setPageSize(newSize)}
					/>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getConversions.loading || api.saveScoreCurrency.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetsaveScoreCurrency();
				}}
			>
				<form
					onSubmit={handleSubmitsaveScoreCurrency(saveScoreCurrency)}
					noValidate
				>
					<div className="px-6">
						<Modal.Section title="Para - Puan">
							<Input
								name="value"
								label="TL Değeri"
								grid
								placeholder="Lütfen giriniz."
								type="text" // Metin tipi kullanılır, çünkü ondalık girişler kontrol edilecek
								register={registersaveScoreCurrency}
								validation={{
									required: 'Bu alan zorunludur.',
									validate: (value) =>
										/^[0-9]+([.,][0-9]{1,2})?$/.test(
											value
										) || 'Geçerli bir ondalık sayı girin.',
								}}
								onChange={(e) => {
									const value = e.target.value.replace(
										',',
										'.'
									); // Virgülü noktaya çevir
									setValuesaveScoreCurrency('value', value); // Değeri güncelle
								}}
								errors={errorssaveScoreCurrency}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="usageType"
								label="İşlem Tipi"
								grid
								options={[
									{ name: 'Lütfen seçiniz.', value: '' },
									{ name: 'Sipariş', value: 'ORDER' },
									{ name: 'İndirim', value: 'DISCOUNT' },
								]}
								value={watch('usageType')} // Formun mevcut değerini bağlama
								defaultValue="" // İlk yüklendiğinde boş değer
								register={registersaveScoreCurrency}
								validation={{
									required: 'Bu alan zorunludur.',
									onChange: (e) => {
										setValuesaveScoreCurrency(
											'usageType',
											e.target.value
										); // Değeri güncelle
									},
								}}
								errors={errorssaveScoreCurrency}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							<Input
								name="minimumScoreRatioForPayment"
								label="Minimum Puan Harcama Yüzdesi"
								grid
								placeholder="Lütfen giriniz."
								type="number"
								register={registersaveScoreCurrency}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorssaveScoreCurrency}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="description"
								label="Açıklama"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registersaveScoreCurrency}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorssaveScoreCurrency}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registersaveScoreCurrency}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorssaveScoreCurrency).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default ScoreCurrency;
