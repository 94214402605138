import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { BsFillXCircleFill, BsCheckCircleFill } from 'react-icons/bs';

import { useAxios } from '../../hooks';
import {
	Button,
	Context,
	Input,
	Item,
	Modal,
	Page,
	Table,
	Select,
	Switch,
	Time,
	Pagination,
} from '../../components';
import ProgramInputs from './ProgramInputs';
import DatePicker from '../../components/DatePicker';
import usePermission from '../../hooks/usePermission';

ChartJS.register(ArcElement);

const Surveys = () => {
	const [context] = React.useContext(Context);
	const [showEdit, setShowEdit] = React.useState(false);
	const [showProgram, setShowProgram] = React.useState(true);
	const [showResult, setShowResult] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const [selectedWeekDays, setSelectedWeekDays] = useState('');
	const [selectedMonthDays, setSelectedMonthDays] = useState('');
	const [selectedWeekType, setSelectedWeekType] = useState('');
	const [selectedScoreDate, setSelectedScoreDate] = useState([]);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [surveys, setSurveys] = React.useState([]);
	const { hasPermission, isLoading } = usePermission();

	// State for pagination
	const [page, setPageNumber] = React.useState(0);
	const [size, setPageSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	const {
		register: registerSaveSurvey,
		watch: watchSaveSurvey,
		setValue: setValueSaveSurvey,
		handleSubmit: handleSubmitSaveSurvey,
		reset: resetSaveSurvey,
		formState: { errors: errorsSaveSurvey },
	} = useForm();

	const programType = watchSaveSurvey('type', 'GENEL');
	const frequency = watchSaveSurvey('definitionFrequencyType', 'GÜNLÜK');
	const dateBegin = watchSaveSurvey(
		'dateBegin',
		moment(Date.now()).format('YYYY-MM-DD HH:mm')
	);

	useEffect(() => {
		console.log(dateBegin);
	}, [dateBegin]);

	const handleMonthDayChange = (day) => {
		console.log(day);
		if (selectedMonthDays.includes(day)) {
			setSelectedMonthDays('');
		} else {
			setSelectedMonthDays(day);
		}
	};

	const handleWeekTypeChange = (type) => {
		console.log(type);
		if (type === 'SAME_QUESTIONS_EVERY_WEEK') {
			setSelectedWeekDays('');
		}
		setSelectedWeekType(type);
	};

	const handleWeekDayChange = (day) => {
		console.log(day);
		if (selectedWeekDays.includes(day)) {
			setSelectedWeekDays(selectedWeekDays.filter((d) => d !== day));
		} else {
			setSelectedWeekDays([...selectedWeekDays, day]);
		}
	};

	const api = {
		getSurveys: useAxios(),
		saveSurvey: useAxios(),
		removeSurvey: useAxios(),
		getSurvey: useAxios(),
	};

	useEffect(() => {
		if (frequency !== 'WEEKLY') {
			setSelectedWeekDays('');
		}
	}, [frequency]);

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getSurveys.response?.data) {
			setSurveys(api.getSurveys.response.data);
			setTotalPages(api.getSurveys.response.data.totalPages);
		}
	}, [api.getSurveys.response?.data]);

	const getSurveys = () => {
		api.getSurveys.fetch({
			method: 'GET',
			url: `/survey/admin/get/all/surveys?page=${page}&size=${size}`,
		});
	};

	const saveSurvey = (data) => {
		const choices = [];
		let surveyData = {};
		if (data.choices && programType === 'GENEL') {
			data.choices.slice(0, data.optionCount).forEach((choice) => {
				if (choice) {
					if (data.surveyId) {
						const survey =
							api.getSurveys.response.data.content[
								api.getSurveys.response.data.content.findIndex(
									(object) =>
										object.id ===
										parseInt(data.surveyId, 10)
								)
							];

						const option =
							survey.surveyChoiceDTOList[
								survey.surveyChoiceDTOList.findIndex(
									(object) => object.name === choice
								)
							];

						choices.push({
							...(option ? { id: option.id } : {}),
							name: choice,
						});
					} else {
						choices.push({
							name: choice,
						});
					}
				}
				return false;
			});
		}

		if (programType === 'GENEL') {
			surveyData = {
				...data,
				active: data.active !== 'false',
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				surveyChoiceDTOList: choices,
			};
		} else {
			const { active, title, description, type, ...filteredData } = data;
			surveyData = {
				...filteredData,
				dateBegin: data.dateBegin
					? moment.utc(data.dateBegin).format()
					: '',
				dateEnd: data.dateEnd ? moment.utc(data.dateEnd).format() : '',
				...(selectedWeekDays ? { selectedDays: selectedWeekDays } : {}),
				...(selectedWeekType
					? { weeklyFrequencyType: selectedWeekType }
					: {}),
				...(selectedMonthDays
					? { monthlyFrequencyType: selectedMonthDays }
					: {}),
			};
		}
		delete surveyData.optionCount;
		delete surveyData.choices;

		if (programType === 'GENEL') {
			api.saveSurvey.fetch({
				method: 'POST',
				url: '/survey/admin/create/survey',
				data: surveyData,
			});
		} else {
			api.saveSurvey.fetch({
				method: 'POST',
				url: '/survey/admin/create/program/survey',
				data: surveyData,
			});
		}
	};

	const removeSurvey = (id) => {
		api.removeSurvey.fetch({
			method: 'POST',
			url: `/survey/admin/delete/survey/${id}`,
		});
	};

	const getSurvey = (data) => {
		api.getSurvey.fetch({
			method: 'POST',
			url: '/survey/admin/get/survey/results',
			data,
		});
	};

	React.useEffect(() => {
		getSurveys();
	}, [page, size]);

	React.useEffect(() => {
		if (api.getSurveys.error) {
			toast.error(api.getSurveys.error.message);
		}
	}, [api.getSurveys.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit && !showEdit.id) {
			setValueSaveSurvey('optionCount', 4);
		}

		if (showEdit.id && api.getSurveys.response) {
			const { id } = showEdit;
			const item =
				api.getSurveys.response.data.content[
					api.getSurveys.response.data.content.findIndex(
						(object) => object.id === id
					)
				];

			const values = [
				{ title: item.title },
				{ description: item.description },
				{ score: item.score },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },

				{ optionCount: item.surveyChoiceDTOList.length },
			];

			values.forEach((value) => {
				setValueSaveSurvey(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
			item.surveyChoiceDTOList.forEach((choice, index) => {
				setValueSaveSurvey(`choices[${index}]`, choice.name);
			});
		}
	}, [showEdit, api.getSurveys.response]);

	React.useEffect(() => {
		if (api.saveSurvey.response) {
			resetSaveSurvey();
			setShowEdit(false);
			setShowProgram(true);
			toast.success('Kaydedildi.');
			getSurveys();
		}
	}, [api.saveSurvey.response]);

	React.useEffect(() => {
		if (api.saveSurvey.error) {
			toast.error(api.saveSurvey.error.message);
		}
	}, [api.saveSurvey.error]);

	React.useEffect(() => {
		if (api.removeSurvey.response) {
			setShowEdit(false);
			setShowProgram(true);
			toast.success('Silindi.');
			getSurveys();
		}
	}, [api.removeSurvey.response]);

	React.useEffect(() => {
		if (api.removeSurvey.error) {
			toast.error(api.removeSurvey.error.message);
		}
	}, [api.removeSurvey.error]);

	if (!isLoading && !hasPermission('ANKET')) {
		return <Navigate to="/" />;
	}

	return (
		<Page>
			<Page.Header header="Portal" title="Tüm Anketler">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getSurveys.loading}
				show={
					api.getSurveys.response &&
					api.getSurveys.response?.data?.content &&
					api.getSurveys.loading === false
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Anketler',
								'Puan',
								'Tarih',
								'Durum',
								'Katılımcı',
								'',
							]}
						/>
						<Table.Body>
							{surveys?.content?.length &&
								surveys?.content.map((item, index) => (
									<Table.Row key={index}>
										<Table.Column>
											<span className="whitespace-nowrap text-xs font-normal text-slate-400">
												{item.id}
											</span>
										</Table.Column>
										<Table.Column>
											<div className="grid gap-1">
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.title}
												</h6>
												<p className="truncate whitespace-nowrap text-xs text-slate-400">
													{item.description}
												</p>
											</div>
										</Table.Column>
										<Table.Column className="text-base font-medium">
											{item.score}
										</Table.Column>
										<Table.Column>
											<Time
												dateBegin={moment(
													item.dateBegin
												).format('DD.MM.YYYY')}
												timeBegin={moment(
													item.dateBegin
												).format('HH:mm:ss')}
												dateEnd={moment(
													item.dateEnd
												).format('DD.MM.YYYY')}
												timeEnd={moment(
													item.timeEnd
												).format('HH:mm:ss')}
											/>
										</Table.Column>
										<Table.Column>
											<Switch
												id={item.id}
												active={item.active}
												list={surveys.content}
												onChange={setSurveys}
												saveList={saveSurvey}
												customPopUpText={false}
											/>
										</Table.Column>
										<Table.Column>
											{item.totalParticipant}
										</Table.Column>
										<Table.Column className="text-right [&_button]:w-20">
											<div className="flex flex-col items-end justify-center gap-2">
												{item.totalParticipant > 0 && (
													<Button
														size="xs"
														variant="primary-outline"
														className="inline-flex"
														onClick={() => {
															getSurvey({
																surveyId:
																	item.id,
															});
															setShowResult({
																id: item.id,
															});
														}}
													>
														Sonuçlar
													</Button>
												)}
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
														setShowProgram(false);
													}}
												>
													Düzenle
												</Button>
											</div>
										</Table.Column>
									</Table.Row>
								))}
						</Table.Body>
					</Table>
					<Pagination
						currentPage={page}
						totalPages={totalPages}
						onPageChange={(newPage) => setPageNumber(newPage)}
						pageSize={size}
						onPageSizeChange={(newSize) => setPageSize(newSize)}
					/>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getSurveys.loading ||
					api.saveSurvey.loading ||
					api.removeSurvey.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setShowProgram(true);
					resetSaveSurvey();
					setSelectedMonthDays([]);
					setSelectedWeekDays([]);
				}}
			>
				<form onSubmit={handleSubmitSaveSurvey(saveSurvey)} noValidate>
					<div className="px-6">
						{showProgram && (
							<Modal.Section title="Anket Türü">
								<Select
									name="type"
									label="Anket Türü"
									grid
									options={[
										{
											name: 'Tekli',
											value: 'GENEL',
										},
										{
											name: 'Çoklu',
											value: 'DONEMSEL',
										},
									]}
									register={registerSaveSurvey}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveSurvey}
									size="lg"
									className={{ select: '!text-sm' }}
								/>

								{programType === 'DONEMSEL' && (
									<ProgramInputs
										errorsSaveSurvey={errorsSaveSurvey}
										registerSaveSurvey={registerSaveSurvey}
										selectedWeekDays={selectedWeekDays}
										handleWeekDayChange={
											handleWeekDayChange
										}
										selectedMonthDays={selectedMonthDays}
										handleMonthDayChange={
											handleMonthDayChange
										}
										selectedScoreDate={selectedScoreDate}
										setSelectedScoreDate={
											setSelectedScoreDate
										}
										selectedWeekType={selectedWeekType}
										handleWeekTypeChange={
											handleWeekTypeChange
										}
										frequency={frequency}
										programType={programType}
										watchSaveSurvey={watchSaveSurvey}
										setValueSaveSurvey={setValueSaveSurvey}
									/>
								)}
							</Modal.Section>
						)}

						{programType === 'GENEL' && (
							<>
								<Modal.Section title="Ayarlar">
									<Input
										type="text"
										name="title"
										label="Başlık"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveSurvey}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveSurvey}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="description"
										label="Açıklama"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveSurvey}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveSurvey}
										size="lg"
										className={{ input: '!text-sm' }}
									/>

									<DatePicker
										label="Başlangıç Tarihi"
										name="dateBegin"
										errors={errorsSaveSurvey}
										size="lg"
										className={{ input: '!text-sm' }}
										grid
										value={watchSaveSurvey('dateBegin')}
										setValue={setValueSaveSurvey}
									/>
									<DatePicker
										label="Bitiş Tarihi"
										name="dateEnd"
										errors={errorsSaveSurvey}
										size="lg"
										className={{ input: '!text-sm' }}
										grid
										value={watchSaveSurvey('dateEnd')}
										setValue={setValueSaveSurvey}
									/>
									<Input
										type="number"
										name="score"
										label="Puan"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveSurvey}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveSurvey}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Select
										name="active"
										label="Durum"
										grid
										options={[
											{
												name: 'Aktif',
												value: true,
											},
											{
												name: 'Pasif',
												value: false,
											},
										]}
										defaultValue
										register={registerSaveSurvey}
										// validation={{
										// 	required: 'Bu alan zorunludur.',
										// }}
										errors={errorsSaveSurvey}
										size="lg"
										className={{ select: '!text-sm' }}
									/>
								</Modal.Section>
								<Modal.Section title="Seçenekler">
									<Select
										name="optionCount"
										label="Seçenek Adeti"
										grid
										options={[
											{
												name: '2',
												value: 2,
											},
											{
												name: '3',
												value: 3,
											},
											{
												name: '4',
												value: 4,
											},
											{
												name: '5',
												value: 5,
											},
											{
												name: '6',
												value: 6,
											},
											{
												name: '7',
												value: 7,
											},
											{
												name: '8',
												value: 8,
											},
											{
												name: '9',
												value: 9,
											},
											{
												name: '10',
												value: 10,
											},
										]}
										register={registerSaveSurvey}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveSurvey}
										size="lg"
										className={{ select: '!text-sm' }}
									/>
									{[
										...Array(
											watchSaveSurvey('optionCount')
												? Number(
														watchSaveSurvey(
															'optionCount'
														)
													)
												: 0
										),
									].map((option, index) => (
										<Input
											key={index}
											type="text"
											name={`choices[${index}]`}
											label={`Seçenek ${index + 1}`}
											grid
											placeholder="Lütfen giriniz."
											register={registerSaveSurvey}
											validation={{
												required: 'Bu alan zorunludur.',
											}}
											errors={errorsSaveSurvey}
											size="lg"
											className={{ input: '!text-sm' }}
										/>
									))}
								</Modal.Section>
							</>
						)}
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="surveyId"
							register={registerSaveSurvey}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => removeSurvey(showEdit.id)}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveSurvey).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>

			<Modal
				show={!!showResult.id}
				loading={api.getSurveys.loading || api.getSurvey.loading}
				size="lg"
				title="Sonuçlar"
				onClose={() => {
					setShowResult(false);
				}}
			>
				<div className="flex flex-col gap-4 p-6">
					{/* const item =
					api.getSurveys.response.data.content[
						api.getSurveys.response.data.content.findIndex(
							(object) => object.id === id
						)
					]; */}
					{api.getSurvey.response?.data?.surveyChoiceDTOList && (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										position: 'right',
										align: 'left',
										labels: {
											font: {
												family: '"Lexend Deca", sans-serif',
												weight: '300',
												size: 12,
											},
											color: '#94A3B8',
										},
									},
								},
							}}
							data={{
								labels: [
									...api.getSurvey.response.data.surveyChoiceDTOList.map(
										(item) => item.name
									),
								],
								datasets: [
									{
										data: [
											...api.getSurvey.response.data.surveyChoiceDTOList.map(
												(item) => item.choicePercent
											),
										],
										backgroundColor: [
											'#0074D9',
											'#FF4136',
											'#2ECC40',
											'#FF851B',
											'#7FDBFF',
											'#B10DC9',
											'#FFDC00',
											'#001f3f',
											'#39CCCC',
											'#01FF70',
											'#85144b',
											'#F012BE',
											'#3D9970',
											'#111111',
											'#AAAAAA',
										],
										borderWidth: 0,
									},
								],
							}}
						/>
					)}
				</div>
			</Modal>
		</Page>
	);
};

export default Surveys;
