/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HiCog } from 'react-icons/hi';
import Button from '../../Button';

import Context from '../../Context';
import Search from './Search';
import Filter from './Filter';
import Add from './Add';
import Upload from './Upload';
import Template from './Template';

const Header = ({ children, header, title }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [context, setContext] = React.useContext(Context);
	const { state } = context;

	return (
		<div className="flex flex-col-reverse md:flex-row items-center gap-3">
			<h3 className="text-2xl font-medium text-slate-700 md:mr-auto">
				{header && (
					<span className="mb-1 block text-sm font-normal text-slate-400">
						{header}
					</span>
				)}
				{typeof state.showSettings !== 'undefined' ? (
					<button
						type="button"
						className="group flex items-center gap-2"
						onClick={() => {
							setContext({
								...context,
								state: {
									...state,
									showSettings: true,
								},
							});
						}}
					>
						{title}
						<HiCog className="h-6 w-6 opacity-25 transition duration-300 group-hover:opacity-100" />
					</button>
				) : (
					<span className="group flex items-center gap-2">
						{title}
					</span>
				)}
			</h3>

			<div>
				{context?.signed?.data?.tenantName && (
					<h3 className="text-end text-2xl font-medium text-slate-700 md:ml-auto">
						{context?.signed?.data?.employeeFirm?.role === 'SUPERADMIN' ? (
							<span className="mb-1 block text-sm font-normal text-slate-400">
								{localStorage.getItem('tenant')}
							</span>
						) : (
							<span className="mb-1 block text-sm font-normal text-slate-400">
								{context?.signed?.data?.tenantName}
							</span>
						)}
					</h3>
				)}

				{children}
			</div>

			{location.pathname !== '/program-dashboard' &&
				context.signed.data.employeeFirm.role === 'SUPERADMIN' && (
					<div className="text-white fixed bottom-16 right-12 items-center justify-center rounded-md text-sm z-20">
						<Button
							variant="danger"
							className="py-1 px-2"
							onClick={() => navigate('/program-dashboard')}
						>
							Program Yönetimi
						</Button>
					</div>
				)}
		</div>
	);
};

Header.Search = Search;
Header.Filter = Filter;
Header.Add = Add;
Header.Upload = Upload;
Header.Template = Template;

export default Header;
