export const formInputsData = [
	{
		label: 'Sayfa Başlığı',
		name: 'pageTitle',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Meta Açıklaması',
		name: 'metaDescription',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Ana Renk Kodu',
		name: 'blue',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Light Renk Kodu',
		name: 'blueLight',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Lighter Renk Kodu',
		name: 'blueLighter',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Dark Renk Kodu',
		name: 'blueDark',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Giriş Sayfası Başlığı',
		name: 'loginPageTitle',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Giriş Sayfası Açıklama',
		name: 'loginPageDescription',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
];
