import React from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	BsAt,
	BsPhone,
	BsCheckCircleFill,
	BsFillXCircleFill,
} from 'react-icons/bs';

import { useAxios } from '../../hooks';
import {
	Badge,
	Button,
	Input,
	Item,
	Modal,
	Page,
	Pagination,
	Select,
	Table,
} from '../../components';

const Employees = () => {
	// const [dashboardItems, setDashboardItems] = React.useState();
	const [showEdit, setShowEdit] = React.useState(false);
	const [showAddPoint, setShowAddPoint] = React.useState(false);
	const [params, setParams] = useSearchParams();

	// State for pagination
	const [page, setPageNumber] = React.useState(0);
	const [size, setPageSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);
	const {
		register: registerSaveEmployee,
		setValue: setValueSaveEmployee,
		handleSubmit: handleSubmitSaveEmployee,
		reset: resetSaveEmployee,
		formState: { errors: errorsSaveEmployee },
	} = useForm();

	const {
		register: registerAddPoint,
		handleSubmit: handleSubmitAddPoint,
		reset: resetShowAddPoint,
		formState: { errors: errorsAddPoint },
	} = useForm();

	const api = {
		getEmployees: useAxios(),
		saveEmployee: useAxios(),
		removeEmployee: useAxios(),
		saveEmployeeStatus: useAxios(),
		getPointTypes: useAxios(),
		addPoint: useAxios(),
	};

	const getPointTypes = () => {
		api.getPointTypes.fetch({
			method: 'GET',
			url: '/score/admin/getScoreTypeList',
		});
	};

	const addPoint = (data) => {
		const body = {
			...data,
		};

		api.addPoint.fetch({
			method: 'POST',
			url: '/score/admin/add/manuel/score',
			data: body,
		});
	};

	const getEmployees = () => {
		api.getEmployees.fetch({
			method: 'GET',
			url: `/employee/admin/empFirmList?page=${page}&size=${size}`,
		});
	};

	const saveEmployee = (data) => {
		const body = {
			...data,
		};

		api.saveEmployee.fetch({
			method: 'POST',
			url: '/employee/admin/updateEmployeeFirm',
			data: body,
		});
	};

	const removeEmployee = (data) => {
		console.log(data);
		api.removeEmployee.fetch({
			method: 'POST',
			url: `/employee/admin/employeeFirm/delete/${data.userId}/${data.username}`,
		});
	};

	const saveEmployeeStatus = (data) => {
		api.saveEmployeeStatus.fetch({
			method: 'POST',
			url: '/employee/admin/employeeFirm/approved',
			data,
		});
	};

	React.useEffect(() => {
		getPointTypes();
	}, []);

	React.useEffect(() => {
		if (api.getPointTypes.response) {
			console.log(api.getPointTypes.response);
		}
	}, [api.getPointTypes.response]);

	React.useEffect(() => {
		if (api.getPointTypes.error) {
			toast.error(api.getPointTypes.error.message);
		}
	}, [api.getEmployees.error]);

	React.useEffect(() => {
		if (api.addPoint.response) {
			resetShowAddPoint();
			setShowAddPoint(false);
			toast.success('Puan Eklendi!');
			getEmployees();
		}
	}, [api.addPoint.response]);

	React.useEffect(() => {
		getEmployees();
	}, [page, size]);

	React.useEffect(() => {
		if (api.getEmployees.response) {
			console.log(api.getEmployees.response);
			setTotalPages(api.getEmployees.response.data.totalPages);
		}
	}, [api.getEmployees.response]);

	React.useEffect(() => {
		if (api.getEmployees.error) {
			toast.error(api.getEmployees.error.message);
		}
	}, [api.getEmployees.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getEmployees.response) {
			const { id } = showEdit;
			const item =
				api.getEmployees.response.data.content[
					api.getEmployees.response.data.content.findIndex(
						(object) => object.id === id
					)
				];

			const values = [
				{ employeeName: item.employeeDTO.name },
				{ employeeSurname: item.employeeDTO.surname },
				{ username: item.username },

				{ email: item.employeeDTO.email },
				{ employeeMobileNo: item.employeeDTO.mobileNo },

				{ dealerSignName: item.dealerSignName },
				{ salesDocumentType: item.salesDocumentType },
				{ salesDocumentNumber: item.salesDocumentNumber },
			];

			values.forEach((value) => {
				setValueSaveEmployee(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEdit, api.getEmployees.response]);

	React.useEffect(() => {
		if (api.saveEmployee.response) {
			resetSaveEmployee();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getEmployees();
		}
	}, [api.saveEmployee.response]);

	React.useEffect(() => {
		if (api.saveEmployee.error) {
			toast.error(api.saveEmployee.error.message);
		}
	}, [api.saveEmployee.error]);

	React.useEffect(() => {
		if (api.removeEmployee.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getEmployees();
		}
	}, [api.removeEmployee.response]);

	React.useEffect(() => {
		if (api.removeEmployee.error) {
			toast.error(api.saveEmployee.error.message);
		}
	}, [api.removeEmployee.error]);

	React.useEffect(() => {
		if (api.saveEmployeeStatus.response) {
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getEmployees();
		}
	}, [api.saveEmployeeStatus.response]);

	React.useEffect(() => {
		if (api.saveEmployeeStatus.error) {
			toast.error(api.saveEmployeeStatus.error.message);
		}
	}, [api.saveEmployeeStatus.error]);

	return (
		<Page>
			<Page.Header header="Üye İşlemleri" title="Tüm Üyeler">
				<div className="flex flex-row flex-wrap">
					<Page.Header.Upload
						className="mr-2 mb-2 md:mb-0"
						show
						onClick={(file) => {
							console.log('Seçilen dosya:', file);
							// Burada dosya ile ilgili işlemleri yapabilirsin (örn. API'ye gönderme)
						}}
					/>
					<Page.Header.Template
						className="mr-2 mb-2 md:mb-0"
						show
						fileUrl="/assets/user-point-template.xlsx"
						fileName="user-point-template.xlsx"
						buttonText="Şablon İndir"
					/>
					<Page.Header.Add show onClick={() => setShowEdit(true)} className="mb-2 md:mb-0" />
				</div>
			</Page.Header>

			<Page.Body
				loading={
					api.getEmployees.loading || api.saveEmployeeStatus.loading
				}
				show={
					api.getEmployees.response &&
					api.getEmployees.loading === false &&
					api.saveEmployeeStatus.loading === false
				}
			>
				{/* <Page.Dashboard items={dashboardItems} /> */}
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Üye',
								'Bayi',
								'Sözleşme',
								'İletişim',
								'Puan',
								'Rol',
								'',
							]}
						/>
						<Table.Body>
							{api.getEmployees.response?.data?.content?.length &&
								api.getEmployees.response.data.content.map(
									(item, index) =>
										item.registerStatu !== 'REJECTED' && (
											<Table.Row
												key={index}
												className={`${
													item.registerStatu !==
														'CONFIRM' &&
													api.getEmployees.response
														.data.content[index + 1]
														.registerStatu ===
														'CONFIRM'
														? 'border-b-[16px]'
														: ''
												}`}
											>
												<Table.Column>
													<span className="whitespace-nowrap text-xs font-normal text-slate-400">
														{item.id}
													</span>
												</Table.Column>
												<Table.Column>
													<Item
														title={`${
															item.employeeDTO
																.name || ''
														}${
															item.employeeDTO
																.surname
																? ` ${item.employeeDTO.surname}`
																: ''
														}`}
														description={`@${item.username}`}
														className="w-60 gap-3 [&_p]:text-xs"
														{...(item.profilePhoto
															? {
																	image: item.profilePhoto,
																}
															: null)}
													/>
												</Table.Column>
												<Table.Column>
													<div className="flex flex-col gap-2 !whitespace-normal text-xs ">
														<span className="font-normal">
															{
																item.dealerSignName
															}
														</span>
														<div className="flex items-center gap-2">
															{(item.salesDocumentType ||
																item.salesDocumentNumber) && (
																<Badge className="font-medium">
																	{item.salesDocumentType
																		? `${item.salesDocumentType}: `
																		: ''}
																	{item.salesDocumentNumber && (
																		<span className="!font-light text-slate-500">
																			{
																				item.salesDocumentNumber
																			}
																		</span>
																	)}
																</Badge>
															)}
														</div>
													</div>
												</Table.Column>
												<Table.Column>
													{item.disclaimerChecked ? (
														<BsCheckCircleFill className="h-4 w-4 text-green-500" />
													) : (
														<BsFillXCircleFill className="h-4 w-4 text-red-500" />
													)}
												</Table.Column>
												<Table.Column>
													<div className="flex flex-col gap-2 !whitespace-normal text-xs ">
														<div className="flex gap-2">
															<BsAt className="h-4 w-4 opacity-50" />
															{
																item.employeeDTO
																	.email
															}
														</div>
														<div className="flex gap-2">
															<BsPhone className="h-4 w-4 opacity-50" />
															{
																item.employeeDTO
																	.mobileNo
															}
														</div>
													</div>
												</Table.Column>
												<Table.Column className="text-base font-medium">
													{item.score}
												</Table.Column>
												<Table.Column>
													<Badge
														variant={
															{
																ADMIN: 'success',
																USER: 'default',
															}[item.role]
														}
													>
														{
															{
																ADMIN: 'Yönetici',
																USER: 'Kullanıcı',
															}[item.role]
														}
													</Badge>
												</Table.Column>
												<Table.Column className="text-right [&_button]:w-20">
													<div className="flex flex-col items-end justify-center gap-2">
														{item.registerStatu ===
															'WAITING' && (
															<>
																<Button
																	size="xs"
																	variant="danger-outline"
																	className="inline-flex"
																	onClick={() => {
																		saveEmployeeStatus(
																			{
																				status: 'REJECTED',
																				empFirmId:
																					item.id,
																			}
																		);
																	}}
																>
																	Reddet
																</Button>
																<Button
																	size="xs"
																	variant="primary"
																	className="inline-flex"
																	onClick={() => {
																		saveEmployeeStatus(
																			{
																				status: 'CONFIRM',
																				empFirmId:
																					item.id,
																			}
																		);
																	}}
																>
																	Onayla
																</Button>
															</>
														)}
														{item.registerStatu ===
															'CONFIRM' && (
																<>
																	<Button
																	size="xs"
																	variant="secondary-outline"
																	className="inline-flex"
																	onClick={() => {
																		setShowEdit(
																			{
																				id: item.id,
																				data: {
																					userId: item.id,
																					username:
																						item.username,
																				},
																			}
																		);
																	}}
																	>
																	Düzenle
																	</Button>

																	<Button
																		size="xs"
																		variant="secondary"
																		className="inline-flex"
																		onClick={() => {
																			setShowAddPoint(
																				{
																					id: item.id,
																				}
																			);
																		}}
																	>
																		Puan Ekle
																	</Button>
																</>
														)}
													</div>
												</Table.Column>
											</Table.Row>
										)
								)}
						</Table.Body>
					</Table>
					<Pagination
						currentPage={page}
						totalPages={totalPages}
						onPageChange={(newPage) => setPageNumber(newPage)}
						pageSize={size}
						onPageSizeChange={(newSize) => setPageSize(newSize)}
					/>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getEmployees.loading ||
					api.saveEmployee.loading ||
					api.removeEmployee.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveEmployee();
				}}
			>
				<form
					onSubmit={handleSubmitSaveEmployee(saveEmployee)}
					noValidate
				>
					<div className="px-6">
						<Modal.Section title="Üye">
							<Input
								name="employeeName"
								label="İsim"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="employeeSurname"
								label="Soy İsim"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="username"
								label="Kullanıcı Adı"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="İletişim">
							<Input
								name="email"
								label="E-Posta"
								grid
								placeholder="Lütfen giriniz."
								type="email"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
									pattern: {
										value: /^\S+@\S+$/i,
										message: 'Geçerli bir e-posta giriniz.',
									},
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="employeeMobileNo"
								label="Telefon"
								grid
								placeholder="Lütfen giriniz."
								type="number"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
									minLength: {
										value: 11,
										message:
											'Bu alan 11 haneden oluşmalıdır.',
									},
									maxLength: {
										value: 11,
										message:
											'Bu alan 11 haneden oluşmalıdır.',
									},
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Bayi">
							<Input
								name="dealerSignName"
								label="Bayi Tabela Adı"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="salesDocumentType"
								label="Satış Belgesi Tipi"
								grid
								options={[
									{
										name: 'Lütfen seçiniz',
										value: '',
									},
									{
										name: 'P',
										value: 'P',
									},
									{
										name: 'PT',
										value: 'Pt',
									},
									{
										name: 'PTT',
										value: 'PTT',
									},
								]}
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							<Input
								name="salesDocumentNumber"
								label="Satış Belgesi No"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveEmployee}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => removeEmployee(showEdit.data)}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveEmployee).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>

			<Modal
				show={Boolean(showAddPoint)}
				loading={
					api.getPointTypes.loading ||
					api.addPoint.loading
				}
				size="lg"
				title="Puan Ekle"
				onClose={() => {
					setShowAddPoint(false);
					resetShowAddPoint();
				}}
			>
				<form
					onSubmit={handleSubmitAddPoint(addPoint)}
					noValidate
				>
					<div className="px-6">
						<Modal.Section title="Puan Ekleme">
							<Select
								name="scoreType"
								label="Puan Türü"
								grid
								options={[
									...[
										{
											name: 'Lütfen seçiniz.',
											value: '',
										},
									],
									...(api.getPointTypes.response?.data
										?.length
										? api.getPointTypes.response.data.map(
											(item) => ({
												name: item,
												value: item,
											})
										)
										: []),
								]}
								defaultValue=""
								register={registerAddPoint}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsAddPoint}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							<Input
								name="score"
								label="Puan"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerAddPoint}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsAddPoint}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="description"
								label="Açıklama"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerAddPoint}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsAddPoint}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>
					</div>

					{showAddPoint?.id && (
						<>
							<Input
								type="hidden"
								name="employeeFirmId"
								register={registerAddPoint}
								defaultValue={showAddPoint.id}
							/>

							<Input
								type="hidden"
								name="scoreDivider"
								register={registerAddPoint}
								defaultValue={0}
							/>

							<Input
								type="hidden"
								name="scoreDividend"
								register={registerAddPoint}
								defaultValue={0}
							/>

							<Input
								type="hidden"
								name="pointType"
								register={registerAddPoint}
								defaultValue="BAZ/SADAKAT PUANI"
							/>
						</>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsAddPoint).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default Employees;
