import React, { useEffect } from 'react';
import { Navigate, NavLink, Outlet, useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import { CgMenuLeftAlt } from 'react-icons/cg';
import { IoChevronDown, IoChevronForward, IoPower } from 'react-icons/io5';

import { useAxios } from '../../../hooks';
import Context from '../../Context';
import Item from '../../Item';

import { getMenuItems } from './MenuName';

const Private = () => {
	const [context, setContext] = React.useContext(Context);
	const [showHeader, setShowHeader] = React.useState(false);
	const [activeMenu, setActiveMenu] = React.useState();
	const { pathname } = useLocation();
	const api = {
		getLoginUser: useAxios(),
		getSideMenuItems: useAxios(),
	};

	const superAdmin = context?.signed?.data?.employeeFirm?.role;
	const userPermissions = api.getSideMenuItems?.response?.data;

	const sections = getMenuItems(superAdmin, userPermissions);

	const logout = (showToast = true) => {
		setContext({});
		localStorage.removeItem('signed');
		localStorage.removeItem('tenant');
		localStorage.removeItem('userPermissions');
		if (showToast) {
			toast.success('Çıkış Yapıldı.');
		}
	};

	const getSideMenuItems = () => {
		api.getSideMenuItems.fetch({
			method: 'GET',
			url: '/management/get/active/componentList',
		});
	};

	React.useEffect(() => {
		if (api.getSideMenuItems.response) {
			localStorage.setItem(
				'userPermissions',
				JSON.stringify(api.getSideMenuItems?.response?.data)
			);
		}
	}, [api.getSideMenuItems.response?.data]);

	const getLoginUser = () => {
		api.getLoginUser
			.fetch({
				method: 'GET',
				url: '/employee/loginUser/detail',
			})
			.catch(() => {
				logout(false);
			});
	};

	React.useEffect(() => {
		if (context?.signed?.data) {
			getLoginUser();
			getSideMenuItems();
		}
	}, []);

	React.useEffect(() => {
		setContext({
			...context,
			state: {},
		});
		setShowHeader(false);
		window.scrollTo(0, 0);
	}, [pathname]);

	React.useEffect(() => {
		if (api.getLoginUser.error) {
			logout(false);
		}
	}, [api.getLoginUser.error]);

	return context?.signed?.data ? (
		<>
			<header className="relative z-30 shadow">
				<div className="bg-white grid grid-cols-6 items-center p-4 lg:hidden">
					<div className="col-span-1">
						<button
							type="button"
							className="text-slate-500 bg-slate-50 rounded-lg p-2"
							onClick={() => {
								setShowHeader(!showHeader);
							}}
						>
							<CgMenuLeftAlt className="h-6 w-6" />
						</button>
					</div>
					<div className="col-span-4 text-center">
						<NavLink to="/">
							<img
								src={require('../../../assets/logo-connection.png')}
								alt=""
								className="mx-auto h-6 w-auto"
							/>
						</NavLink>
					</div>
				</div>

				<div
					className={`
						bg-white flex w-[260px] flex-col 
						max-lg:fixed max-lg:left-0 max-lg:top-0 max-lg:z-50 max-lg:h-screen max-lg:-translate-x-full max-lg:transform max-lg:transition max-lg:duration-300 max-lg:ease-in-out 
						${showHeader && 'max-lg:translate-x-0'} 
						lg:h-full
					`}
				>
					<div className="shrink-0 p-4 max-lg:hidden">
						<NavLink to="/">
							<img
								src={require('../../../assets/logo-connection.png')}
								alt=""
								className="mx-auto h-24 w-auto"
							/>
						</NavLink>
					</div>

					<div
						className="
							flex grow flex-col gap-6 overflow-y-auto p-4
							[mask-image:linear-gradient(to_bottom,transparent_0%,black_theme(spacing.4),black_calc(100%-theme(spacing.4)),transparent_100%)]
						"
					>
						<div className="flex flex-col gap-6">
							{api.getLoginUser.response && (
								<div className="relative">
									<div className="group peer text-slate-700 bg-slate-100 flex items-center gap-3 rounded-xl p-4 text-sm font-medium transition duration-300 hover:bg-slate-100 [&.active]:text-white [&.active]:bg-blue-900 [&.active]:transition [&.active]:duration-300">
										<Item
											title={`${
												api.getLoginUser?.response?.data
													?.employeeDTO?.name
											} ${api.getLoginUser?.response?.data?.employeeDTO?.surname
												?.charAt(0)
												.toUpperCase()}.`}
											description="Yönetici"
											className="gap-3 pr-10 [&_p]:text-xs"
											{...(api?.getLoginUser?.response
												?.data?.profilePhoto
												? {
														image: api?.getLoginUser
															?.response?.data
															?.profilePhoto,
													}
												: null)}
										/>
									</div>

									<button
										type="button"
										className="group absolute right-2 top-1/2 -translate-y-1/2 transform p-2 peer-[.active]:text-white"
										onClick={() => logout(true)}
										title="Çıkış Yap"
									>
										<IoPower className="h-6 w-6 opacity-50 transition duration-300 group-hover:text-red-500 group-hover:opacity-100" />
									</button>
								</div>
							)}

							{sections?.map((section, sectionIndex) => (
								<div key={sectionIndex}>
									{section.name && (
										<h6 className="text-slate-400 mb-4 text-xs font-medium uppercase">
											{section.name}
										</h6>
									)}

									{section.items && (
										<div className="overflow-hidden rounded-lg">
											{section.items.map(
												(menu, menuIndex) => (
													<React.Fragment
														key={menuIndex}
													>
														{menu.url && (
															<div className="[&:not(:last-child)]:mb-px">
																<NavLink
																	to={
																		menu.url
																	}
																	className={({
																		isActive,
																	}) =>
																		`bg-slate-100 flex w-full items-center gap-3 px-4 py-3 text-sm font-normal transition duration-300 hover:bg-slate-200 [&.active]:text-white [&.active]:bg-blue-500
																		${isActive && !menu.url.includes('?') ? 'active' : ''}
																		`
																	}
																	onClick={() => {
																		setActiveMenu(
																			activeMenu ===
																				menu.name
																				? null
																				: menu.name
																		);
																	}}
																>
																	{menu.icon && (
																		<div className="[&>*]:h-5 [&>*]:w-5">
																			{
																				menu.icon
																			}
																		</div>
																	)}
																	{menu.name}
																	<IoChevronForward className="ml-auto h-4 w-4" />
																</NavLink>
															</div>
														)}

														{menu.items && (
															<div className="[&:not(:last-child)]:mb-px">
																<button
																	type="button"
																	className={`bg-slate-100 flex w-full items-center gap-3 px-4 py-3 text-sm font-normal transition duration-300 hover:bg-slate-200 [&.active]:text-blue-500 [&.active]:bg-blue-100 ${
																		activeMenu ===
																		menu.name
																			? 'active'
																			: ''
																	}`}
																	onClick={() => {
																		setActiveMenu(
																			activeMenu ===
																				menu.name
																				? null
																				: menu.name
																		);
																	}}
																>
																	{menu.icon && (
																		<div className="[&>*]:h-5 [&>*]:w-5">
																			{
																				menu.icon
																			}
																		</div>
																	)}
																	{menu.name}
																	<IoChevronDown
																		className={`ml-auto h-4 w-4 transition-transform ${
																			activeMenu ===
																				menu.name &&
																			'rotate-180 transform'
																		}`}
																	/>
																</button>

																<div
																	className={`bg-slate-50 grid max-h-0 overflow-hidden opacity-0 transition-all duration-300 [&.active]:max-h-[600px] [&.active]:opacity-100 ${
																		activeMenu ===
																		menu.name
																			? 'active py-2'
																			: ''
																	}`}
																>
																	{menu.items.map(
																		(
																			subMenu,
																			subMenuIndex
																		) => (
																			<NavLink
																				key={
																					subMenuIndex
																				}
																				to={
																					subMenu.url
																				}
																				className={({
																					isActive,
																				}) =>
																					`px-4 py-2 text-sm [&.active]:text-blue-500 [&.active]:font-normal
																					${isActive && !subMenu.url.includes('?') ? 'active' : ''}
																					`
																				}
																			>
																				{
																					subMenu.name
																				}
																			</NavLink>
																		)
																	)}
																</div>
															</div>
														)}
													</React.Fragment>
												)
											)}
										</div>
									)}
								</div>
							))}
						</div>

						{/* <div className="mt-auto pt-6">
							<div className="mt-6 flex shrink-0 flex-col items-center
							justify-center gap-6 rounded-xl p-6 text-center text-slate-700 bg-slate-50">
								<img
									src="https://preview.keenthemes.com/metronic8/demo30/assets/media/svg/illustrations/easy/2.svg"
									alt=""
									className="-mt-12 w-3/4"
								/>
								<div className="text-center text-sm">
									<h6 className="font-medium">Go Premium</h6>
									<p className="mt-2 opacity-50">
										Unlock all features for unlimited joy.
									</p>
								</div>
								<a
									href="#"
									className="rounded-lg border-2 px-3 py-2 text-xs
									font-medium transition duration-300 bg-white text-blue-500
									 border-blue-500 hover:text-blue-600 hover:border-blue-600"
								>
									Upgrade
								</a>
							</div>
						</div> */}
					</div>
				</div>

				<Transition
					show={showHeader}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					className="fixed inset-0 bg-slate-900/75 backdrop-blur-sm"
					onClick={() => {
						setShowHeader(false);
					}}
				/>
			</header>

			<Outlet />
		</>
	) : (
		<Navigate to="/giris-yap" replace />
	);
};

export default Private;
