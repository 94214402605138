import { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAxios } from '../../hooks';
import GameWhoWantScoreTable from './GameWhoWantScoreTable';
import { Page } from '../../components';
import Pagination from '../../components/Pagination';
import usePermission from '../../hooks/usePermission';

const GameWhoWantScore = () => {
	const [showEdit, setShowEdit] = useState(false);
	const navigate = useNavigate();
	const { hasPermission, isLoading } = usePermission();

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};
	// Pagination states
	const [page, setPage] = useState(0);
	const [size, setSize] = useState(20);
	const [totalPages, setTotalPages] = useState(1);

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: `/game/admin/wwsList?page=${page}&size=${size}`,
		});
	};

	useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	useEffect(() => {
		if (api.getGames.response?.data) {
			setTotalPages(api.getGames.response.data.totalPages || 1);
		}
	}, [api.getGames.response?.data]);

	useEffect(() => {
		getGames();
	}, [page, size]);

	useEffect(() => {
		if (api.saveGame.response) {
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	if (!hasPermission('KİM 1000 PUAN İSTER')) {
		return <Navigate to="/" />;
	}

	return (
		<Page className="p-4 md:p-6 lg:p-8">
			<Page.Header
				header="Oyunlar"
				title="Kim X Puan İster"
				className="flex flex-col md:flex-row items-center justify-between gap-4"
			>
				<Page.Header.Add
					show
					onClick={() => navigate('/edit-who-want-score')}
					className="w-full md:w-auto"
				/>
			</Page.Header>
			<Page.Body
				className="w-full"
				loading={api.getGames.loading || api.getBrandStyles.loading}
				show={!api.getGames.loading && api.getGames.response}
			>
				<div className="overflow-x-auto">
					<GameWhoWantScoreTable
						saveGame={() => setShowEdit(true)}
						api={api}
						setShowEdit={setShowEdit}
						page={page}
						size={size}
						totalPages={totalPages}
						setPage={setPage}
						setSize={setSize}
						className="min-w-[300px] w-full"
					/>
				</div>
			</Page.Body>
			<Page.Footer className="mt-4" />
		</Page>
	);
};

export default GameWhoWantScore;
