import React from 'react';
import { IoAlertCircle } from 'react-icons/io5';

const FormCheckbox = ({
	id,
	name,
	label,
	grid,
	checked = false,
	errors,
	className,
	size = 'md',
	onChange,
}) => {
	const sizes = {
		xs: 'h-5 w-5',
		sm: 'h-6 w-6',
		md: 'h-7 w-7',
		lg: 'h-8 w-8',
		xl: 'h-10 w-10',
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault();
			if (onChange) {
				onChange(!checked);
			}
		}
	};

	return (
		<div
			className={`group ${errors && errors[name] ? 'invalid' : ''} ${className?.group || ''}`}
		>
			<div className={grid ? 'grid grid-cols-3 items-center' : ''}>
				{label && (
					<label
						className={`flex gap-1 text-sm font-normal px-2 text-slate-700 group-[.invalid]:!text-red-500 
            ${grid ? 'col-span-1' : 'mb-2'}
            ${className?.label || ''}`}
					>
						{label}
					</label>
				)}
				<div className={`relative ${grid ? 'col-span-2' : 'w-full'}`}>
					<div
						id={id || name}
						role="checkbox"
						tabIndex="0"
						aria-checked={checked}
						className={`relative z-10 cursor-pointer rounded-lg border-2 transition duration-300 text-slate-500 bg-slate-50 border-slate-200 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500 flex items-center justify-center
            ${sizes[size]} 
            ${checked ? 'bg-blue-500 border-blue-500 text-black' : ''} 
            ${className?.checkbox || ''}`}
						onClick={() => onChange && onChange(!checked)}
						onKeyDown={handleKeyDown}
					>
						{checked && '✔'}
					</div>
				</div>
			</div>

			{errors && errors[name] && (
				<div
					className={`relative -top-2 inline-flex gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500
          ${grid ? 'left-1/3 w-2/3' : 'w-full'}`}
				>
					<IoAlertCircle className="h-5 w-5 shrink-0" />
					{errors[name].message}
				</div>
			)}
		</div>
	);
};

export default FormCheckbox;
