import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

import { Button, Context, Input, Modal, Select } from '../../../components';
import { useAxios } from '../../../hooks';

const ProgramComponents = () => {
	const { id } = useParams();
	const [context] = useContext(Context);
	const navigate = useNavigate();
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const api = {
		getProgram: useAxios(),
		saveProgram: useAxios(),
		removeProgram: useAxios(),
		getProgramType: useAxios(),
	};

	useEffect(() => {
		console.log('context: ', context.signed.data.employeeFirm.role);
	}, [context]);

	const pageTitle = id ? 'Program Bileşeni Düzenle' : 'Program Bileşeni Ekle';

	const getProgramType = () => {
		api.getProgramType.fetch({
			method: 'GET',
			url: '/management/superadmin/getTypeList',
		});
	};

	// API çağrıları
	const getProgram = () => {
		if (id) {
			api.getProgram.fetch({
				method: 'GET',
				url: `/management/superadmin/get/component/${id}`,
			});
		}
	};

	useEffect(() => {
		getProgram();
		getProgramType();
	}, []);
	// API yanıtlarını yönetme

	useEffect(() => {
		if (api.getProgram.error) {
			toast.error(api.getProgram.error.message);
		}
	}, [api.getProgram.error]);

	useEffect(() => {
		const data = api.getProgram.response?.data;
		if (data) {
			setValue('programManagementName', data.programManagementName || '');
			setValue('components', data.components?.[0] || '');
			setValue('active', data.active || false);
		}
	}, [api.getProgram.response?.data]);

	useEffect(() => {
		if (api.saveProgram.response) {
			toast.success('Kaydedildi.');
			navigate(-1);
		}
	}, [api.saveProgram.response]);

	useEffect(() => {
		if (api.removeProgram.response) {
			toast.success('Başarıyla Silindi.');
			navigate(-1);
		}
	}, [api.removeProgram.response]);

	const handleSave = (data) => {
		const tenantName =
			localStorage.getItem('tenant') || context?.signed?.data?.tenantName;

		const requestData = {
			active: data.active,
			id: id ? parseInt(id, 10) : 0,
			names: [data.components],
		};
		api.saveProgram.fetch({
			method: 'POST',
			url: '/management/superadmin/save/programManagement/component',
			data: requestData,
			headers: {
				'Content-Type': 'application/json',
				'X-Tenant': tenantName,
			},
		});
	};

	const handleDelete = () => {
		api.removeProgram.fetch({
			method: 'DELETE',
			url: `/management/superadmin/delete/programManagement/component/${id}`,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	};

	return (
		<div
			className="container mx-auto p-4 m-4"
			style={{ overflow: 'scroll' }}
		>
			<div className="flex items-center gap-4">
				<button
					type="button"
					className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-full flex items-center gap-2"
					onClick={() => navigate(-1)}
				>
					<FaArrowLeft />
					Geri
				</button>
				<h1 className="text-sm lg:text-xl font-bold">{pageTitle}</h1>
			</div>

			<form onSubmit={handleSubmit(handleSave)} noValidate>
				<div className="flex justify-end mt-4 lg:px-20 pt-4 space-x-4">
					<Button
						type="submit"
						variant="primary"
						className="py-2 px-4"
					>
						Kaydet
					</Button>
					{id && (
						<Button
							variant="danger"
							className="py-2 px-4"
							onClick={() => setIsConfirmationModalOpen(true)}
						>
							Sil
						</Button>
					)}
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-2 md:px-20 pt-4">
					{id && (
						<Input
							register={register}
							errors={errors}
							grid
							type="text"
							label="Program Yönetimi Adı"
							name="programManagementName"
							placeholder="Lütfen giriniz."
							readOnly
							validation={{
								required: 'Program Yönetimi Adı zorunludur',
							}}
						/>
					)}
					<Select
						name="components"
						label="Bileşen"
						grid
						options={
							api.getProgramType.response?.data?.map((type) => ({
								name: type,
								value: type,
							})) || []
						}
						register={register}
						size="lg"
						className={{ select: '!text-sm' }}
						validation={{
							required: 'Bileşen seçimi zorunludur',
						}}
					/>
					<Select
						name="active"
						label="Durum"
						grid
						options={[
							{ name: 'Aktif', value: true },
							{ name: 'Pasif', value: false },
						]}
						register={register}
						size="lg"
						className={{ select: '!text-sm' }}
						validation={{
							required: 'Durum seçimi zorunludur',
						}}
					/>
				</div>

				<Modal
					show={isConfirmationModalOpen}
					title="Uyarı"
					onClose={() => setIsConfirmationModalOpen(false)}
				>
					<div className="p-4">
						<p className="text-sm text-slate-500">
							Oyun silindikten sonra geri getirilemez. Onaylıyor
							musunuz?
						</p>
					</div>

					<div className="flex justify-end p-4 space-x-2">
						<Button
							variant="white"
							onClick={() => setIsConfirmationModalOpen(false)}
						>
							Vazgeç
						</Button>
						<Button
							variant="danger"
							onClick={() => {
								handleDelete();
								setIsConfirmationModalOpen(false);
							}}
						>
							Evet
						</Button>
					</div>
				</Modal>
			</form>
		</div>
	);
};

export default ProgramComponents;
