import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
	Button,
	Item,
	Table,
	Time,
	Page,
	Switch,
	Context,
} from '../../components';

const ProgramComponentsTable = ({ api }) => {
	const navigate = useNavigate();
	const [context] = useContext(Context);
	const [gameList, setGameList] = useState([]);

	useEffect(() => {
		if (api.getComponentList.response?.data) {
			console.log(
				'api.getComponentList.response.data: ',
				api.getComponentList.response.data
			);
			setGameList(api.getComponentList.response.data);
		}
	}, [api.getComponentList.response?.data]);

	const handleChangeStatus = (data) => {
		if (!data) return;

		const tenantName =
			localStorage.getItem('tenant') || context?.signed?.data?.tenantName;

		const formattedData = {
			...data,
			name: data.name,
		};

		api.saveGame.fetch({
			method: 'POST',
			url: '/management/superadmin/save/programComponents',
			data: JSON.stringify(formattedData),
			headers: {
				'Content-Type': 'application/json',
				'X-Tenant': tenantName,
			},
		});
	};

	return (
		<Page.Content>
			<Table>
				<Table.Head data={['Id', 'Oyun', 'Bileşenler', 'Durum', '']} />
				<Table.Body>
					{api.getComponentList.response?.data?.length &&
						api.getComponentList.response.data.map(
							(item, index) => (
								<Table.Row key={index}>
									<Table.Column>
										<span className="whitespace-nowrap text-xs font-normal text-slate-400">
											{item.id}
										</span>
									</Table.Column>
									<Table.Column>
										<div className="flex flex-col">
											<span>
												{item?.programManagementName}
											</span>
										</div>
									</Table.Column>
									<Table.Column>
										<div className="flex flex-wrap gap-1">
											{item?.components?.length > 0 &&
												item?.components?.map(
													(component, idx) => (
														<span
															key={idx}
															className="px-2 py-1 text-xs bg-slate-100 rounded-md"
														>
															{component}
														</span>
													)
												)}
										</div>
									</Table.Column>
									<Table.Column>
										<span
											className={`px-2 py-1 text-xs rounded-md ${
												item.active
													? 'bg-green-100 text-green-700'
													: 'bg-red-100 text-red-700'
											}`}
										>
											{item.active ? 'Aktif' : 'Pasif'}
										</span>
									</Table.Column>
									<Table.Column className="text-right [&_button]:w-20">
										<Button
											size="xs"
											variant="secondary-outline"
											className="inline-flex"
											onClick={() => {
												navigate(
													`/duzenle-program-bilesenleri/${item.id}`
												);
											}}
										>
											Düzenle
										</Button>
									</Table.Column>
								</Table.Row>
							)
						)}
				</Table.Body>
			</Table>
		</Page.Content>
	);
};

export default ProgramComponentsTable;
