/* eslint-disable react/jsx-closing-tag-location */
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft, FaChevronDown } from 'react-icons/fa';
import { IoInformationOutline } from 'react-icons/io5';

import {
	Button,
	Context,
	Modal,
	Tooltip,
	Input,
} from '../../../components';
import { useAxios } from '../../../hooks';
import DatePicker from '../../../components/DatePicker';

const groupByCategory = (components) => {
	return components
		.filter((component) => component.componentName)
		.reduce((acc, component) => {
			const category = component.componentCategory || 'Genel';
			if (!acc[category]) {
				acc[category] = [];
			}
			acc[category].push(component);
			return acc;
		}, {});
};

const ProgramManagementEdit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);

	const [infoText] = React.useState(
		"Tenant'a tanımlı bileşenleri listelemektedir!"
	);

	const [programFormData, setProgramFormData] = useState({
		id: id || null,
		name: '',
		dateBegin: moment().format('YYYY-MM-DDTHH:mm'),
		dateEnd: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
	});
	const [context] = useContext(Context);
	const superAdmin = context?.signed?.data?.employeeFirm?.role;

	useEffect(() => {
		if (superAdmin !== 'SUPERADMIN') {
			navigate('/');
		}
	}, [superAdmin]);

	const api = {
		getProgram: useAxios(),
		getprogramComponents: useAxios(),
		saveProgram: useAxios(),
		removeProgram: useAxios(),
	};

	const pageTitle = id ? 'Program Düzenle' : 'Program Ekle';
	const [componentMap, setComponentMap] = useState({});
	const [activeComponentList, setActiveComponentList] = useState([]);
	const [openCategories, setOpenCategories] = useState({});

	const getProgram = (programId) => {
		if (programId) {
			api.getProgram.fetch({
				method: 'GET',
				url: `/management/admin/get/programManagement/${programId}`,
			});
		}
	};

	const getProgramComponents = () => {
		api.getprogramComponents.fetch({
			method: 'GET',
			url: '/management/admin/get/active/componentList',
		});
	};
	const {
		register: registerSaveProgram,
		unregister: unregisterSaveProgram,
		setValue: setValueSaveProgram,
		watch: watchSaveProgram,
		handleSubmit: handleSubmitSaveProgram,
		reset: resetSaveProgram,
		formState: { errors: errorsSaveProgram },
	} = useForm();

	useEffect(() => {
		if (id) {
			getProgram(id);
		}
		// Her durumda program bileşenlerini al
		getProgramComponents();
	}, [id]);

	useEffect(() => {
		const data = api.getProgram.response?.data;
		if (data) {
			// Form verilerini set et
			setProgramFormData({
				id: data.id || null,
				name: data.name || '',
				dateBegin:
					moment(data.dateBegin).format('YYYY-MM-DDTHH:mm') ||
					moment().format('YYYY-MM-DDTHH:mm'),
				dateEnd:
					moment(data.dateEnd).format('YYYY-MM-DDTHH:mm') ||
					moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
				// Kota bilgilerini form datasına da ekleyelim
				gameQuote: data.gameQuote,
				surveyQuote: data.surveyQuote,
				competitionQuote: data.competitionQuote,
			});

			// Kota bilgilerini form alanlarına set et
			// Sayısal değerler için Number kullanmak daha güvenli
			if (data.gameQuote !== undefined && data.gameQuote !== null) {
				setValueSaveProgram('gameQuote', Number(data.gameQuote));
			}
			if (data.surveyQuote !== undefined && data.surveyQuote !== null) {
				setValueSaveProgram('surveyQuote', Number(data.surveyQuote));
			}
			if (
				data.competitionQuote !== undefined &&
				data.competitionQuote !== null
			) {
				setValueSaveProgram(
					'competitionQuote',
					Number(data.competitionQuote)
				);
			}

			// managementComponentDTOList varsa, doğrudan bu listedeki active değerlerini kullan
			if (
				data.managementComponentDTOList &&
				data.managementComponentDTOList.length > 0
			) {
				const initialComponentMap =
					data.managementComponentDTOList.reduce((acc, component) => {
						acc[component.id] = component.active; // API'den gelen active değerini kullan
						return acc;
					}, {});
				setComponentMap(initialComponentMap);

				// Kategorileri varsayılan olarak açık yapalım
				const categories = groupByCategory(
					data.managementComponentDTOList
				);
				const initialOpenState = Object.keys(categories).reduce(
					(acc, category) => {
						acc[category] = true;
						return acc;
					},
					{}
				);
				setOpenCategories(initialOpenState);
			}
		}
	}, [api.getProgram.response?.data]);

	useEffect(() => {
		// id yoksa veya getProgram'dan managementComponentDTOList gelmemişse
		// yeni program ekleme durumu - tüm bileşenler aktif olarak ayarlanmalı
		if (api.getprogramComponents.response?.data) {
			setActiveComponentList(api.getprogramComponents.response.data);

			// Yeni program ekleme durumunda tüm bileşenleri aktif olarak ayarla
			// veya mevcut değerleri koru (düzenleme durumu)
			const initialComponentMap =
				api.getprogramComponents.response.data.reduce(
					(acc, component) => {
						// id yoksa (ekleme durumu) tüm bileşenler aktif olacak
						// id varsa (düzenleme durumu) mevcut componentMap'teki değerler korunacak
						if (!id) {
							acc[component.id] = true; // Ekleme sayfasında tümü aktif
						} else {
							acc[component.id] =
								componentMap[component.id] !== undefined
									? componentMap[component.id]
									: false; // Düzenleme sayfasında varsa mevcut değer, yoksa pasif
						}
						return acc;
					},
					{}
				);
			setComponentMap(initialComponentMap);

			// Kategorileri varsayılan olarak açık yapalım
			const categories = groupByCategory(
				api.getprogramComponents.response.data
			);
			const initialOpenState = Object.keys(categories).reduce(
				(acc, category) => {
					acc[category] = true;
					return acc;
				},
				{}
			);
			setOpenCategories(initialOpenState);
		}
	}, [api.getprogramComponents.response?.data, id]);

	useEffect(() => {
		if (api.getProgram.error) toast.error(api.getProgram.error.message);
	}, [api.getProgram.error]);

	useEffect(() => {
		// Form verileri değiştiğinde, form alanlarını sıfırla
		resetSaveProgram(programFormData);

		// Form sıfırlandıktan sonra, kota değerlerini tekrar ayarla
		if (
			programFormData.gameQuote !== undefined &&
			programFormData.gameQuote !== null
		) {
			setValueSaveProgram('gameQuote', Number(programFormData.gameQuote));
		}
		if (
			programFormData.surveyQuote !== undefined &&
			programFormData.surveyQuote !== null
		) {
			setValueSaveProgram(
				'surveyQuote',
				Number(programFormData.surveyQuote)
			);
		}
		if (
			programFormData.competitionQuote !== undefined &&
			programFormData.competitionQuote !== null
		) {
			setValueSaveProgram(
				'competitionQuote',
				Number(programFormData.competitionQuote)
			);
		}
	}, [programFormData]);

	const handleSubmit = async (data) => {
		try {
			// Değişken adını componentId olarak değiştirdik (id yerine)
			const componentsData = Object.entries(componentMap).map(
				([componentId, active]) => {
					// İlgili bileşeni bulalım
					const component =
						api.getprogramComponents.response?.data.find(
							(comp) => comp.id.toString() === componentId
						);

					return {
						id: parseInt(componentId, 10),
						active,
						// Eğer bileşen bilgileri bulunabiliyorsa ekleyelim
						...(component
							? {
									componentName: component.componentName,
									categoryName: component.componentCategory,
								}
							: {}),
					};
				}
			);

			const saveProgramDTO = {
				...(programFormData?.id ? { id: programFormData.id } : {}),
				name: data.name,
				dateBegin: moment(data.dateBegin).format(
					'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
				),
				dateEnd: moment(data.dateEnd).format(
					'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
				),
				// Kota bilgilerini ekle
				gameQuote: data.gameQuote ? parseInt(data.gameQuote, 10) : null,
				surveyQuote: data.surveyQuote
					? parseInt(data.surveyQuote, 10)
					: null,
				competitionQuote: data.competitionQuote
					? parseInt(data.competitionQuote, 10)
					: null,
				// Component bilgilerini ekle
				programComponentsDTO: componentsData,
			};

			console.log('saveProgramDTO', saveProgramDTO);

			const tenantName =
				localStorage.getItem('tenant') ??
				context?.signed?.data?.tenantName ??
				'defaultTenant';

			const response = await api.saveProgram.fetch({
				method: 'POST',
				url: '/management/superadmin/save/programManagement',
				data: JSON.stringify(saveProgramDTO),
				headers: {
					'Content-Type': 'application/json',
					'X-Tenant': tenantName,
				},
			});

			console.log('API Response:', response);
		} catch (error) {
			console.error('API Error:', error);
		}
	};

	const handleDelete = (programId) => {
		api.removeProgram.fetch({
			method: 'POST',
			url: `/management/superadmin/delete/programManagement/${programId}`,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	};

	useEffect(() => {
		if (api.removeProgram.response) {
			setIsModalOpen(false);
			setProgramFormData([]);
			toast.success('Kaydedildi.');
			navigate(-1);
		}
	}, [api.removeProgram.response]);

	useEffect(() => {
		if (api.saveProgram.response) {
			setIsModalOpen(false);
			setProgramFormData([]);
			toast.success('Başarıyla Kaydedildi.');
			navigate('/program-dashboard');
		}
	}, [api.saveProgram.response]);

	const groupedComponents = groupByCategory(
		api.getprogramComponents.response?.data || []
	);
	console.log('groupedComponents : ', groupedComponents);

	const toggleCategory = (category) => {
		setOpenCategories((prev) => ({
			...prev,
			[category]: !prev[category],
		}));
	};

	return (
		<div
			className="container mx-auto p-2 sm:p-4 m-2 sm:m-4"
			style={{ overflow: 'auto' }}
		>
			<div className="flex items-center gap-2 sm:gap-4">
				<button
					type="button"
					className="py-1 px-2 sm:py-2 sm:px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-full flex items-center gap-1 sm:gap-2 text-sm sm:text-base"
					onClick={() => navigate(-1)}
				>
					<FaArrowLeft />
					Geri
				</button>
				<h1 className="text-sm sm:text-xl font-bold">{pageTitle}</h1>
			</div>

			<form onSubmit={handleSubmitSaveProgram(handleSubmit)} noValidate>
				<div className="flex justify-end mt-2 sm:mt-4 px-2 sm:px-20 pt-2 sm:pt-4 space-x-2 sm:space-x-4">
					<Button
						type="submit"
						variant="primary"
						className="py-1 px-2 sm:py-2 sm:px-4 text-sm sm:text-base"
					>
						Kaydet
					</Button>
					{id && (
						<Button
							variant="danger"
							className="py-1 px-2 sm:py-2 sm:px-4 text-sm sm:text-base"
							onClick={() => setIsConfirmationModalOpen(true)}
						>
							Sil
						</Button>
					)}
				</div>

				<div className="grid grid-cols-1 gap-2 sm:gap-4 px-2 sm:px-20 pt-2 sm:pt-4">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-4">
						<Input
							type="text"
							name="name"
							label="Program Adı"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProgram}
							validation={{
								required: 'Program adı zorunludur.',
							}}
							value={programFormData.name}
							errors={errorsSaveProgram}
						/>
						{/* <Select
							name="targetGroupType"
							label="Hedef Kitle"
							grid
							options={[
								{
									name: 'Departmana Göre',
									value: 'DEPARTMAN',
								},
								{
									name: 'Puana Göre',
									value: 'PUAN',
								},
								{
									name: 'Bölgeye Göre',
									value: 'BÖLGE',
								},
							]}
							register={registerSaveProgram}
							validation={{ required: 'Hedef kitle zorunludur.' }}
							errors={errorsSaveProgram}
							size="lg"
							className={{ select: '!text-sm' }}
						/> */}

						<DatePicker
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveProgram('dateBegin')}
							setValue={setValueSaveProgram}
							register={registerSaveProgram}
							errors={errorsSaveProgram}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveProgram('dateEnd')}
							setValue={setValueSaveProgram}
							register={registerSaveProgram}
							errors={errorsSaveProgram}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						{/* <Input
							type="text"
							name="targetGroupValue"
							label="Hedef Kitle Değeri"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProgram}
							validation={{
								required: 'Hedef kitle değeri zorunludur.',
							}}
							value={programFormData.targetGroupValue}
							errors={errorsSaveProgram}
						/> */}
						{/* <TextArea
							name="illuminationText"
							label="Aydınlatma Metni"
							grid
							placeholder="Lütfen aydınlatma metni giriniz."
							size="lg"
							className={{ input: '!text-sm' }}
							defaultValue={programFormData.illuminationText}
							onChange={handleChange}
						/>
						<TextArea
							name="cookieText"
							label="Çerez Metni"
							grid
							placeholder="Lütfen çerez metni giriniz."
							size="lg"
							className={{ input: '!text-sm' }}
							defaultValue={programFormData.cookieText}
							onChange={handleChange}
						/>
						<TextArea
							name="gtmCode"
							label="Google Tag Manager"
							grid
							placeholder="Lütfen gtm code giriniz."
							size="lg"
							className={{ input: '!text-sm' }}
							defaultValue={programFormData.gtmCode}
							onChange={handleChange}
						/> */}
					</div>

					<div className="bg-white p-2 sm:p-4 rounded-lg col-span-full">
						<h2 className="text-base sm:text-lg font-bold mb-2 sm:mb-4">
							<span>Bileşenler</span>
							<Tooltip text={infoText} position="top">
								<button
									type="button"
									className="inline-flex items-center gap-2 text-xs text-slate-500"
								>
									<span className="mx-1 min-w-5 min-h-5 flex items-center justify-center rounded-full bg-slate-900">
										<IoInformationOutline
											className="text-white"
											size={16}
										/>
									</span>
								</button>
							</Tooltip>
						</h2>

						{/* Kota Alanı */}
						<div className="mb-6">
							<button
								type="button"
								className="w-full flex items-center justify-between cursor-pointer p-2 rounded hover:bg-gray-100 text-left"
								onClick={() => toggleCategory('KOTA_BILGILERI')}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										toggleCategory('KOTA_BILGILERI');
									}
								}}
							>
								<h3 className="text-base font-semibold text-slate-700">
									KOTA BİLGİLERİ
								</h3>
								<FaChevronDown
									className={`transition-transform ${openCategories.KOTA_BILGILERI ? 'transform rotate-180' : ''}`}
									size={16}
								/>
							</button>

							{openCategories.KOTA_BILGILERI && (
								<div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-3 p-2">
									<Input
										type="number"
										name="gameQuote"
										label="Oyun Kota Bilgisi"
										placeholder="ör : 300"
										register={registerSaveProgram}
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="number"
										name="surveyQuote"
										label="Anket Kota Bilgisi"
										placeholder="ör : 300"
										register={registerSaveProgram}
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="number"
										name="competitionQuote"
										label="Yarışma Kota Bilgisi"
										placeholder="ör : 300"
										register={registerSaveProgram}
										className={{ input: '!text-sm' }}
									/>
								</div>
							)}
						</div>

						{Object.entries(groupedComponents).map(
							([category, components]) => (
								<div key={category} className="mb-6">
									<button
										type="button"
										className="w-full flex items-center justify-between cursor-pointer p-2 rounded hover:bg-gray-100 text-left"
										onClick={() => toggleCategory(category)}
										onKeyDown={(e) => {
											if (
												e.key === 'Enter' ||
												e.key === ' '
											) {
												toggleCategory(category);
											}
										}}
									>
										<h3 className="text-base font-semibold text-slate-700">
											{category}
										</h3>
										<FaChevronDown
											className={`transition-transform ${openCategories[category] ? 'transform rotate-180' : ''}`}
											size={16}
										/>
									</button>

									{openCategories[category] && (
										<div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 select-none mt-3 p-2">
											{components.map((component) => (
												<div
													key={component.id}
													className="flex items-center p-2 border rounded transition-colors bg-white hover:bg-gray-50"
												>
													<div className="flex items-center flex-1 mr-2">
														<span
															className={`inline-block w-2 h-2 rounded-full mr-2 ${
																componentMap[
																	component.id
																]
																	? 'bg-green-500'
																	: 'bg-red-500'
															}`}
														/>
														<div className="overflow-hidden">
															<p
																className="font-medium text-gray-800 text-sm truncate"
																title={
																	component.componentName
																}
															>
																{component
																	.componentName
																	.length > 20
																	? `${component.componentName.substring(0, 15)}...`
																	: component.componentName}
															</p>
															<span
																className={`text-xs ${
																	componentMap[
																		component
																			.id
																	]
																		? 'text-green-600'
																		: 'text-red-600'
																}`}
															>
																{componentMap[
																	component.id
																]
																	? 'Aktif'
																	: 'Pasif'}
															</span>
														</div>
													</div>
													<button
														type="button"
														className={`px-2 py-1 text-xs rounded whitespace-nowrap ${
															componentMap[
																component.id
															]
																? 'bg-red-500 hover:bg-red-600 text-white'
																: 'bg-green-500 hover:bg-green-600 text-white'
														}`}
														onClick={() => {
															setComponentMap(
																(prev) => ({
																	...prev,
																	[component.id]:
																		!prev[
																			component
																				.id
																		],
																})
															);
														}}
													>
														{componentMap[
															component.id
														]
															? 'Pasif Yap'
															: 'Aktif Yap'}
													</button>
												</div>
											))}
										</div>
									)}
								</div>
							)
						)}
					</div>
				</div>
			</form>

			<Modal
				show={isConfirmationModalOpen}
				title="Uyarı"
				onClose={() => setIsConfirmationModalOpen(false)}
			>
				<div className="p-2 sm:p-4">
					<p className="text-xs sm:text-sm text-slate-500">
						Program silindikten sonra geri getirilemez. Onaylıyor
						musunuz?
					</p>
				</div>

				<div className="flex justify-end p-2 sm:p-4 space-x-2">
					<Button
						variant="white"
						className="text-sm sm:text-base"
						onClick={() => setIsConfirmationModalOpen(false)}
					>
						Vazgeç
					</Button>
					<Button
						variant="danger"
						className="text-sm sm:text-base"
						onClick={() => {
							handleDelete(programFormData?.id);
							setIsConfirmationModalOpen(false);
						}}
					>
						Evet
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default ProgramManagementEdit;
