import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { FaChartBar, FaEdit } from 'react-icons/fa';
import {
	Button,
	Table,
	Time,
	Page,
	Switch,
	Context,
} from '../../../components';

const ProgramManagementTable = ({ api }) => {
	const navigate = useNavigate();
	const [context] = useContext(Context);
	const [programList, setProgramList] = useState([]);

	useEffect(() => {
		if (api.getProgramList.response?.data) {
			setProgramList(api.getProgramList.response.data);
		}
	}, [api.getProgramList.response?.data]);

	const handleChangeStatus = (data) => {
		const programToUpdate = data;
		if (!programToUpdate) return;

		const formData = new FormData();
		formData.append(
			'saveAdminWhoWantsScoreDTO',
			JSON.stringify(programToUpdate)
		);

		const tenantName =
			localStorage.getItem('tenant') || context?.signed?.data?.tenantName;

		api.saveProgram.fetch({
			method: 'POST',
			url: '/management/superadmin/save/programManagement',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'X-Tenant': tenantName,
			},
		});
	};

	return (
		<Page.Content>
			<Table>
				<Table.Head data={['Id', 'Program', 'Tarih', '']} />
				<Table.Body>
					{api.getProgramList.response?.data?.length &&
						api.getProgramList.response.data.map((item, index) => (
							<Table.Row key={index}>
								<Table.Column>
									<span className="whitespace-nowrap text-xs font-normal text-slate-400">
										{item.id}
									</span>
								</Table.Column>

								<Table.Column className="min-w-[260px]">
									<span className="whitespace-nowrap text-xs font-normal text-slate-400">
										{item.name}
									</span>
								</Table.Column>

								<Table.Column>
									<Time
										dateBegin={moment(
											item.dateBegin
										).format('DD.MM.YYYY')}
										timeBegin={moment(
											item.dateBegin
										).format('HH:mm:ss')}
										dateEnd={moment(item.dateEnd).format(
											'DD.MM.YYYY'
										)}
										timeEnd={moment(item.dateEnd).format(
											'HH:mm:ss'
										)}
									/>
								</Table.Column>

								<Table.Column className="text-right [&_button]:w-30">
									<Button
										size="xs"
										variant="danger-outline"
										className="inline-flex mr-4"
										onClick={() => {
											navigate('/program-dashboard');
										}}
									>
										<FaChartBar /> Dashboard
									</Button>

									<Button
										size="xs"
										variant="secondary-outline"
										className="inline-flex"
										onClick={() => {
											navigate(
												`/program-yonetimi-duzenle/${item.id}`
											);
										}}
									>
										<FaEdit size={12} /> Düzenle
									</Button>
								</Table.Column>
							</Table.Row>
						))}
				</Table.Body>
			</Table>
		</Page.Content>
	);
};

export default ProgramManagementTable;
