import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
	Button,
	Item,
	Table,
	Time,
	Page,
	Switch,
	Pagination,
} from '../../components';

const GameLuckyWheelTable = ({
	api,
	setShowEdit,
	saveGame,
	page,
	size,
	totalPages,
	setPage,
	setSize,
	setShowProgram,
}) => {
	const [gameList, setGameList] = useState([]);

	useEffect(() => {
		if (api.getGames.response?.data) {
			setGameList(api.getGames.response.data);
		}
	}, [api.getGames.response?.data]);

	return (
		<Page.Content>
			<Table>
				<Table.Head data={['Id', 'Oyun', 'Tarih', 'Durum', '']} />
				<Table.Body>
					{api.getGames.response?.data?.content?.length &&
						api.getGames.response.data.content.map(
							(item, index) => (
								<Table.Row key={index}>
									<Table.Column>
										<span className="whitespace-nowrap text-xs font-normal text-slate-400">
											{item.id}
										</span>
									</Table.Column>
									<Table.Column>
										<Item
											image={
												item.icon
													? item.icon
													: require('../../assets/logo-connection.png')
											}
											title={item.title}
											description={item.description}
											className="w-96 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
										/>
									</Table.Column>
									<Table.Column>
										<Time
											dateBegin={moment(
												item.dateBegin
											).format('DD.MM.YYYY')}
											timeBegin={moment(
												item.dateBegin
											).format('HH:mm:ss')}
											dateEnd={moment(
												item.dateEnd
											).format('DD.MM.YYYY')}
											timeEnd={moment(
												item.dateEnd
											).format('HH:mm:ss')}
										/>
									</Table.Column>
									<Table.Column>
										<Switch
											id={item?.id}
											active={item?.active}
											list={gameList?.content}
											onChange={setGameList}
											saveList={saveGame}
										/>
									</Table.Column>

									<Table.Column className="text-right [&_button]:w-20">
										<Button
											size="xs"
											variant="secondary-outline"
											className="inline-flex"
											onClick={() => {
												setShowEdit({
													id: item.id,
												});
												setShowProgram(false);
											}}
										>
											Düzenle
										</Button>
									</Table.Column>
								</Table.Row>
							)
						)}
				</Table.Body>
			</Table>
			<div className="mt-4">
				<Pagination
					currentPage={page}
					totalPages={totalPages}
					onPageChange={(newPage) => setPage(newPage)}
					pageSize={size}
					onPageSizeChange={(newSize) => {
						setSize(newSize);
						setPage(0);
					}}
				/>
			</div>
		</Page.Content>
	);
};

export default GameLuckyWheelTable;
