/* eslint-disable max-len */
/* eslint-disable brace-style */
import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsFillXCircleFill } from 'react-icons/bs';

import { useAxios } from '../../hooks';
import {
	Button,
	Context,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Switch,
	Table,
	TextArea,
	Time,
	Pagination,
} from '../../components';
import DatePicker from '../../components/DatePicker';
import usePermission from '../../hooks/usePermission';

const BrandStyles = () => {
	const [context] = React.useContext(Context);
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentBrandImage, setCurrentBrandImage] = React.useState([]);
	const [params, setParams] = useSearchParams();

	// Pagination state'lerini ekleyelim
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	const { hasPermission, isLoading } = usePermission();

	/* form */
	const {
		register: registerSaveBrandStyle,
		setValue: setValueSaveBrandStyle,
		watch: watchSaveBrandStyle,
		handleSubmit: handleSubmitSaveBrandStyle,
		reset: resetSaveBrandStyle,
		formState: { errors: errorsSaveBrandStyle },
	} = useForm();

	/* api */
	const api = {
		getBrandStyleList: useAxios(),
		saveBrandStyle: useAxios(),
		removeBrandStyle: useAxios(),
		getBrands: useAxios(),
		getCategories: useAxios(),
		getSubCategories: useAxios(),
	};

	const [brandStyleList, setBrandStyleList] = React.useState([]);
	const [categories, setCategories] = React.useState([]);
	const [subCategories, setSubCategories] = React.useState([]);
	const [brands, setBrands] = React.useState([]);

	/* list brands */
	const getBrands = () => {
		api.getBrands.fetch({
			method: 'GET',
			url: '/brand/admin/brandList',
		});
	};

	// Component mount olduğunda markaları yükle
	React.useEffect(() => {
		getBrands();
	}, []);

	// Brands API yanıtını izle
	React.useEffect(() => {
		if (api.getBrands.response?.data?.content?.length > 0) {
			console.log('brands response:', api.getBrands.response.data.content);
			setBrands(api.getBrands.response.data.content);
		}
	}, [api.getBrands.response]);

	// Brands API hatasını izle
	React.useEffect(() => {
		if (api.getBrands.error) {
			toast.error(api.getBrands.error.message);
		}
	}, [api.getBrands.error]);

	/* list brands */
	const getBrandStyleList = () => {
		api.getBrandStyleList.fetch({
			method: 'GET',
			url: `/brand/admin/get/brandStyleList?page=${page}&size=${size}`,
		});
	};

	// Kategorileri yükleyen fonksiyon
	const getCategories = (brandId) => {
		api.getCategories.fetch({
			method: 'GET',
			url: `/brand/admin/brandCategoryList/${brandId}`,
		});
	};

	// Alt kategorileri yükleyen fonksiyon
	const getSubCategories = (categoryId) => {
		if (!categoryId) return;
		api.getSubCategories.fetch({
			method: 'GET',
			url: `/brand/admin/brandSubCategoryList/${categoryId}`,
		});
	};

	React.useEffect(() => {
		if (api.getBrandStyleList.response?.data) {
			setBrandStyleList(api.getBrandStyleList.response.data);
			// Pagination bilgilerini set edelim
			setTotalPages(api.getBrandStyleList.response.data.totalPages || 1);
		}
	}, [api.getBrandStyleList.response?.data]);

	// Sayfa değiştiğinde API'yi tekrar çağıralım
	React.useEffect(() => {
		getBrandStyleList();
	}, [page, size]);

	React.useEffect(() => {
		if (api.getBrandStyleList.response) {
			console.log('brandStyleList: ', api.getBrandStyleList.response);
		}
	}, [api.getBrandStyleList.response]);

	React.useEffect(() => {
		if (api.getBrandStyleList.error) {
			toast.error(api.getBrandStyleList.error.message);
		}
	}, [api.getBrandStyleList.error]);

	/* save brand */
	const saveBrandStyle = (data) => {
		const formData = new FormData();

		formData.append(
			'saveBrandStyleDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				dateBegin: data.dateBegin !== '' ? moment.utc(data.dateBegin).format() : '',
				dateEnd: data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
				imageOrder: parseInt(data.imageOrder, 10),
				price: parseFloat(data.price) || 0,
				topIcon: data.topIcon || false,
				topIconText: data.topIconText || '',
				productCode: data.productCode || '',
				brandId: parseInt(data.brandId, 10) || null,
				brandCategoryId: parseInt(data.brandCategoryId, 10) || null,
				brandSubCategoryId: parseInt(data.brandSubCategoryId, 10) || null,
				stock: parseFloat(data.stock) || 0,
				title: data.title || data.name,
				contents: data.contents || [],
			})
		);

		// Yeni görseller için - sadece File tipindeki görselleri 'icon' olarak gönder
		const newImages = currentBrandImage.filter((img) => img instanceof File);
		newImages.forEach((image) => {
			formData.append('icon', image);
		});

		api.saveBrandStyle.fetch({
			method: 'POST',
			url: '/brand/admin/brandStyle/save',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'X-Tenant': localStorage.getItem('tenant') || context?.signed?.data?.tenantName,
			},
		});
	};

	React.useEffect(() => {
		if (api.saveBrandStyle.response) {
			resetSaveBrandStyle();
			setCurrentBrandImage([]);
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getBrandStyleList();
		}
	}, [api.saveBrandStyle.response]);

	React.useEffect(() => {
		if (api.saveBrandStyle.error) {
			toast.error(api.saveBrandStyle.error.message);
		}
	}, [api.saveBrandStyle.error]);

	const removeBrandImage = (position) => {
		const images = [...currentBrandImage];
		images.splice(position, 1);
		setCurrentBrandImage(images);

		// Form state'indeki contents'i güncelle
		// Sadece kalan görselleri içerecek şekilde güncelleme yap
		const remainingImages = images.filter((img) => typeof img === 'string');
		setValueSaveBrandStyle('contents', remainingImages);
	};

	/* edit brand */
	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	// Modal'ı açarken ve kapatırken state'leri yönetmek için fonksiyon
	const handleModalClose = () => {
		setShowEdit(false);
		setCurrentBrandImage([]);
		setCategories([]);
		setSubCategories([]);
		resetSaveBrandStyle();
		setValueSaveBrandStyle('contents', []); // contents'i temizle
	};

	// Modal açıldığında verileri yüklemek için useEffect
	React.useEffect(() => {
		if (showEdit) {
			// Yeni ekleme durumunda sadece markaları yükle
			if (!showEdit.id) {
				getBrands();
			}

			// Düzenleme durumunda
			else if (showEdit.id && api.getBrandStyleList.response?.data) {
				const { id } = showEdit;
				const item = api.getBrandStyleList.response.data.content.find((object) => object.id === id);

				if (item) {
					// Form değerlerini set et
					const values = [
						{ name: item.name },
						{ title: item.title },
						{ description: item.description },
						{ imageOrder: item.imageOrder },
						{ dateBegin: moment(item.dateBegin).format('YYYY-MM-DD HH:mm:ss') },
						{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
						{ active: item.active },
						{ price: item.price },
						{ topIcon: item.topIcon },
						{ topIconText: item.topIconText },
						{ productCode: item.productCode },
						{ stock: item.stock }
					];

					values.forEach((value) => {
						setValueSaveBrandStyle(Object.keys(value)[0], Object.values(value)[0], {
							shouldValidate: true
						});
					});

					// Görselleri ayarla ve contents'i form state'ine ekle
					if (item.brandStyleContentDTOList?.length) {
						const images = item.brandStyleContentDTOList.map((content) => content.baseAddress);
						setCurrentBrandImage(images);
						setValueSaveBrandStyle('contents', images);
					}

					// Önce markaları yükle ve seç
					getBrands();
					setValueSaveBrandStyle('brandId', item.brandId, {
						shouldValidate: true
					});

					// Kategorileri yükle
					getCategories(item.brandId);

					// Kategori ve alt kategori değerlerini set et
					setTimeout(() => {
						setValueSaveBrandStyle('brandCategoryId', item.brandCategoryId, {
							shouldValidate: true
						});

						if (item.brandCategoryId) {
							getSubCategories(item.brandCategoryId);
							setValueSaveBrandStyle('brandSubCategoryId', item.brandSubCategoryId, {
								shouldValidate: true
							});
						}
					}, 100);
				}
			}
		}
	}, [showEdit]);

	// Marka seçildiğinde kategorileri yükle
	React.useEffect(() => {
		const brandId = watchSaveBrandStyle('brandId');
		if (brandId) {
			// Kategori ve alt kategori seçimlerini sıfırla
			setValueSaveBrandStyle('brandCategoryId', '');
			setValueSaveBrandStyle('brandSubCategoryId', '');
			setCategories([]);
			setSubCategories([]);
		}
	}, [watchSaveBrandStyle('brandId')]);

	/* remove brand */
	const removeBrandStyle = (id) => {
		api.removeBrandStyle.fetch({
			method: 'POST',
			url: `/brand/admin/delete/brandStyle/${id}`,
		});
	};

	React.useEffect(() => {
		if (api.removeBrandStyle.response) {
			setCurrentBrandImage([]);
			setShowEdit(false);
			toast.success('Silindi.');
			getBrandStyleList();
		}
	}, [api.removeBrandStyle.response]);

	React.useEffect(() => {
		if (api.removeBrandStyle.error) {
			toast.error(api.removeBrandStyle.error.message);
		}
	}, [api.removeBrandStyle.error]);

	// API responselarını handle et
	React.useEffect(() => {
		if (api.getCategories.response?.data?.content) {
			const categoriesData = api.getCategories.response.data.content;
			setCategories(categoriesData);

			// İlk kategoriyi otomatik seç eğer henüz seçili değilse
			if (categoriesData?.length > 0 && !watchSaveBrandStyle('brandCategoryId')) {
				setValueSaveBrandStyle('brandCategoryId', categoriesData[0].id);
			}
		}
	}, [api.getCategories.response]);

	React.useEffect(() => {
		if (api.getSubCategories.response?.data?.content) {
			const subCategoriesData = api.getSubCategories.response.data.content;
			setSubCategories(subCategoriesData);

			// İlk alt kategoriyi otomatik seç eğer henüz seçili değilse
			if (subCategoriesData?.length > 0 && !watchSaveBrandStyle('brandSubCategoryId')) {
				setValueSaveBrandStyle('brandSubCategoryId', subCategoriesData[0].id);
			}
		}
	}, [api.getSubCategories.response]);

	if (!isLoading && !hasPermission('MARKALAR')) {
		return <Navigate to="/" />;
	}

	return (
		<Page>
			<Page.Header header="Markalar" title="Marka Ürünleri">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getBrandStyleList.loading}
				show={!api.getBrandStyleList.loading && api.getBrandStyleList.response}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Ürün', 'Fiyat', 'Satılan', 'Tarih', 'Durum', '']}
						/>
						<Table.Body>
							{api.getBrandStyleList.response?.data?.content?.length &&
								api.getBrandStyleList.response.data.content.map(
									(item, index) => (
										<Table.Row key={`${index}.${item.id}`}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<Item
													image={
														item.brandStyleContentDTOList?.length > 0
															? item.brandStyleContentDTOList[0]?.baseAddress
															: require('../../assets/logo-connection.png')
													}
													title={item.name}
													description={item.description.replace(
														/(<([^>]+)>)/gi,
														''
													)}
													className="w-48 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
												/>
											</Table.Column>
											<Table.Column>
												<div className="text-base font-medium">
													{item.price ? `${item.price} ₺` : 0}
												</div>
												<div className="mt-1 text-xs text-slate-400">
													Oran: {item.multiplier || 1}
												</div>
											</Table.Column>
											<Table.Column>
												<div className="text-base font-medium">
													{item.sold ? item.sold : 0}
												</div>
												<div className="mt-1 text-xs text-slate-400">
													Stok: {item.stock}
												</div>
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												<Switch
													id={item.id}
													active={item.active}
													list={brandStyleList.content}
													onChange={setBrandStyleList}
													saveList={saveBrandStyle}
													customPopUpText={false}
												/>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<div className="flex flex-col items-end justify-center gap-2">
													<Button
														size="xs"
														variant="secondary-outline"
														className="inline-flex"
														onClick={() => {
															setShowEdit({
																id: item.id,
															});
														}}
													>
														Düzenle
													</Button>
												</div>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>

					{/* Pagination komponenti ekleyelim */}
					<div className="mt-4">
						<Pagination
							currentPage={page}
							totalPages={totalPages}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={size}
							onPageSizeChange={(newSize) => {
								setSize(newSize);
								setPage(0); // Sayfa boyutu değiştiğinde ilk sayfaya dön
							}}
						/>
					</div>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			{/* brand */}
			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getBrandStyleList.loading ||
					api.saveBrandStyle.loading ||
					api.removeBrandStyle.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={handleModalClose}
			>
				<form onSubmit={handleSubmitSaveBrandStyle(saveBrandStyle)} noValidate>
					<div className="flex flex-col gap-4 p-6">
						<div className="grid grid-cols-3 items-center">
							<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
								Görsel
							</label>
							<div className="col-span-2">
								<div className="mb-4 grid grid-cols-4 gap-4">
									{currentBrandImage &&
									currentBrandImage.length ? (
										currentBrandImage.map(
											(image, index) => (
												<div
													className="relative inline-block"
													key={index}
												>
													<img
														src={
															typeof image ===
															'object'
																? window.URL.createObjectURL(
																		image
																	)
																: image
														}
														key={index}
														alt=""
														className="aspect-square h-full w-full
															rounded-lg object-cover shadow bg-slate-50"
													/>
													<button
														type="button"
														className="absolute right-0 top-0 -translate-y-2 translate-x-2"
														onClick={() =>
															removeBrandImage(
																index
															)
														}
													>
														<BsFillXCircleFill
															className="translate h-5 w-5
															rounded-full border-2 bg-white border-white text-red-500"
														/>
													</button>
												</div>
											)
										)
									) : (
										<div
											className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
										>
											Görsel Bulunamadı
										</div>
									)}
								</div>
								<div className="relative">
									<input
										type="file"
										accept="image/*"
										onChange={(event) => {
											if (event?.target?.files?.[0]) {
												setCurrentBrandImage([
													...currentBrandImage,
													event.target.files[0],
												]);
											}
										}}
										className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
									/>
									{!currentBrandImage.length && (
										<div
											className={`flex h-9 w-full items-center justify-center whitespace-nowrap
											rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
											transition
											duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
										>
											Yeni Görsel Ekle
										</div>
									)}
								</div>
							</div>
						</div>
						<Input
							type="text"
							name="name"
							label="İsim"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<TextArea
							name="description"
							label="Açıklama"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="number"
							name="imageOrder"
							label="Sıra"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							defaultValue={0}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveBrandStyle('dateBegin')}
							setValue={setValueSaveBrandStyle}
							register={registerSaveBrandStyle}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveBrandStyle('dateEnd')}
							setValue={setValueSaveBrandStyle}
							register={registerSaveBrandStyle}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveBrandStyle}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<Input
							type="number"
							name="price"
							label="Fiyat"
							grid
							placeholder="Lütfen fiyat giriniz."
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							validation={{
								required: 'Bu alan zorunludur.',
								min: { value: 0, message: 'Fiyat 0\'dan küçük olamaz.' }
							}}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="text"
							name="productCode"
							label="Ürün Kodu"
							grid
							placeholder="Lütfen ürün kodu giriniz."
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							validation={{
								required: 'Bu alan zorunludur.'
							}}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="number"
							name="stock"
							label="Stok"
							grid
							placeholder="Lütfen stok miktarı giriniz."
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							validation={{
								required: 'Bu alan zorunludur.',
								min: { value: 0, message: 'Stok 0\'dan küçük olamaz.' }
							}}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="topIcon"
							label="Üst İkon"
							grid
							options={[
								{ name: 'Evet', value: true },
								{ name: 'Hayır', value: false }
							]}
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<Input
							type="text"
							name="topIconText"
							label="İkon Metni"
							grid
							placeholder="Lütfen ikon metni giriniz."
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							errors={errorsSaveBrandStyle}
							size="lg"
							className={{ input: '!text-sm' }}
						/>

						{brands?.length > 0 &&
							<Select
								name="brandId"
								label="Marka"
								grid
								placeholder="Lütfen marka seçiniz"
								options={brands.map((brand) => ({
									name: brand.name,
									value: brand.id
								}))}
								register={registerSaveBrandStyle}
								setValue={setValueSaveBrandStyle}
								validation={{
									required: 'Bu alan zorunludur.'
								}}
								errors={errorsSaveBrandStyle}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						}

						{categories?.length > 0 &&
							<Select
								name="brandCategoryId"
								label="Kategori"
								grid
								placeholder="Lütfen kategori seçiniz"
								options={categories.map((category) => ({
									name: category.name,
									value: category.id
								}))}
								register={registerSaveBrandStyle}
								setValue={setValueSaveBrandStyle}
								validation={{
									required: 'Bu alan zorunludur.'
								}}
								errors={errorsSaveBrandStyle}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						}

						{subCategories?.length > 0 &&
							<Select
								name="brandSubCategoryId"
								label="Alt Kategori"
								grid
								placeholder="Lütfen alt kategori seçiniz"
								options={subCategories.map((subCategory) => ({
									name: subCategory.name,
									value: subCategory.id
								}))}
								register={registerSaveBrandStyle}
								setValue={setValueSaveBrandStyle}
								validation={{
									required: 'Bu alan zorunludur.'
								}}
								errors={errorsSaveBrandStyle}
								size="lg"
								className={{ select: '!text-sm' }}
								disabled={!watchSaveBrandStyle('brandCategoryId')}
							/>
						}
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveBrandStyle}
							setValue={setValueSaveBrandStyle}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeBrandStyle(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveBrandStyle).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default BrandStyles;
