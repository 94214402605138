import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { BsFillXCircleFill } from 'react-icons/bs';
import {
	Button,
	Table,
	Page,
	Modal,
	FormInput,
	Context,
	Time,
} from '../../../components';
import { useAxios } from '../../../hooks';
import { modalFormInputsData } from './formInputsData';
import DatePicker from '../../../components/DatePicker';

const EditWhoWantScoreTable = ({
	gameFormData,
	setIsModalOpen,
	isModalOpen,
	getGames,
}) => {
	const [selectedGame, setSelectedGame] = useState(null);
	const [context] = useContext(Context);
	const [dateBegin, setDateBegin] = useState('');
	const [dateEnd, setDateEnd] = useState('');

	const {
		register: registerSaveGame,
		unregister: unregisterSaveGame,
		watch: watchSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm({
		defaultValues: {
			dateBegin: '',
			dateEnd: '',
		},
	});

	useEffect(() => {
		const subscription = watchSaveGame((value, { name }) => {
			if (name === 'dateBegin') {
				setDateBegin(value.dateBegin);
			}
			if (name === 'dateEnd') {
				setDateEnd(value.dateEnd);
			}
		});

		return () => subscription.unsubscribe();
	}, [watchSaveGame]);

	const closeModal = () => {
		setSelectedGame(null);
		setIsModalOpen(false);
		resetSaveGame();
	};

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	const removeGame = (id) => {
		api.removeGame.fetch({
			method: 'POST',
			url: '/game/admin/delete/wws/question',
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify(id),
		});
	};

	useEffect(() => {
		if (api.removeGame.response) {
			toast.success('Silindi.');
			getGames(gameFormData.id);
			closeModal();
		}
	}, [api.removeGame.response]);

	const handleAddQuestion = () => {
		const newQuestion = {
			title: '',
			description: '',
			score: null,
			dateBegin: moment().format('YYYY-MM-DD HH:mm:ss'),
			dateEnd: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
			whoWantsScoreChoiceDTOList: [],
		};

		setSelectedGame(newQuestion);
	};

	const handleAddChoice = (questionId) => {
		if (!selectedGame) return;

		const newChoice = {
			id: `deleted_${Date.now()}`,
			description: '',
			isTrue: false,
			whoWantsScoreQuestionId: questionId,
		};

		setSelectedGame((prevGame) => {
			const updatedChoices = [
				...(prevGame.whoWantsScoreChoiceDTOList || []),
				newChoice,
			];
			return {
				...prevGame,
				whoWantsScoreChoiceDTOList: updatedChoices,
				dateBegin: prevGame.dateBegin,
				dateEnd: prevGame.dateEnd,
			};
		});
	};

	const openModal = (item) => {
		if (item.id) {
			const formattedBegin = moment(item.dateBegin).format(
				'YYYY-MM-DDTHH:mm'
			);
			const formattedEnd = moment(item.dateEnd).format(
				'YYYY-MM-DDTHH:mm'
			);

			setSelectedGame({
				...item,
				dateBegin: formattedBegin,
				dateEnd: formattedEnd,
			});

			setDateBegin(formattedBegin);
			setDateEnd(formattedEnd);

			setValueSaveGame('dateBegin', formattedBegin);
			setValueSaveGame('dateEnd', formattedEnd);
		} else {
			setSelectedGame(item);
		}
		setIsModalOpen(true);
		if (!item.id) {
			handleAddQuestion();
		}
	};

	useEffect(() => {
		resetSaveGame(selectedGame);
	}, [selectedGame]);

	const handleSave = async () => {
		const hasCheckedChoice = selectedGame.whoWantsScoreChoiceDTOList.some(
			(choice) => choice.isTrue
		);
		if (!hasCheckedChoice) {
			toast.error('En az bir doğru yanıt seçmelisiniz.');
			return;
		}

		const hasEmptyChoices = selectedGame.whoWantsScoreChoiceDTOList.some(
			(choice) => !choice.description
		);
		if (hasEmptyChoices) {
			toast.error('Tüm şıklar doldurulmalıdır.');
			return;
		}

		const { ...otherData } = selectedGame;

		const transformedChoices = otherData.whoWantsScoreChoiceDTOList?.map(
			(choice) => {
				if (choice.id && String(choice.id).startsWith('deleted_')) {
					const { id, ...rest } = choice;
					return rest;
				}
				return choice;
			}
		);

		const questions = {
			whoWantsScoreId: gameFormData.id,
			whoWantsScoreQuestionDTOS: [
				{
					...otherData,
					whoWantsScoreChoiceDTOList: transformedChoices,
					dateBegin: dateBegin
						? moment(dateBegin).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
						: '',
					dateEnd: dateEnd
						? moment(dateEnd).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
						: '',
					score: parseInt(selectedGame.score, 10),
				},
			],
		};

		api.saveGame.fetch({
			method: 'POST',
			url: '/game/admin/save/wws/question',
			data: JSON.stringify(questions),
			headers: {
				'Content-Type': 'application/json',
				'X-Tenant': localStorage.getItem('tenant')
					? localStorage.getItem('tenant')
					: context?.signed?.data?.tenantName,
			},
		});
	};

	useEffect(() => {
		if (api.saveGame.response) {
			toast.success('Kaydedildi.');
			closeModal();
			getGames(gameFormData.id);
		}
	}, [api.saveGame.response]);

	const handleChange = (event, questionId, choiceId) => {
		const { name, value, type, checked } = event.target;

		setSelectedGame((prevGame) => {
			if (choiceId) {
				const updatedChoices = prevGame.whoWantsScoreChoiceDTOList.map(
					(choice) => {
						if (name === 'isTrue' && type === 'checkbox') {
							if (checked) {
								return {
									...choice,
									isTrue: choice.id === choiceId,
								};
							}

							if (choice.id === choiceId) {
								return { ...choice, isTrue: false };
							}
						}

						if (choice.id === choiceId) {
							return {
								...choice,
								[name]: type === 'checkbox' ? checked : value,
							};
						}

						return choice;
					}
				);

				return {
					...prevGame,
					whoWantsScoreChoiceDTOList: updatedChoices,
				};
			}

			return {
				...prevGame,
				[name]: type === 'checkbox' ? checked : value,
			};
		});
	};

	const handleChoiceChange = (e, index, questionId) => {
		const { name, type, checked, value } = e.target;

		setSelectedGame((prevSelectedGame) => {
			const updatedChoices =
				prevSelectedGame.whoWantsScoreChoiceDTOList.map(
					(choice, choiceIndex) => {
						if (choiceIndex === index) {
							if (name === 'isTrue' && type === 'checkbox') {
								return {
									...choice,
									isTrue: checked,
								};
							}
							return {
								...choice,
								[name]: type === 'checkbox' ? checked : value,
							};
						}
						if (
							name === 'isTrue' &&
							type === 'checkbox' &&
							checked
						) {
							return {
								...choice,
								isTrue: false,
							};
						}
						return choice;
					}
				);

			return {
				...prevSelectedGame,
				whoWantsScoreChoiceDTOList: updatedChoices,
			};
		});
	};

	const handleRemoveChoice = (choiceId) => {
		setSelectedGame((prevState) => {
			if (
				!prevState.whoWantsScoreChoiceDTOList ||
				!Array.isArray(prevState.whoWantsScoreChoiceDTOList)
			) {
				return prevState;
			}

			const updatedChoices = prevState.whoWantsScoreChoiceDTOList.filter(
				(choice) => choice.id !== choiceId
			);

			return {
				...prevState,
				whoWantsScoreChoiceDTOList: updatedChoices,
			};
		});
	};

	return (
		<Page.Content>
			{gameFormData?.id && (
				<div className="flex justify-end w-full mb-3">
					<button
						type="button"
						onClick={() => openModal({})}
						className="flex h-9 items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600 w-full sm:w-auto"
					>
						Yeni Soru Ekle
					</button>
				</div>
			)}
			<Table>
				<Table.Head
					data={[
						'Id',
						'Başlık',
						'Açıklama',
						'Başlangıç Tarihi',
						'Bitiş Tarihi',
						'Durum',
					]}
				/>
				<Table.Body>
					{gameFormData?.whoWantsScoreQuestionDTOList?.map(
						(item, index) => {
							if (!item.id) return null;

							return (
								<Table.Row key={item.id || index}>
									<Table.Column>
										<span className="whitespace-nowrap text-xs font-normal text-slate-400">
											{item.id}
										</span>
									</Table.Column>
									<Table.Column>
										<span className="text-sm font-medium">
											{item.title}
										</span>
									</Table.Column>
									<Table.Column>
										<span className="text-sm text-gray-500">
											{item.description}
										</span>
									</Table.Column>
									<Table.Column>
										<Time
											dateBegin={moment(
												item.dateBegin
											).format('DD.MM.YYYY')}
											timeBegin={moment(
												item.dateBegin
											).format('HH:mm:ss')}
										/>
									</Table.Column>
									<Table.Column>
										<Time
											dateBegin={moment(
												item.dateEnd
											).format('DD.MM.YYYY')}
											timeBegin={moment(
												item.dateEnd
											).format('HH:mm:ss')}
										/>
									</Table.Column>
									<Table.Column className="text-sm whitespace-nowrap">
										<Button
											size="xs"
											variant="secondary-outline"
											className="inline-flex"
											onClick={() => openModal(item)}
										>
											Düzenle
										</Button>
									</Table.Column>
								</Table.Row>
							);
						}
					)}
				</Table.Body>
			</Table>

			{isModalOpen && selectedGame && (
				<Modal
					title={`${selectedGame?.id ? 'Düzenle' : 'Ekle'}`}
					show={isModalOpen}
					size="lg"
					loading={api.saveGame.loading || api.removeGame.loading}
					onClose={() => {
						closeModal();
						setSelectedGame(null);
						setIsModalOpen(false);
					}}
				>
					<div className="p-4">
						<form
							onSubmit={handleSubmitSaveGame(handleSave)}
							noValidate
						>
							<div className="px-3">
								<Modal.Section title="Görünüm">
									{modalFormInputsData.map((input, index) => (
										<FormInput
											key={index}
											errors={errorsSaveGame}
											grid
											id={`${input.name}-${selectedGame?.id}`}
											name={input.name}
											type={input.type}
											label={input.label}
											value={
												selectedGame?.[input.name] || ''
											}
											{...registerSaveGame(input.name, {
												required: input.error
													? input.error
													: false,
											})}
											onChange={(e) => {
												handleChange(
													e,
													selectedGame?.id
												);
												setValueSaveGame(
													input.name,
													e.target.value
												);
											}}
											size="lg"
										/>
									))}

									<DatePicker
										id={`dateBegin-${selectedGame?.id}`}
										label="Başlangıç Tarihi"
										name="dateBegin"
										grid
										placeholder="Lütfen giriniz."
										value={watchSaveGame('dateBegin')}
										setValue={setValueSaveGame}
										size="lg"
									/>

									<DatePicker
										id={`dateEnd-${selectedGame?.id}`}
										label="Bitiş Tarihi"
										name="dateEnd"
										grid
										placeholder="Lütfen giriniz."
										value={watchSaveGame('dateEnd')}
										setValue={setValueSaveGame}
										size="lg"
									/>
								</Modal.Section>

								<Modal.Section title="Şıklar">
									{selectedGame.whoWantsScoreChoiceDTOList
										?.length ? (
										selectedGame.whoWantsScoreChoiceDTOList.map(
											(choice, index) => (
												<div
													key={index}
													className="relative pr-8 mb-4"
												>
													<FormInput
														errors={errorsSaveGame}
														id={`choice-${choice.id}`}
														name="description"
														grid
														type="text"
														label={`Seçenek ${index + 1}`}
														value={
															choice.description
														}
														onChange={(e) =>
															handleChoiceChange(
																e,
																index
															)
														}
														validation={{
															required:
																'Bu alan zorunludur.',
														}}
														size="lg"
														className={{
															input: '!text-sm',
														}}
													/>
													<div className="mb-4 mt-2 grid grid-cols-3">
														<div className="col-span-2 col-start-2 flex items-center gap-2 text-sm">
															<input
																id={`isTrue-${index}`}
																name="isTrue"
																className="cursor-pointer"
																type="checkbox"
																checked={
																	choice.isTrue
																}
																onChange={(e) =>
																	handleChoiceChange(
																		e,
																		index
																	)
																}
															/>
															<label
																className="cursor-pointer"
																htmlFor={`isTrue-${choice.id}`}
															>
																Doğru yanıt
															</label>
														</div>
													</div>
													<button
														className="absolute right-0 top-3.5"
														type="button"
														onClick={() =>
															handleRemoveChoice(
																choice.id
															)
														}
													>
														<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
													</button>
												</div>
											)
										)
									) : (
										<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
											Seçenek bulunamadı
										</div>
									)}

									<button
										className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600"
										type="button"
										onClick={() =>
											handleAddChoice(selectedGame.id)
										}
									>
										Yeni Şık Ekle
									</button>
								</Modal.Section>

								<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
									{selectedGame.id && (
										<Button
											type="button"
											variant="danger-outline"
											size="md"
											className="w-32"
											onClick={() =>
												removeGame(selectedGame.id)
											}
										>
											Sil
										</Button>
									)}
									<Button
										type="submit"
										variant="primary"
										size="md"
										disabled={
											Object.keys(errorsSaveGame).length >
											0
										}
										className="w-32"
									>
										Kaydet
									</Button>
								</div>
							</div>
						</form>
					</div>
				</Modal>
			)}
		</Page.Content>
	);
};

export default EditWhoWantScoreTable;
