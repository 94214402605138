import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Select, FormCheckbox, Input } from '../../components';
import { useAxios } from '../../hooks';
import DatePicker from '../../components/DatePicker';

const ScoreHuntProgramInputs = ({
	registerSaveGame,
	errorsSaveGame,
	frequency,
	selectedWeekDays,
	handleWeekDayChange,
	selectedMonthDays,
	handleMonthDayChange,
	selectedWeekType,
	handleWeekTypeChange,
	watchSaveGame,
	setValueSaveGame,
}) => {
	const [programList, setProgramList] = useState([]);

	const api = {
		getPrograms: useAxios(),
	};

	useEffect(() => {
		api.getPrograms.fetch({
			method: 'GET',
			url: '/management/admin/get/all/programManagement',
			headers: { 'Content-Type': 'application/json' },
		});
	}, []);

	useEffect(() => {
		if (api.getPrograms.response?.data) {
			setProgramList(api.getPrograms.response.data);
		}
	}, [api.getPrograms.response?.data]);

	return (
		<div className="grid grid-cols-1 gap-5 bg-slate-200 p-5 rounded-lg">
			{console.log(
				'programList',
				programList.map((program) => ({
					name: program.name,
					value: program.id,
				}))
			)}
			<Select
				name="programId"
				label="Program "
				grid
				options={programList.map((program) => ({
					name: program.name,
					value: program.id,
				}))}
				defaultValue={programList[0]?.id}
				key={programList[0]?.id}
				onChange={(e) => console.log(e)}
				register={registerSaveGame}
				validation={{ required: 'Bu alan zorunludur.' }}
				errors={errorsSaveGame}
				size="lg"
				className={{ select: '!text-sm' }}
			/>
			<Input
				type="text"
				name="name"
				label="Oyun İsmi"
				grid
				placeholder="Lütfen giriniz."
				register={registerSaveGame}
				validation={{
					required: 'Bu alan zorunludur.',
				}}
				errors={errorsSaveGame}
				size="lg"
				className={{ input: '!text-sm' }}
			/>
			<DatePicker
				name="dateBegin"
				label="Başlangıç Tarihi"
				grid
				placeholder="Lütfen giriniz."
				value={watchSaveGame('dateBegin')}
				setValue={setValueSaveGame}
				register={registerSaveGame}
				errors={errorsSaveGame}
				size="lg"
				className={{ input: '!text-sm' }}
			/>
			<DatePicker
				name="dateEnd"
				label="Bitiş Tarihi"
				grid
				placeholder="Lütfen giriniz."
				value={watchSaveGame('dateEnd')}
				setValue={setValueSaveGame}
				register={registerSaveGame}
				errors={errorsSaveGame}
				size="lg"
				className={{ input: '!text-sm' }}
			/>
			<Input
				type="number"
				name="score"
				label="Puan"
				grid
				placeholder="Lütfen giriniz."
				register={registerSaveGame}
				validation={{
					required: 'Bu alan zorunludur.',
				}}
				errors={errorsSaveGame}
				size="lg"
				className={{ input: '!text-sm' }}
			/>

			<Select
				name="definitionFrequencyType"
				label="Oynama Sıklığı"
				grid
				options={[
					{ name: 'Günlük', value: 'DAILY' },
					{ name: 'Haftalık', value: 'WEEKLY' },
					{ name: 'Aylık', value: 'MONTHLY' },
				]}
				register={registerSaveGame}
				validation={{ required: 'Bu alan zorunludur.' }}
				errors={errorsSaveGame}
				size="lg"
				className={{ select: '!text-sm' }}
			/>

			{frequency === 'WEEKLY' && (
				<>
					<div className="grid py-5 grid-cols-2 gap-2">
						{[
							{
								label: 'Tüm hafta boyunca aynı soru',
								value: 'SAME_QUESTION_ALL_WEEK',
							},
							{
								label: 'Her haftanın belirli günü',
								value: 'DIFFERENT_QUESTIONS_EVERY_DAY',
							},
						].map(({ label, value }) => (
							<FormCheckbox
								grid
								key={value}
								name="weeklyFrequencyType"
								label={label}
								value={value}
								checked={selectedWeekType.includes(value)}
								onChange={() => handleWeekTypeChange(value)}
								register={registerSaveGame}
								errors={errorsSaveGame}
							/>
						))}
					</div>
					{selectedWeekType.includes(
						'DIFFERENT_QUESTIONS_EVERY_DAY'
					) && (
						<div className="grid px-10 py-5 grid-cols-4 gap-2">
							{[
								{ label: 'Pazartesi', value: 'MONDAY' },
								{ label: 'Salı', value: 'TUESDAY' },
								{ label: 'Çarşamba', value: 'WEDNESDAY' },
								{ label: 'Perşembe', value: 'THURSDAY' },
								{ label: 'Cuma', value: 'FRIDAY' },
								{ label: 'Cumartesi', value: 'SATURDAY' },
								{ label: 'Pazar', value: 'SUNDAY' },
							].map(({ label, value }) => (
								<FormCheckbox
									key={value}
									name="selectedDays"
									label={label}
									value={value}
									checked={selectedWeekDays.includes(value)}
									onChange={() => handleWeekDayChange(value)}
									register={registerSaveGame}
									errors={errorsSaveGame}
								/>
							))}
						</div>
					)}
				</>
			)}

			{frequency === 'MONTHLY' && (
				<div className="grid py-5 grid-cols-2 gap-2">
					{[
						{ label: 'Ay Boyunca', value: 'ALL_MONTH' },
						{
							label: 'Her ayın belirli günü',
							value: 'SPECIFIC_DAY_OF_THE_MONTH',
						},
					].map(({ label, value }) => (
						<FormCheckbox
							key={value}
							name="monthlyFrequencyType"
							label={label}
							value={value}
							checked={selectedMonthDays.includes(value)}
							onChange={() => handleMonthDayChange(value)}
							register={registerSaveGame}
							errors={errorsSaveGame}
						/>
					))}
				</div>
			)}

			{selectedMonthDays.includes('SPECIFIC_DAY_OF_THE_MONTH') &&
				frequency === 'MONTHLY' && (
					<div className="grid">
						<Input
							name="specificDayOfTheMonth"
							grid
							label="Ayın Kaçıncı günü"
							type="number"
							register={registerSaveGame}
							validation={{
								required: 'Bu alan zorunludur.',
								min: {
									value: 1,
									message: 'Gün 1 ile 31 arasında olmalıdır.',
								},
								max: {
									value: 31,
									message: 'Gün 1 ile 31 arasında olmalıdır.',
								},
							}}
							errors={errorsSaveGame}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
					</div>
				)}
		</div>
	);
};

export default ScoreHuntProgramInputs;
