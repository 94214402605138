/* eslint-disable no-debugger */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useSearchParams, Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import LuckyWheelProgramInputs from './ProgramInputs';
import {
	Button,
	Context,
	FormInput,
	Modal,
	Page,
	Select,
} from '../../components';
import { useAxios } from '../../hooks';
import GameLuckyWheelQuestions from './GameLuckyWheelQuestions';
import GameLuckyWheelPoint from './GameLuckyWheelPoint';
import GameLuckyWheelTable from './GameLuckyWheelTable';
import DatePicker from '../../components/DatePicker';
import usePermission from '../../hooks/usePermission';

const GameLuckyWheel = () => {
	const [context] = React.useContext(Context);
	const { hasPermission, isLoading } = usePermission();
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [brandStyles, setBrandStyles] = React.useState([]);
	const [params, setParams] = useSearchParams();
	const [WheelPartList, setWheelPartList] = React.useState([]);
	const [gameFormData, setGameFormData] = React.useState({});

	// Pagination state'lerini ekleyelim
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	const {
		register: registerSaveGame,
		watch: watchSaveGame,
		unregister: unregisterSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm();

	// program modal
	const [showProgram, setShowProgram] = React.useState(true);
	const [selectedWeekDays, setSelectedWeekDays] = React.useState('');
	const [selectedMonthDays, setSelectedMonthDays] = React.useState('');
	const [selectedWeekType, setSelectedWeekType] = React.useState('');
	const [selectedScoreDate, setSelectedScoreDate] = React.useState([]);
	const programType = watchSaveGame('type', 'SINGLE');
	const frequency = watchSaveGame('definitionFrequencyType', 'GÜNLÜK');

	const handleMonthDayChange = (day) => {
		console.log(day);
		if (selectedMonthDays.includes(day)) {
			setSelectedMonthDays('');
		} else {
			setSelectedMonthDays(day);
		}
	};

	useEffect(() => {
		if (frequency !== 'WEEKLY') {
			setSelectedWeekDays('');
		}
	}, [frequency]);

	const handleWeekTypeChange = (type) => {
		console.log(type);
		if (type === 'SAME_QUESTIONS_EVERY_WEEK') {
			setSelectedWeekDays('');
		}
		setSelectedWeekType(type);
	};

	const handleWeekDayChange = (day) => {
		if (selectedWeekDays.includes(day)) {
			setSelectedWeekDays(selectedWeekDays.filter((d) => d !== day));
		} else {
			setSelectedWeekDays([...selectedWeekDays, day]);
		}
	};

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	const formatDate = (date) => {
		const formattedDate = new Date(date).toISOString().slice(0, 16);
		return formattedDate;
	};

	function removeDeletedIds(obj) {
		if (Array.isArray(obj)) {
			return obj.map(removeDeletedIds);
		}

		if (obj && typeof obj === 'object') {
			const { id, ...rest } = obj;

			const cleanedObj = String(id).startsWith('deleted_') ? rest : obj;

			return {
				...cleanedObj,
				luckyWheelQuestionDTOList: cleanedObj.luckyWheelQuestionDTOList
					? removeDeletedIds(cleanedObj.luckyWheelQuestionDTOList)
					: undefined,
				luckyWheelChoiceDTOList: cleanedObj.luckyWheelChoiceDTOList
					? removeDeletedIds(cleanedObj.luckyWheelChoiceDTOList)
					: undefined,
			};
		}

		return obj;
	}

	const handleChange = (event, questionId, itemId, choiceId) => {
		const { name, value, type, checked } = event.target;

		setGameFormData((prevFormData) => {
			const updatedFormData = {
				...prevFormData,
				[name]:
					type === 'checkbox' || type === 'radio' ? checked : value,
			};

			if (prevFormData.luckyWheelType === 'SCORE') {
				const updatedWheelParts = (
					prevFormData.wheelPartDTOList || []
				).map((part) => {
					if (part.id === questionId) {
						return { ...part, [name]: value };
					}
					return part;
				});

				return {
					...updatedFormData,
					wheelPartDTOList: updatedWheelParts,
				};
			}

			if (prevFormData.luckyWheelType === 'QUESTION') {
				const updatedWheelParts = (
					prevFormData.wheelPartDTOList || []
				).map((part) => {
					if (part.id === questionId) {
						if (!choiceId && !itemId) {
							return {
								...part,
								[name]: value,
							};
						}
						const updatedQuestions =
							part.luckyWheelQuestionDTOList.map((question) => {
								if (question.id === itemId) {
									if (choiceId) {
										const updatedChoices =
											question.luckyWheelChoiceDTOList.map(
												(choice) => {
													if (
														choice.id === choiceId
													) {
														return {
															...choice,
															[name]:
																type ===
																'checkbox'
																	? checked
																	: value,
														};
													}
													return {
														...choice,
														isTrue: false,
													};
												}
											);

										return {
											...question,
											luckyWheelChoiceDTOList:
												updatedChoices,
										};
									}

									return { ...question, [name]: value };
								}
								return question;
							});

						return {
							...part,
							luckyWheelQuestionDTOList: updatedQuestions,
						};
					}
					return part;
				});

				return {
					...updatedFormData,
					wheelPartDTOList: updatedWheelParts,
				};
			}

			return updatedFormData;
		});
	};

	const setTrueChoice = (questionIndex, choiceIndex) => {
		setWheelPartList((prevQuestions) => {
			const updatedQuestions = prevQuestions.map((question, index) => {
				if (index === questionIndex) {
					return {
						...question,
						trueChoice: choiceIndex,
					};
				}
				return question;
			});
			return updatedQuestions;
		});
	};

	const getBrandStyles = () => {
		api.getBrandStyles.fetch({
			method: 'GET',
			url: '/brand/get/brandStyleList',
		});
	};

	useEffect(() => {
		if (api.getBrandStyles.error) {
			toast.error(api.getBrandStyles.error.message);
		}
	}, [api.getBrandStyles.error]);

	useEffect(() => {
		getBrandStyles();
	}, []);

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: `/game/admin/getLuckyWheelList?page=${page}&size=${size}`,
		});
	};

	useEffect(() => {
		if (api.getGames.response?.data) {
			setTotalPages(api.getGames.response.data.totalPages || 1);
		}
	}, [api.getGames.response?.data]);

	useEffect(() => {
		getGames();
	}, [page, size]);

	useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	useEffect(() => {
		getGames();
	}, []);

	const saveGame = async (data, isSwitch) => {
		const formData = new FormData();
		let gameData = {};
		const sourceData = isSwitch === 'switch' ? data : gameFormData;
		const saveLuckyWheelDTO = {
			...sourceData,
			active: !!(
				sourceData.active === 'true' || sourceData.active === true
			),
			dateBegin:
				sourceData.dateBegin !== ''
					? moment.utc(sourceData.dateBegin).format()
					: '',
			dateEnd:
				sourceData.dateEnd !== ''
					? moment.utc(sourceData.dateEnd).format()
					: '',
		};

		// JSON içindeki istenmeyen alanları temizle
		const keysToDelete = [
			'score',
			'text',
			'textColor',
			'color',
			'selectionLimit',
			'percentage',
		];
		keysToDelete.forEach((key) => {
			delete saveLuckyWheelDTO[key];
		});

		saveLuckyWheelDTO.wheelPartDTOList =
			saveLuckyWheelDTO?.wheelPartDTOList?.map((part) => {
				if (String(part?.id).startsWith('deleted_')) {
					const { id, ...rest } = part;
					return rest;
				}
				return part;
			});

		saveLuckyWheelDTO.wheelPartDTOList = removeDeletedIds(
			saveLuckyWheelDTO.wheelPartDTOList
		);

		if (currentImage.length && currentImage[0]) {
			if (typeof currentImage[0] === 'string') {
				saveLuckyWheelDTO.contents = [currentImage[0]];
			} else if (currentImage[0] instanceof File) {
				formData.append('icon', currentImage[0]);
			}
		}

		formData.append('saveLuckyWheelDTO', JSON.stringify(saveLuckyWheelDTO));

		if (programType === 'MULTIPLE') {
			const {
				active,
				backgroundPattern,
				buttonText,
				title,
				description,
				type,
				inGameDescription,
				trueAnswerText,
				wrongAnswerText,
				timeoutAnswerText,
				question,
				numberOfLetterTry,
				numberOfTry,
				questionTitle,
				correctWord,
				remainTime,
				...filteredData
			} = data;

			gameData = {
				...filteredData,
				dateBegin: data.dateBegin
					? moment.utc(data.dateBegin).format()
					: '',
				dateEnd: data.dateEnd ? moment.utc(data.dateEnd).format() : '',
				...(selectedWeekDays ? { selectedDays: selectedWeekDays } : {}),
				...(selectedWeekType
					? { weeklyFrequencyType: selectedWeekType }
					: {}),
				...(selectedMonthDays
					? { monthlyFrequencyType: selectedMonthDays }
					: {}),
			};

			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/create/program/luckyWheel',
				data: gameData,
				headers: {
					'Content-Type': 'application/json',
					'X-Tenant': localStorage.getItem('tenant')
						? localStorage.getItem('tenant')
						: context?.signed?.data?.tenantName,
				},
			});
		} else {
			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/save/luckyWheel',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
					'X-Tenant': localStorage.getItem('tenant')
						? localStorage.getItem('tenant')
						: context?.signed?.data?.tenantName,
				},
			});
		}
	};

	useEffect(() => {
		if (api.saveGame.response) {
			resetSaveGame();
			setCurrentImage([]);
			setShowEdit(false);
			setShowProgram(true);
			setWheelPartList([]);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	useEffect(() => {
		if (api.saveGame.error) {
			toast.error(api.saveGame.error.message);
		}
	}, [api.saveGame.error]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
			setShowProgram(false);
		}
	}, [params.get('edit')]);

	useEffect(() => {
		if (
			showEdit.id &&
			api.getGames.response &&
			api.getBrandStyles.response
		) {
			const { id } = showEdit;
			const item = api.getGames.response.data.content.find(
				(object) => object.id === id
			);

			let formattedValues = {};

			if (item) {
				formattedValues = {
					id: item.id,
					title: item.title,
					description: item.description,
					buttonText: item.buttonText,
					remainTime: item.remainTime,
					dateBegin: formatDate(item.dateBegin),
					dateEnd: formatDate(item.dateEnd),
					active: item.active,
					inGameDescription: item.inGameDescription,
					backgroundPattern: item.backgroundPattern,
					luckyWheelType: item.luckyWheelType,
					wrongAnswerText: item.wrongAnswerText,
					trueAnswerText: item.trueAnswerText,
					timeoutAnswerText: item.timeoutAnswerText,
					wheelPartDTOList: item.wheelPartDTOList?.map(
						(wheelPart) => ({
							id: wheelPart.id,
							text: wheelPart.text,
							textColor: wheelPart.textColor,
							color: wheelPart.color,
							selectionLimit: wheelPart.selectionLimit,
							unlimitedStock: wheelPart.unlimitedStock,
							percentage: wheelPart.percentage,
							score: wheelPart.score,
							luckyWheelQuestionDTOList:
								wheelPart.luckyWheelQuestionDTOList?.map(
									(question) => ({
										id: question.id,
										title: question.title,
										description: question.description,
										luckyWheelChoiceDTOList:
											question.luckyWheelChoiceDTOList?.map(
												(choice) => ({
													id: choice.id,
													description:
														choice.description,
													isTrue: choice.isTrue,
												})
											),
									})
								),
						})
					),
					icon: item.icon,
				};

				if (item.icon) {
					const images = [];
					images.push(item.icon);
					setCurrentImage(images);
				}

				setGameFormData(formattedValues);
				setValueSaveGame('gameData', formattedValues, {
					shouldValidate: true,
				});
				setWheelPartList(formattedValues.wheelPartDTOList);
			}
		}
	}, [showEdit.id, api.getGames.response, api.getBrandStyles.response]);

	const handleRemoveChoice = (questionId, choiceId) => {
		setGameFormData((prevFormData) => ({
			...prevFormData,
			wheelPartDTOList: prevFormData.wheelPartDTOList.map((part) => {
				return {
					...part,
					luckyWheelQuestionDTOList:
						part.luckyWheelQuestionDTOList.map((question) => {
							if (question.id === questionId) {
								return {
									...question,
									luckyWheelChoiceDTOList:
										question.luckyWheelChoiceDTOList.filter(
											(choice) => choice.id !== choiceId
										),
								};
							}
							return question;
						}),
				};
			}),
		}));
	};

	const handleRemoveCategory = (categoryId) => {
		setGameFormData((prevFormData) => ({
			...prevFormData,
			wheelPartDTOList: prevFormData?.wheelPartDTOList?.filter(
				(part) => part.id !== categoryId
			),
		}));
	};

	const removeGame = (id) => {
		const data = { id };

		api.removeGame.fetch({
			method: 'POST',
			url: '/game/admin/delete/luckyWheel',
			data,
		});
	};

	useEffect(() => {
		if (api.removeGame.response) {
			setShowEdit(false);
			setShowProgram(true);
			toast.success('Silindi.');
			getGames();
		}
	}, [api.removeGame.response]);

	useEffect(() => {
		if (api.removeGame.error) {
			toast.error(api.removeGame.error.message);
		}
	}, [api.removeGame.error]);

	const handleAddChoice = (wheelPartId, questionId) => {
		setGameFormData((prevFormData) => ({
			...prevFormData,
			wheelPartDTOList: prevFormData.wheelPartDTOList.map((part) => {
				if (part.id === wheelPartId) {
					return {
						...part,
						luckyWheelQuestionDTOList:
							part.luckyWheelQuestionDTOList.map((question) => {
								if (question.id === questionId) {
									return {
										...question,
										luckyWheelChoiceDTOList: [
											...(question.luckyWheelChoiceDTOList ||
												[]),
											{
												id: `deleted_${new Date().getTime()}`,
												description: '',
												isTrue: false,
											},
										],
									};
								}
								return question;
							}),
					};
				}
				return part;
			}),
		}));
	};

	const handleAddCategory = () => {
		if (gameFormData.luckyWheelType === 'SCORE') {
			setGameFormData((prevFormData) => ({
				...prevFormData,
				wheelPartDTOList: [
					...(prevFormData?.wheelPartDTOList || []),
					{
						id: `deleted_${new Date().getTime()}`,
						text: '',
						color: '',
						percentage: '',
						score: '',
						selectionLimit: '',
						textColor: '',
					},
				],
			}));
		} else {
			setGameFormData((prevFormData) => ({
				...prevFormData,
				wheelPartDTOList: [
					...(prevFormData?.wheelPartDTOList || []),
					{
						id: `deleted_${new Date().getTime()}`,
						text: '',
						color: '',
						percentage: '',
						score: '',
						selectionLimit: '',
						textColor: '',
						luckyWheelQuestionDTOList: [],
					},
				],
			}));
		}
	};

	const handleAddQuestion = (wheelPartId) => {
		setGameFormData((prevFormData) => ({
			...prevFormData,
			wheelPartDTOList: prevFormData.wheelPartDTOList.map((part) => {
				if (part.id === wheelPartId) {
					return {
						...part,
						luckyWheelQuestionDTOList: [
							...(part.luckyWheelQuestionDTOList || []),
							{
								id: `deleted_${new Date().getTime()}`,
								title: '',
								description: '',
								luckyWheelChoiceDTOList: [],
							},
						],
					};
				}
				return part;
			}),
		}));
	};

	if (!isLoading && !hasPermission('ŞANS ÇARKI')) {
		return <Navigate to="/" />;
	}

	return (
		<Page>
			<Page.Header header="Oyunlar" title="Soru Çarkı">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getGames.loading || api.getBrandStyles.loading}
				show={!api.getGames.loading && api.getGames.response}
			>
				<GameLuckyWheelTable
					saveGame={saveGame}
					api={api}
					setShowEdit={setShowEdit}
					page={page}
					size={size}
					totalPages={totalPages}
					setPage={setPage}
					setSize={setSize}
					setShowProgram={setShowProgram}
				/>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getGames.loading ||
					api.saveGame.loading ||
					api.removeGame.loading ||
					api.getBrandStyles.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setShowProgram(true);
					setCurrentImage([]);
					setBrandStyles([]);
					setWheelPartList([]);
					setGameFormData([]);

					resetSaveGame();
				}}
			>
				<form onSubmit={handleSubmitSaveGame(saveGame)} noValidate>
					<div className="px-6">
						{showProgram && (
							<Modal.Section title="Oyun Türü">
								<Select
									name="type"
									label="Oyun Türü"
									grid
									options={[
										{
											name: 'Tekli',
											value: 'SINGLE',
										},
										{
											name: 'Çoklu',
											value: 'MULTIPLE',
										},
									]}
									register={registerSaveGame}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									value={programType}
									errors={errorsSaveGame}
									size="lg"
									className={{ select: '!text-sm' }}
								/>

								{programType === 'MULTIPLE' && (
									<LuckyWheelProgramInputs
										errorsSaveGame={errorsSaveGame}
										registerSaveGame={registerSaveGame}
										selectedWeekDays={selectedWeekDays}
										handleWeekDayChange={
											handleWeekDayChange
										}
										selectedMonthDays={selectedMonthDays}
										handleMonthDayChange={
											handleMonthDayChange
										}
										selectedScoreDate={selectedScoreDate}
										setSelectedScoreDate={
											setSelectedScoreDate
										}
										selectedWeekType={selectedWeekType}
										handleWeekTypeChange={
											handleWeekTypeChange
										}
										frequency={frequency}
										programType={programType}
										watchSaveGame={watchSaveGame}
										setValueSaveGame={setValueSaveGame}
									/>
								)}
							</Modal.Section>
						)}
						{programType === 'SINGLE' && (
							<>
								<Modal.Section title="İçerik">
									<FormInput
										type="text"
										label="Başlık"
										name="title"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.title}
										onChange={handleChange}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>

									<FormInput
										type="text"
										label="Açıklama"
										name="description"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.description}
										onChange={(e) => handleChange(e)}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>

									<DatePicker
										label="Başlangıç Tarihi"
										name="dateBegin"
										grid
										placeholder="Lütfen giriniz."
										value={watchSaveGame('dateBegin')}
										setValue={setValueSaveGame}
										register={registerSaveGame}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<DatePicker
										label="Bitiş Tarihi"
										name="dateEnd"
										grid
										placeholder="Lütfen giriniz."
										value={watchSaveGame('dateEnd')}
										setValue={setValueSaveGame}
										register={registerSaveGame}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>

									{gameFormData.luckyWheelType ===
										'QUESTION' && (
										<FormInput
											type="number"
											label="Kalan Süre"
											name="remainTime"
											grid
											placeholder="Lütfen giriniz."
											value={watchSaveGame('remainTime')}
											setValue={setValueSaveGame}
											register={registerSaveGame}
											errors={errorsSaveGame}
											size="lg"
											className={{ input: '!text-sm' }}
										/>
									)}
									<FormInput
										type="text"
										label="Oyun İçi Açıklama"
										name="inGameDescription"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.inGameDescription}
										onChange={handleChange}
										register={registerSaveGame}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Select
										label="Durum"
										grid
										name="active"
										options={[
											{ name: 'Aktif', value: true },
											{ name: 'Pasif', value: false },
										]}
										value={gameFormData.active}
										onChange={handleChange}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ select: '!text-sm' }}
									/>
								</Modal.Section>

								<Modal.Section title="Görünüm">
									<FormInput
										type="text"
										label="Düğme Metni"
										name="buttonText"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.buttonText}
										onChange={handleChange}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<FormInput
										type="text"
										label="Arka Plan Rengi"
										name="backgroundPattern"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.backgroundPattern}
										onChange={handleChange}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<FormInput
										type="text"
										label="Doğru Yanıt Metni"
										name="trueAnswerText"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.trueAnswerText}
										onChange={handleChange}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<FormInput
										type="text"
										label="Yanlış Yanıt Metni"
										name="wrongAnswerText"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.wrongAnswerText}
										onChange={handleChange}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<FormInput
										type="text"
										label="Zaman Aşımı Metni"
										name="timeoutAnswerText"
										grid
										placeholder="Lütfen giriniz."
										value={gameFormData.timeoutAnswerText}
										onChange={handleChange}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
								</Modal.Section>

								<Modal.Section title="Soru Türü">
									<Select
										name="luckyWheelType"
										label="Soru Türü"
										options={[
											{ name: 'Seçiniz', value: '' },
											{ name: 'Soru', value: 'QUESTION' },
											{ name: 'Puan', value: 'SCORE' },
										]}
										value={gameFormData.luckyWheelType}
										onChange={(e) => handleChange(e)}
									/>
								</Modal.Section>

								{gameFormData.luckyWheelType === 'SCORE' && (
									<GameLuckyWheelPoint
										partTitle="Puan"
										wheelPartList={
											gameFormData.wheelPartDTOList
										}
										errorsSaveGame={errorsSaveGame}
										handleRemoveCategory={
											handleRemoveCategory
										}
										handleAddCategory={handleAddCategory}
										handleChange={handleChange}
									/>
								)}
								{gameFormData.luckyWheelType === 'QUESTION' && (
									<GameLuckyWheelQuestions
										partTitle="Soru"
										WheelPartList={
											gameFormData.wheelPartDTOList
										}
										registerSaveGame={registerSaveGame}
										errorsSaveGame={errorsSaveGame}
										handleRemoveCategory={
											handleRemoveCategory
										}
										handleAddCategory={handleAddCategory}
										handleAddQuestions={handleAddQuestion}
										handleAddChoice={handleAddChoice}
										handleRemoveChoice={handleRemoveChoice}
										setTrueChoice={setTrueChoice}
										handleChange={handleChange}
									/>
								)}

								<Modal.Section title="Görsel">
									<div className="group">
										<div className="grid grid-cols-3 items-center">
											<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
												Simge
											</label>
											<div className="col-span-2">
												<div className="mb-4 grid grid-cols-4 gap-4">
													{currentImage &&
													currentImage.length ? (
														currentImage.map(
															(image, index) => (
																<div
																	className="relative inline-block"
																	key={index}
																>
																	<img
																		src={
																			typeof image ===
																			'object'
																				? window.URL.createObjectURL(
																						image
																					)
																				: image
																		}
																		alt=""
																		className="aspect-square h-full w-full rounded-lg object-cover shadow bg-slate-50"
																	/>
																	<button
																		type="button"
																		className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																		onClick={() =>
																			removeImage(
																				index
																			)
																		}
																	>
																		<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
																	</button>
																</div>
															)
														)
													) : (
														<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
															Görsel Bulunamadı
														</div>
													)}
												</div>
												<div className="relative">
													<input
														type="file"
														accept="image/*"
														onChange={(event) => {
															if (
																event?.target
																	?.files?.[0]
															) {
																setCurrentImage(
																	[
																		...currentImage,
																		event
																			.target
																			.files[0],
																	]
																);
															}
														}}
														className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
													/>
													{!currentImage.length && (
														<div className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600">
															Yeni Görsel Ekle
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</Modal.Section>
							</>
						)}
					</div>

					{showEdit.id && (
						<FormInput
							type="hidden"
							name="id"
							register={registerSaveGame}
							value={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => removeGame(showEdit.id)}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveGame).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default GameLuckyWheel;
