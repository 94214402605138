import React, { useRef } from 'react';
import { BiUpload } from 'react-icons/bi';
import Button from '../../../Button';

const Upload = ({ onClick, show, className }) => {
	const fileInputRef = useRef(null);

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			console.log('Yüklenen dosya:', file);
			onClick?.(file); // Eğer onClick fonksiyonu varsa, dosya bilgisiyle çağır
		}
	};

	return (
		show && (
			<>
				<Button className={className} variant="primary" size="sm" onClick={handleClick}>
					<BiUpload className="h-4 w-4" />
					Puan Yükle
				</Button>
				<input
					type="file"
					ref={fileInputRef}
					className="hidden"
					onChange={handleFileChange}
					accept=".csv,.xlsx,.txt"
				/>
			</>
		)
	);
};

export default Upload;
