import React from 'react';
import { BiPlusCircle } from 'react-icons/bi';

import Button from '../../../Button';

const Add = ({ onClick, show, className }) =>
	show && (
		<Button className={className} size="sm" onClick={onClick}>
			<BiPlusCircle className="h-4 w-4" />
			Yeni Ekle
		</Button>
	);

export default Add;
