import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BsFillXCircleFill } from 'react-icons/bs';
import { FaArrowLeft } from 'react-icons/fa';

import { Button, Context, FormInput, Select } from '../../components';
import { useAxios } from '../../hooks';
import { formInputsData } from './formInputsData';

const PlatformManagement = () => {
	const navigate = useNavigate();

	const [platFormManagementData, setPlatformManagementData] = useState({
		pageTitle: '',
		metaDescription: '',
		blue: '',
		blueLight: '',
		blueLighter: '',
		blueDark: '',
		loginPageTitle: '',
		loginPageDescription: '',
	});

	const [logo, setLogo] = useState([]);
	const [banner, setBanner] = useState([]);
	const [favicon, setFavicon] = useState([]);
	const [context] = useContext(Context);

	const api = {
		getPlatformManagement: useAxios(),
		savePlatformManagement: useAxios(),
	};
	const {
		register: registerSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm();

	const pageTitle = 'Platform Yönetimi';

	// const formatDate = (date) => {
	// 	const parsedDate = new Date(date);

	// 	if (Number.isNaN(parsedDate.getTime())) {
	// 		return '';
	// 	}

	// 	return parsedDate.toISOString().slice(0, 16);
	// };

	const getPlatformManagement = () => {
		let apiUrl;

		if (context?.signed?.data?.employeeFirm?.role === 'SUPERADMIN') {
			if (localStorage.getItem('tenant')) {
				apiUrl = `/auth/admin/tenant/getLoginSetting?tenantId=${localStorage.getItem('tenant')}`;
			} else {
				apiUrl = `/auth/admin/tenant/getLoginSetting?tenantId=${context?.signed?.data?.tenantId}`;
			}
		} else {
			apiUrl = '/auth/admin/tenant/getLoginSetting';
		}

		api.getPlatformManagement.fetch({
			method: 'GET',
			url: apiUrl,
		});
	};

	useEffect(() => {
		getPlatformManagement();
	  return () => {};
	}, []);

	useEffect(() => {
		if (api.getPlatformManagement.error) toast.error(api.getPlatformManagement.error.message);
	}, [api.getPlatformManagement.error]);

	useEffect(() => {
		const data = api.getPlatformManagement.response?.data;
		if (data) {
			const images = [];

			if (data?.logo) {
				images.push(data?.logo);
				setLogo([data?.logo]);
			}

			if (data?.banner) {
				images.push(data?.banner);
				setBanner([data?.banner]);
			}

			if (data?.favicon) {
				images.push(data?.favicon);
				setFavicon([data?.favicon]);
			}

			setPlatformManagementData({
				id: data.id || null,
				pageTitle: data.pageTitle || '',
				metaDescription: data.metaDescription || '',
				blue: data.blue || '',
				blueLight: data.blueLight || '',
				blueLighter: data.blueLighter || '',
				blueDark: data.blueDark || '',
				loginPageTitle: data.loginPageTitle || '',
				loginPageDescription: data.loginPageDescription || '',
				contents: images,
			});
		}
	}, [api.getPlatformManagement.response?.data]);

	const handleChange = ({ target: { name, value } }) => {
		setPlatformManagementData((prev) => ({ ...prev, [name]: value }));
	};

	const removeLogo = (index) => {
		// Silinecek logo değerini alalım
		const logoToRemove = logo[index];

		// Logo state'inden belirli indeksi sil
		setLogo((prev) => prev.filter((_, i) => i !== index));

		// Contents'den logoToRemove ile eşleşen elemanı sil
		setPlatformManagementData((prev) => ({
			...prev,
			contents: prev.contents.filter((item) => item !== logoToRemove)
		}));
	};

	const removeBanner = (index) => {
		// Silinecek banner değerini alalım
		const bannerToRemove = banner[index];

		// Banner state'inden belirli indeksi sil
		setBanner((prev) => prev.filter((_, i) => i !== index));

		// Contents'den bannerToRemove ile eşleşen elemanı sil
		setPlatformManagementData((prev) => ({
			...prev,
			contents: prev.contents.filter((item) => item !== bannerToRemove)
		}));
	};

	const removeFavicon = (index) => {
		// Silinecek favicon değerini alalım
		const faviconToRemove = favicon[index];

		// Favicon state'inden belirli indeksi sil
		setFavicon((prev) => prev.filter((_, i) => i !== index));

		// Contents'den faviconToRemove ile eşleşen elemanı sil
		setPlatformManagementData((prev) => ({
			...prev,
			contents: prev.contents.filter((item) => item !== faviconToRemove)
		}));
	};

	const handleSubmit = () => {
		const formData = new FormData();

		platFormManagementData.tenantId = context?.signed?.data?.tenantId;

		console.log('platFormManagementData: ', platFormManagementData);

		// JSON verisini ekle
		formData.append(
			'saveLoginPageDTO',
			JSON.stringify(platFormManagementData)
		);

		// Logo dosyasını ekle (eğer yeni bir dosya yüklendiyse)
		if (logo.length && logo[0] && logo[0] instanceof File) {
			formData.append('logo', logo[0]);
		}

		// Banner dosyasını ekle (eğer yeni bir dosya yüklendiyse)
		if (banner.length && banner[0] && banner[0] instanceof File) {
			formData.append('banner', banner[0]);
		}

		// Favicon dosyasını ekle (eğer yeni bir dosya yüklendiyse)
		if (favicon.length && favicon[0] && favicon[0] instanceof File) {
			formData.append('favicon', favicon[0]);
		}

		console.log('formData: ', formData);

		const tenantName =
			localStorage.getItem('tenant') || context?.signed?.data?.tenantName;

		api.savePlatformManagement.fetch({
			method: 'POST',
			url: '/auth/admin/save/loginSetting',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'X-Tenant': tenantName,
			},
		});
	};

	useEffect(() => {
		if (api.savePlatformManagement.response) {
			toast.success('Kaydedildi.');
		}
	}, [api.savePlatformManagement.response]);

	useEffect(() => {
		resetSaveGame(platFormManagementData);
	}, [platFormManagementData]);

	return (
		<div
			className="container mx-auto p-4 m-4"
			style={{ overflow: 'scroll' }}
		>
			<div className="flex  items-center gap-4">
				<button
					type="button"
					className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-full flex items-center gap-2"
					onClick={() => navigate(-1)}
				>
					<FaArrowLeft />
					Geri
				</button>
				<h1 className="text-sm lg:text-xl font-bold">{pageTitle}</h1>
			</div>

			<form onSubmit={handleSubmitSaveGame(handleSubmit)} noValidate>
				<div className="flex justify-end mt-4 lg:px-20 pt-4 space-x-4">
					<Button
						type="submit"
						variant="primary"
						className="py-2 px-4"
					>
						Kaydet
					</Button>
				</div>

				<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 px-2 lg:px-20 pt-4 my-4">
					{formInputsData.map(({ label, name, type }) => (
						<FormInput
							{...registerSaveGame(name, {
								required: 'Bu alan zorunludur.',
							})}
							errors={errorsSaveGame}
							key={name}
							grid
							type={type}
							label={label}
							name={name}
							placeholder="Lütfen giriniz."
							value={platFormManagementData[name] || ''}
							onChange={(e) => {
								handleChange(e);
								setValueSaveGame(name, e.target.value);
							}}
						/>
					))}

					<Select
						grid
						label="Durum"
						name="active"
						options={[
							{ name: 'Aktif', value: true },
							{ name: 'Pasif', value: false },
						]}
						value={platFormManagementData.active}
						onChange={handleChange}
					/>

					<div className="mb-4">
						<div className="group">
							<div className="grid grid-cols-3 items-center">
								<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
									Logo
								</label>
								<div className="col-span-2">
									<div className="mb-4 grid grid-cols-4 gap-4">
										{logo.length > 0 ? (
											logo.map(
												(image, index) => (
													<div
														className="relative inline-block"
														key={index}
													>
														<img
															src={
																typeof image ===
																'object'
																	? URL.createObjectURL(
																			image
																		)
																	: image
															}
															alt=""
															className="aspect-square h-full w-full rounded-lg object-cover shadow bg-slate-50"
														/>
														<button
															type="button"
															className="absolute right-0 top-0 -translate-y-2 translate-x-2"
															onClick={() =>
																removeLogo(
																	index
																)
															}
														>
															<BsFillXCircleFill className="h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
														</button>
													</div>
												)
											)
										) : (
											<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
												Görsel Bulunamadı
											</div>
										)}
									</div>
									<div className="relative">
										<input
											type="file"
											accept="image/*"
											onChange={(event) => {
												if (
													event?.target
														?.files?.[0]
												) {
													setLogo([
														...logo,
														event.target
															.files[0],
													]);
												}
											}}
											className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
										/>
										<button
											type="button"
											className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-sm text-white bg-blue-500 hover:bg-blue-600"
										>
											Yeni Logo Ekle
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="mb-4">
						<div className="group">
							<div className="grid grid-cols-3 items-center">
								<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
									Banner
								</label>
								<div className="col-span-2">
									<div className="mb-4 grid grid-cols-4 gap-4">
										{banner.length > 0 ? (
											banner.map(
												(image, index) => (
													<div
														className="relative inline-block"
														key={index}
													>
														<img
															src={
																typeof image ===
																'object'
																	? URL.createObjectURL(
																			image
																		)
																	: image
															}
															alt=""
															className="aspect-square h-full w-full rounded-lg object-cover shadow bg-slate-50"
														/>
														<button
															type="button"
															className="absolute right-0 top-0 -translate-y-2 translate-x-2"
															onClick={() =>
																removeBanner(
																	index
																)
															}
														>
															<BsFillXCircleFill className="h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
														</button>
													</div>
												)
											)
										) : (
											<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
												Görsel Bulunamadı
											</div>
										)}
									</div>
									<div className="relative">
										<input
											type="file"
											accept="image/*"
											onChange={(event) => {
												if (
													event?.target
														?.files?.[0]
												) {
													setBanner([
														...banner,
														event.target
															.files[0],
													]);
												}
											}}
											className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
										/>
										<button
											type="button"
											className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-sm text-white bg-blue-500 hover:bg-blue-600"
										>
											Yeni Banner Ekle
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="mb-4">
						<div className="group">
							<div className="grid grid-cols-3 items-center">
								<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
									Favicon
								</label>
								<div className="col-span-2">
									<div className="mb-4 grid grid-cols-4 gap-4">
										{favicon.length > 0 ? (
											favicon.map(
												(image, index) => (
													<div
														className="relative inline-block"
														key={index}
													>
														<img
															src={
																typeof image ===
																'object'
																	? URL.createObjectURL(
																			image
																		)
																	: image
															}
															alt=""
															className="aspect-square h-full w-full rounded-lg object-cover shadow bg-slate-50"
														/>
														<button
															type="button"
															className="absolute right-0 top-0 -translate-y-2 translate-x-2"
															onClick={() =>
																removeFavicon(
																	index
																)
															}
														>
															<BsFillXCircleFill className="h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
														</button>
													</div>
												)
											)
										) : (
											<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
												Görsel Bulunamadı
											</div>
										)}
									</div>
									<div className="relative">
										<input
											type="file"
											accept="image/*"
											onChange={(event) => {
												if (
													event?.target
														?.files?.[0]
												) {
													setFavicon([
														...favicon,
														event.target
															.files[0],
													]);
												}
											}}
											className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
										/>
										<button
											type="button"
											className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-sm text-white bg-blue-500 hover:bg-blue-600"
										>
											Yeni Favicon Ekle
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PlatformManagement;
