/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoInformationOutline } from 'react-icons/io5';
import { Page, Tooltip, FormCheckbox, Context } from '../../components';

const ProgramTenantComponents = () => {
	const [activeComponentList, setActiveComponentList] = React.useState([]);
	const [infoText] = React.useState(
		"Tenant'a tanımlı bileşenleri listelemektedir!"
	);
	const [context] = useContext(Context);
	const superAdmin = context?.signed?.data?.employeeFirm?.role;
	const navigate = useNavigate();

	useEffect(() => {
		if (superAdmin === 'SUPERADMIN') {
			navigate('/');
		}
	}, [superAdmin]);

	React.useEffect(() => {
		if (localStorage.getItem('userPermissions')) {
			setActiveComponentList(
				JSON.parse(localStorage.getItem('userPermissions'))
			);
		}
	}, []);

	const groupByCategory = (components) => {
		return components.reduce((acc, component) => {
			const category = component.componentCategory;
			if (!acc[category]) {
				acc[category] = [];
			}
			acc[category].push(component);
			return acc;
		}, {});
	};

	const groupedComponents = groupByCategory(activeComponentList);

	return (
		<Page>
			<Page.Header header="Ayarlar" title="Bileşenler" />
			<Page.Body loading={false} show>
				<div className="bg-white p-2 sm:p-4 rounded-lg col-span-full">
					<h2 className="text-base sm:text-lg font-bold mb-2 sm:mb-4">
						<span>Bileşenler</span>
						<Tooltip text={infoText} position="top">
							<button
								type="button"
								className="inline-flex items-center gap-2 text-xs text-slate-500"
							>
								<span className="mx-1 min-w-5 min-h-5 flex items-center justify-center rounded-full bg-slate-900">
									<IoInformationOutline
										className="text-white"
										size={16}
									/>
								</span>
							</button>
						</Tooltip>
					</h2>
					{Object.entries(groupedComponents).map(
						([category, components]) => (
							<div key={category} className="mb-6">
								<h3 className="text-base font-semibold mb-3 text-slate-700">
									{category}
								</h3>
								<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4 select-none">
									{components.map((component) => (
										<FormCheckbox
											grid
											key={component.id}
											id={component.id.toString()}
											label={component.componentName}
											checked={component.active}
											className="select-none"
											size="md"
										/>
									))}
								</div>
							</div>
						)
					)}
				</div>
			</Page.Body>
			<Page.Footer />
		</Page>
	);
};

export default ProgramTenantComponents;
