import React from 'react';
import { BiFilterAlt } from 'react-icons/bi';

import Button from '../../../Button';

const Filter = ({ onClick, show, className }) =>
	show && (
		<Button className={className} variant="tertiary-outline" size="sm" onClick={onClick}>
			<BiFilterAlt className="h-4 w-4" />
			Filtrele
		</Button>
	);

export default Filter;
