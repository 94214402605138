import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsFillXCircleFill, BsCheckCircleFill } from 'react-icons/bs';
import { IoChevronForward } from 'react-icons/io5';

import { useAxios } from '../../hooks';
import {
	Button,
	Context,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Switch,
	Table,
	TextArea,
	Time,
	Pagination,
} from '../../components';
import DatePicker from '../../components/DatePicker';
import usePermission from '../../hooks/usePermission';

const Brands = () => {
	const [context] = React.useContext(Context);
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentBrandImage, setCurrentBrandImage] = React.useState([]);
	const [params, setParams] = useSearchParams();

	const [showBrandCategories, setShowBrandCategories] = React.useState(false);
	const [showEditBrandCategory, setShowEditBrandCategory] =
		React.useState(false);
	const [showBrandStyles, setShowBrandStyles] = React.useState(false);

	// Pagination state'lerini ekleyelim
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	const { hasPermission, isLoading } = usePermission();

	/* form */

	const {
		register: registerSaveBrand,
		setValue: setValueSaveBrand,
		watch: watchSaveBrand,
		handleSubmit: handleSubmitSaveBrand,
		reset: resetSaveBrand,
		formState: { errors: errorsSaveBrand },
	} = useForm();

	const {
		register: registerSaveBrandCategory,
		setValue: setValueSaveBrandCategory,
		watch: watchSaveBrandCategory,
		handleSubmit: handleSubmitSaveBrandCategory,
		reset: resetSaveBrandCategory,
		formState: { errors: errorsSaveBrandCategory },
	} = useForm();

	/* api */

	const api = {
		getBrands: useAxios(),
		saveBrand: useAxios(),
		removeBrand: useAxios(),

		getBrandCategories: useAxios(),
		saveBrandCategory: useAxios(),
		removeBrandCategory: useAxios(),

		getBrandStyles: useAxios(),
		saveBrandStyle: useAxios(),
		removeBrandStyle: useAxios(),
	};

	const [brands, setBrands] = React.useState([]); // Listeyi state olarak tut

	/* list brands */

	const getBrands = () => {
		api.getBrands.fetch({
			method: 'GET',
			url: `/brand/admin/brandList?page=${page}&size=${size}`,
		});
	};

	React.useEffect(() => {
		if (api.getBrands.response?.data) {
			setBrands(api.getBrands.response.data);
			// Pagination bilgilerini set edelim
			setTotalPages(api.getBrands.response.data.totalPages || 1);
		}
	}, [api.getBrands.response?.data]);

	// Sayfa değiştiğinde API'yi tekrar çağıralım
	React.useEffect(() => {
		getBrands();
	}, [page, size]);

	React.useEffect(() => {
		if (api.getBrands.response) {
			console.log('brands', api.getBrands.response);
		}
	}, [api.getBrands.response]);

	React.useEffect(() => {
		if (api.getBrands.error) {
			toast.error(api.getBrands.error.message);
		}
	}, [api.getBrands.error]);

	/* save brand */

	const saveBrand = (data) => {
		const formData = new FormData();

		formData.append(
			'saveBrandDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				imageOrder: parseInt(data.imageOrder, 10),

				...(currentBrandImage.length &&
				currentBrandImage[0] &&
				typeof currentBrandImage[0] === 'string'
					? { contents: [currentBrandImage[0]] }
					: null),
			})
		);

		if (
			currentBrandImage.length &&
			currentBrandImage[0] &&
			currentBrandImage[0] instanceof File
		) {
			formData.append('icon', currentBrandImage[0]);
		}

		api.saveBrand.fetch({
			method: 'POST',
			url: '/brand/admin/save',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'X-Tenant': localStorage.getItem('tenant')
					? localStorage.getItem('tenant')
					: context?.signed?.data?.tenantName,
			},
		});
	};

	React.useEffect(() => {
		if (api.saveBrand.response) {
			resetSaveBrand();
			setCurrentBrandImage([]);
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getBrands();
		}
	}, [api.saveBrand.response]);

	React.useEffect(() => {
		if (api.saveBrand.error) {
			toast.error(api.saveBrand.error.message);
		}
	}, [api.saveBrand.error]);

	const removeBrandImage = (position) => {
		const images = [...currentBrandImage];
		images.splice(position, 1);
		setCurrentBrandImage(images);
	};

	/* edit brand */

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getBrands.response) {
			const { id } = showEdit;
			const item =
				api.getBrands.response.data.content[
					api.getBrands.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ name: item.name },
				{ description: item.description },
				{ imageOrder: item.imageOrder },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
				{ website: item.website },
			];

			values.forEach((value) => {
				setValueSaveBrand(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (item.icon) {
				const images = [];
				images.push(item.icon);
				setCurrentBrandImage(images);
			}
		}
	}, [showEdit.id && api.getBrands.response]);

	/* remove brand */

	const removeBrand = (id) => {
		api.removeBrand.fetch({
			method: 'POST',
			url: `/brand/admin/delete/brand/${id}`,
		});
	};

	React.useEffect(() => {
		if (api.removeBrand.response) {
			setCurrentBrandImage([]);
			setShowEdit(false);
			toast.success('Silindi.');
			getBrands();
		}
	}, [api.removeBrand.response]);

	React.useEffect(() => {
		if (api.removeBrand.error) {
			toast.error(api.removeBrand.error.message);
		}
	}, [api.removeBrand.error]);

	/* list brand categories */

	const getBrandCategories = (data) => {
		api.getBrandCategories.fetch({
			method: 'GET',
			url: `/brand/admin/brandCategoryList/${data.id}`,
		});
	};

	React.useEffect(() => {
		if (api.getBrandCategories.response) {
			console.log('brand categories', api.getBrandCategories.response);
		}
	}, [api.getBrandCategories.response]);

	React.useEffect(() => {
		if (api.getBrandCategories.error) {
			toast.error(api.getBrandCategories.error.message);
		}
	}, [api.getBrandCategories.error]);

	/* save brand category */

	const saveBrandCategory = (data) => {
		const body = {
			...data,
			...(data.id ? { id: parseInt(data.id, 10) } : null),
			...(data.subId
				? {
						id: parseInt(data.subId, 10),
						brandCategoryId: parseInt(data.id, 10),
					}
				: null),
			...(data.brandCategoryId
				? { brandCategoryId: parseInt(data.brandCategoryId, 10) }
				: null),
			...(showBrandCategories.id && !data.subId
				? { brandId: parseInt(showBrandCategories.id, 10) }
				: null),
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
		};

		const url =
			data.type === 'subCategory' || data.subId
				? '/brand/admin/brandSubCategory/save'
				: '/brand/admin/brandCategory/save';

		if (data.type === 'subCategory') {
			delete body.brandId;
		}

		if (data.type) {
			delete body.type;
		}

		if (data.subId) {
			delete body.subId;
		}

		api.saveBrandCategory.fetch({
			method: 'POST',
			url,
			data: body,
		});
	};

	React.useEffect(() => {
		if (api.saveBrandCategory.response) {
			resetSaveBrandCategory();
			setShowBrandCategories(false);
			setShowEditBrandCategory(false);
			toast.success('Kaydedildi.');
			getBrands();
		}
	}, [api.saveBrandCategory.response]);

	React.useEffect(() => {
		if (api.saveBrandCategory.error) {
			toast.error(api.saveBrandCategory.error.message);
		}
	}, [api.saveBrandCategory.error]);

	/* edit brand category */

	React.useEffect(() => {
		if (showEditBrandCategory === true) {
			setValueSaveBrandCategory('type', 'category', {
				shouldValidate: true,
			});
		}

		if (
			showEditBrandCategory.id &&
			!showEditBrandCategory.subId &&
			api.getBrandCategories.response
		) {
			const { id } = showEditBrandCategory;
			const item =
				api.getBrandCategories.response.data.content[
					api.getBrandCategories.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ name: item.name },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
			];

			values.forEach((value) => {
				setValueSaveBrandCategory(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}

		if (showEditBrandCategory.subId && api.getBrandCategories.response) {
			const { id, subId } = showEditBrandCategory;
			const item =
				api.getBrandCategories.response.data.content[
					api.getBrandCategories.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const subItem =
				item.brandSubCategoryDTOList[
					item.brandSubCategoryDTOList.findIndex(
						(object) => object.id === subId
					)
				];
			const values = [
				{ name: subItem.name },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
			];

			values.forEach((value) => {
				setValueSaveBrandCategory(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEditBrandCategory, api.getBrandCategories.response]);

	/* remove brand category */

	const removeBrandCategory = (id) => {
		api.removeBrandCategory.fetch({
			method: 'POST',
			url: showEditBrandCategory.subId
				? `/brand/admin/delete/brandSubCategory/${id}`
				: `/brand/admin/delete/brandCategory/${id}`,
		});
	};

	React.useEffect(() => {
		if (api.removeBrandCategory.response) {
			setShowBrandCategories(false);
			setShowEditBrandCategory(false);
			toast.success('Silindi.');
			// getBrandCategories(); //TODO: id
		}
	}, [api.removeBrandCategory.response]);

	React.useEffect(() => {
		if (api.removeBrandCategory.error) {
			toast.error(api.removeBrandCategory.error.message);
		}
	}, [api.removeBrandCategory.error]);

	/* list brand styles */

	const getBrandStyles = (data) => {
		api.getBrandStyles.fetch({
			method: 'GET',
			url: `/brand/admin/subCategory/brandStyleList/${data.subId}`,
		});
	};

	React.useEffect(() => {
		if (api.getBrandStyles.response) {
			console.log('brand styles', api.getBrandStyles.response);
		}
	}, [api.getBrandStyles.response]);

	React.useEffect(() => {
		if (api.getBrandStyles.error) {
			toast.error(api.getBrandStyles.error.message);
		}
	}, [api.getBrandStyles.error]);

	/* save brand style */

	/*
	"name": "deneme",
	"title":"title",
	"description":"aciklama",
	"imageOrder":1,
	"price":20,
	"topIcon":true,
	"topIconText":"Hızlı gönderi", //true ise gönderilmeli
	"brandSubCategoryId":1,
   	"brandCategoryId":793,
	"dateBegin": "2023-11-14T15:00:00Z",
	"dateEnd": "2024-11-15T05:00:00Z",
	"active": true,
	"productCode":"COLA12",
	"stock":100,
	"rate":3,
	*/

	/* edit brand style */

	/* remove brand style */

	/* return */

	if (!isLoading && !hasPermission('MARKALAR')) {
		return <Navigate to="/" />;
	}

	return (
		<Page>
			<Page.Header header="Markalar" title="Tüm Markalar">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getBrands.loading}
				show={!api.getBrands.loading && api.getBrands.response}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Marka', 'Sıra', 'Tarih', 'Durum', '']}
						/>
						<Table.Body>
							{api.getBrands.response?.data?.content?.length &&
								api.getBrands.response.data.content.map(
									(item, index) => (
										<Table.Row key={`${index}.${item.id}`}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<Item
													image={
														item.icon
															? item.icon
															: require('../../assets/logo-connection.png')
													}
													title={item.name}
													description={item.description.replace(
														/(<([^>]+)>)/gi,
														''
													)}
													className="w-48 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
												/>
											</Table.Column>
											<Table.Column>
												<div className="text-base font-medium">
													{item.imageOrder}
												</div>
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												<Switch
													id={item.id}
													active={item.active}
													list={brands.content}
													onChange={setBrands}
													saveList={saveBrand}
													customPopUpText={false}
												/>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<div className="flex flex-col items-end justify-center gap-2">
													<Button
														size="xs"
														variant="primary-outline"
														className="inline-flex"
														onClick={() => {
															getBrandCategories({
																id: item.id,
															});
															setShowBrandCategories(
																{
																	id: item.id,
																}
															);
														}}
													>
														Kategoriler
													</Button>
													<Button
														size="xs"
														variant="secondary-outline"
														className="inline-flex"
														onClick={() => {
															setShowEdit({
																id: item.id,
															});
														}}
													>
														Düzenle
													</Button>
												</div>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>

					{/* Pagination komponenti ekleyelim */}
					<div className="mt-4">
						<Pagination
							currentPage={page}
							totalPages={totalPages}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={size}
							onPageSizeChange={(newSize) => {
								setSize(newSize);
								setPage(0); // Sayfa boyutu değiştiğinde ilk sayfaya dön
							}}
						/>
					</div>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			{/* brand */}
			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getBrands.loading ||
					api.saveBrand.loading ||
					api.removeBrand.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setCurrentBrandImage([]);
					resetSaveBrand();
				}}
			>
				<form onSubmit={handleSubmitSaveBrand(saveBrand)} noValidate>
					<div className="flex flex-col gap-4 p-6">
						<div className="grid grid-cols-3 items-center">
							<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
								Görsel
							</label>
							<div className="col-span-2">
								<div className="mb-4 grid grid-cols-4 gap-4">
									{currentBrandImage &&
									currentBrandImage.length ? (
										currentBrandImage.map(
											(image, index) => (
												<div
													className="relative inline-block"
													key={index}
												>
													<img
														src={
															typeof image ===
															'object'
																? window.URL.createObjectURL(
																		image
																	)
																: image
														}
														key={index}
														alt=""
														className="aspect-square h-full w-full
															rounded-lg object-cover shadow bg-slate-50"
													/>
													<button
														type="button"
														className="absolute right-0 top-0 -translate-y-2 translate-x-2"
														onClick={() =>
															removeBrandImage(
																index
															)
														}
													>
														<BsFillXCircleFill
															className="translate h-5 w-5
															rounded-full border-2 bg-white border-white text-red-500"
														/>
													</button>
												</div>
											)
										)
									) : (
										<div
											className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
										>
											Görsel Bulunamadı
										</div>
									)}
								</div>
								<div className="relative">
									<input
										type="file"
										accept="image/*"
										onChange={(event) => {
											if (event?.target?.files?.[0]) {
												setCurrentBrandImage([
													...currentBrandImage,
													event.target.files[0],
												]);
											}
										}}
										className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
									/>
									{!currentBrandImage.length && (
										<div
											className={`flex h-9 w-full items-center justify-center whitespace-nowrap
											rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
											transition
											duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
										>
											Yeni Görsel Ekle
										</div>
									)}
								</div>
							</div>
						</div>
						<Input
							type="text"
							name="name"
							label="İsim"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveBrand}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveBrand}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<TextArea
							name="description"
							label="Açıklama"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveBrand}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveBrand}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="url"
							name="website"
							label="Web Sitesi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue="http://"
							register={registerSaveBrand}
							errors={errorsSaveBrand}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="number"
							name="imageOrder"
							label="Sıra"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveBrand}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							defaultValue={0}
							errors={errorsSaveBrand}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveBrand('dateBegin')}
							setValue={setValueSaveBrand}
							register={registerSaveBrand}
							errors={errorsSaveBrand}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveBrand('dateEnd')}
							setValue={setValueSaveBrand}
							register={registerSaveBrand}
							errors={errorsSaveBrand}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveBrand}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveBrand}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveBrand}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeBrand(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveBrand).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>

			{/* brand categories */}
			<Modal
				show={!!showBrandCategories.id}
				loading={api.getBrandCategories.loading}
				size="xl"
				title="Kategoriler"
				onClose={() => {
					setShowBrandCategories(false);
				}}
			>
				<div className="flex flex-col gap-4 p-6">
					<div className="flex justify-end">
						<Page.Header.Add
							show
							onClick={() => setShowEditBrandCategory(true)}
						/>
					</div>

					{api.getBrandCategories.response?.data?.content ? (
						<Table className="!table-fixed">
							<Table.Head data={['Id', 'Kategori', '']} />
							<Table.Body>
								{api.getBrandCategories.response.data.content.map(
									(item, index) => (
										<>
											<Table.Row key={`${index}.item.id`}>
												<Table.Column className="w-16">
													<span className="whitespace-nowrap text-xs font-normal text-slate-400">
														{item.id}
													</span>
												</Table.Column>
												<Table.Column>
													<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
														{item.name}
													</h6>
												</Table.Column>

												<Table.Column className="text-right [&_button]:w-20">
													<div className="flex flex-col items-end justify-center gap-2">
														<Button
															size="xs"
															variant="secondary-outline"
															className="inline-flex"
															onClick={() => {
																setShowEditBrandCategory(
																	{
																		id: item.id,
																	}
																);
															}}
														>
															Düzenle
														</Button>
													</div>
												</Table.Column>
											</Table.Row>
											{item.brandSubCategoryDTOList &&
												item.brandSubCategoryDTOList.map(
													(
														subCategory,
														subCategoryIndex
													) => (
														<Table.Row
															key={`${index}.${item.id}.${subCategoryIndex}`}
															className={`${
																item
																	.brandSubCategoryDTOList
																	.length ===
																subCategoryIndex +
																	1
																	? 'border-b-[16px]'
																	: ''
															}`}
														>
															<Table.Column>
																<span className="whitespace-nowrap text-xs font-normal text-slate-400">
																	{
																		subCategory.id
																	}
																</span>
															</Table.Column>
															<Table.Column>
																<h6 className="flex items-center gap-2 truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
																	<span className="opacity-50">
																		{
																			item.name
																		}
																	</span>
																	<IoChevronForward className="h-3 w-3 opacity-50" />
																	{
																		subCategory.name
																	}
																</h6>
															</Table.Column>

															<Table.Column className="text-right [&_button]:w-20">
																<div className="flex flex-col items-end justify-center gap-2">
																	<Button
																		size="xs"
																		variant="primary-outline"
																		className="inline-flex"
																		onClick={() => {
																			getBrandStyles(
																				{
																					subId: subCategory.id,
																				}
																			);
																			setShowBrandStyles(
																				true
																			);
																			// setShowBrandCategories(
																			// 	{
																			// 		id: item.id,
																			// 	}
																			// );
																		}}
																	>
																		Ürünler
																	</Button>
																	<Button
																		size="xs"
																		variant="secondary-outline"
																		className="inline-flex"
																		onClick={() => {
																			setShowEditBrandCategory(
																				{
																					id: item.id,
																					subId: subCategory.id,
																				}
																			);
																		}}
																	>
																		Düzenle
																	</Button>
																</div>
															</Table.Column>
														</Table.Row>
													)
												)}
										</>
									)
								)}
							</Table.Body>
						</Table>
					) : (
						<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
							Kategori bulunamadı
						</div>
					)}
				</div>
			</Modal>

			{/* brand styles */}
			<Modal
				show={Boolean(showBrandStyles)}
				loading={api.getBrandStyles.loading}
				size="xxl"
				title="Ürünler"
				onClose={() => {
					setShowBrandStyles(false);
				}}
			>
				<div className="flex flex-col gap-4 p-6">
					{api.getBrandStyles.response?.data?.content ? (
						<Table className="!table-fixed">
							<Table.Head
								data={[
									'Id',
									'Ürün',
									'Fiyat',
									'Kod',
									'Stok',
									'Puan',
									'Durum',
								]}
							/>
							<Table.Body>
								{api.getBrandStyles.response.data.content.map(
									(item, index) => (
										<Table.Row key={`${index}.item.id`}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.name}
												</h6>
											</Table.Column>
											<Table.Column>
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.price}
												</h6>
											</Table.Column>
											<Table.Column>
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.productCode}
												</h6>
											</Table.Column>
											<Table.Column>
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.stock}
												</h6>
											</Table.Column>
											<Table.Column>
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.rate}
												</h6>
											</Table.Column>
											<Table.Column>
												<Switch
													id={item.id}
													active={item.active}
													list={brands.content}
													onChange={setBrands}
													saveList={saveBrand}
													customPopUpText={false}
												/>
											</Table.Column>
										</Table.Row>
									)
								)}
							</Table.Body>
						</Table>
					) : (
						<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
							Ürün bulunamadı
						</div>
					)}
				</div>
			</Modal>

			{/* brand category */}
			<Modal
				show={Boolean(showEditBrandCategory)}
				loading={
					api.getBrandCategories.loading ||
					api.saveBrandCategory.loading ||
					api.removeBrandCategory.loading
				}
				size="lg"
				title={!showEditBrandCategory.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEditBrandCategory(false);
					resetSaveBrandCategory();
				}}
			>
				<form
					onSubmit={handleSubmitSaveBrandCategory(saveBrandCategory)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						{showEditBrandCategory === true && (
							<Select
								name="type"
								label="Tür"
								grid
								options={[
									{ name: 'Kategori', value: 'category' },
									{
										name: 'Alt kategori',
										value: 'subCategory',
									},
								]}
								defaultValue="category"
								register={registerSaveBrandCategory}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveBrandCategory}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						)}

						{showEditBrandCategory === true &&
							watchSaveBrandCategory('type') ===
								'subCategory' && (
								<Select
									name="brandCategoryId"
									label="Kategori"
									grid
									options={[
										...[
											{
												name: 'Lütfen seçiniz.',
												value: '',
											},
										],
										...(api.getBrandCategories.response
											?.data?.content?.length
											? api.getBrandCategories.response.data.content.map(
													(item) => ({
														name: item.name,
														value: item.id,
													})
												)
											: []),
									]}
									defaultValue=""
									register={registerSaveBrandCategory}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={registerSaveBrandCategory}
									size="lg"
									className={{ select: '!text-sm' }}
								/>
							)}

						<Input
							type="text"
							name="name"
							label="Başlık"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveBrandCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveBrandCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveBrandCategory('dateBegin')}
							setValue={setValueSaveBrandCategory}
							register={registerSaveBrandCategory}
							errors={errorsSaveBrandCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<DatePicker
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							value={watchSaveBrandCategory('dateEnd')}
							setValue={setValueSaveBrandCategory}
							register={registerSaveBrandCategory}
							errors={errorsSaveBrandCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
					</div>

					{showEditBrandCategory.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveBrandCategory}
							defaultValue={showEditBrandCategory.id}
						/>
					)}
					{showEditBrandCategory.subId && (
						<Input
							type="hidden"
							name="subId"
							register={registerSaveBrandCategory}
							defaultValue={showEditBrandCategory.subId}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{(showEditBrandCategory.id ||
							showEditBrandCategory.subId) && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => {
									removeBrandCategory(
										showEditBrandCategory.subId
											? showEditBrandCategory.subId
											: showEditBrandCategory.id
									);
								}}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(registerSaveBrandCategory).length >
								0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default Brands;
