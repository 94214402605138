/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Button,
	Context,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Switch,
	Table,
	Time,
	Pagination,
} from '../../components';
import { useAxios } from '../../hooks';
import PuzzleMatchProgramInputs from './ProgramInputs';
import DatePicker from '../../components/DatePicker';
import usePermission from '../../hooks/usePermission';

const GamePuzzleMatch = () => {
	const [context] = React.useContext(Context);
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [brandStyles, setBrandStyles] = React.useState([]);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveGame,
		unregister: unregisterSaveGame,
		watch: watchSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm();

	// program modal
	const [showProgram, setShowProgram] = React.useState(true);
	const [selectedWeekDays, setSelectedWeekDays] = useState('');
	const [selectedMonthDays, setSelectedMonthDays] = useState('');
	const [selectedWeekType, setSelectedWeekType] = useState('');
	const [selectedScoreDate, setSelectedScoreDate] = useState([]);
	const { hasPermission, isLoading } = usePermission();
	const programType = watchSaveGame('type', 'SINGLE');
	const frequency = watchSaveGame('definitionFrequencyType', 'GÜNLÜK');

	const handleMonthDayChange = (day) => {
		console.log(day);
		if (selectedMonthDays.includes(day)) {
			setSelectedMonthDays('');
		} else {
			setSelectedMonthDays(day);
		}
	};

	useEffect(() => {
		if (frequency !== 'WEEKLY') {
			setSelectedWeekDays('');
		}
	}, [frequency]);

	const handleWeekTypeChange = (type) => {
		console.log(type);
		if (type === 'SAME_QUESTIONS_EVERY_WEEK') {
			setSelectedWeekDays('');
		}
		setSelectedWeekType(type);
	};

	const handleWeekDayChange = (day) => {
		if (selectedWeekDays.includes(day)) {
			setSelectedWeekDays(selectedWeekDays.filter((d) => d !== day));
		} else {
			setSelectedWeekDays([...selectedWeekDays, day]);
		}
	};

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	const [games, setGames] = React.useState([]); // Listeyi state olarak tut

	// Pagination state'lerini ekleyelim
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getGames.response?.data) {
			setGames(api.getGames.response.data);
			// Pagination bilgilerini set edelim
			setTotalPages(api.getGames.response.data.totalPages || 1);
		}
	}, [api.getGames.response?.data]);

	/* getBrandStyles */

	const getBrandStyles = () => {
		api.getBrandStyles.fetch({
			method: 'GET',
			url: '/brand/get/brandStyleList',
		});
	};

	React.useEffect(() => {
		if (api.getBrandStyles.error) {
			toast.error(api.getBrandStyles.error.message);
		}
	}, [api.getBrandStyles.error]);

	React.useEffect(() => {
		getBrandStyles();
	}, []);

	/* getGames */

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: `/game/admin/getPuzzleMatchList?page=${page}&size=${size}`,
		});
	};

	React.useEffect(() => {
		if (api.getGames.response) {
			console.log(api.getGames.response);
		}
	}, [api.getGames.response]);

	React.useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	// Sayfa değiştiğinde API'yi tekrar çağıralım
	React.useEffect(() => {
		getGames();
	}, [page, size]);

	/* saveGame */

	const saveGame = async (data) => {
		const formData = new FormData();
		let gameData = {};

		formData.append(
			'savePuzzleMatchDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				brandStyleIdList: brandStyles,
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				...(currentImage.length &&
				currentImage[0] &&
				typeof currentImage[0] === 'string'
					? { contents: [currentImage[0]] }
					: null),
			})
		);

		// JSON stringi parse ederek daha okunaklı görmek için:
		const jsonData = JSON.parse(formData.get('savePuzzleMatchDTO'));
		console.log('FormData JSON içeriği:', jsonData);

		if (
			currentImage.length &&
			currentImage[0] &&
			currentImage[0] instanceof File
		) {
			formData.append('icon', currentImage[0]);
		}
		if (programType === 'MULTIPLE') {
			const {
				active,
				backgroundPattern,
				buttonText,
				title,
				description,
				type,
				remainTime,
				brandId,
				inGameDescription,
				trueAnswerText,
				wrongAnswerText,
				timeoutAnswerText,
				questionTitle,
				questionDescription,
				...filteredData
			} = data;
			gameData = {
				...filteredData,
				dateBegin: data?.dateBegin
					? moment.utc(data?.dateBegin).format()
					: '',
				dateEnd: data?.dateEnd
					? moment.utc(data?.dateEnd).format()
					: '',
				...(selectedWeekDays ? { selectedDays: selectedWeekDays } : {}),
				...(selectedWeekType
					? { weeklyFrequencyType: selectedWeekType }
					: {}),
				...(selectedMonthDays
					? { monthlyFrequencyType: selectedMonthDays }
					: {}),
			};
			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/create/program/puzzleMatch',
				data: gameData,
				headers: {
					'Content-Type': 'application/json',
					'X-Tenant': localStorage.getItem('tenant')
						? localStorage.getItem('tenant')
						: context?.signed?.data?.tenantName,
				},
			});
		} else {
			api.saveGame.fetch({
				method: 'POST',
				url: '/game/admin/puzzleMatch/save',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
					'X-Tenant': localStorage.getItem('tenant')
						? localStorage.getItem('tenant')
						: context?.signed?.data?.tenantName,
				},
			});
		}
	};

	React.useEffect(() => {
		if (api.saveGame.response) {
			resetSaveGame();
			setCurrentImage([]);
			setBrandStyles([]);
			setShowEdit(false);
			setShowProgram(true);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	React.useEffect(() => {
		if (api.saveGame.error) {
			toast.error(api.saveGame.error.message);
		}
	}, [api.saveGame.error]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (
			showEdit.id &&
			api.getGames.response &&
			api.getBrandStyles.response
		) {
			const { id } = showEdit;
			const item =
				api.getGames.response.data.content[
					api.getGames.response.data.content.findIndex(
						(object) => object.id === id
					)
				];

			const values = [
				{ title: item.title },
				{ description: item.description },
				{ buttonText: item.buttonText },
				{ inGameDescription: item.inGameDescription },
				{ score: item.score },
				{ trueAnswerText: item.trueAnswerText },
				{ backgroundPattern: item.backgroundPattern },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
			];

			if (item.brandStyleDTOList) {
				const brandStyleDTOList = [];
				item.brandStyleDTOList.forEach((value) => {
					brandStyleDTOList.push(value.id);
				});
				setBrandStyles(brandStyleDTOList);
			}

			values.forEach((value) => {
				setValueSaveGame(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (item.icon) {
				const images = [];
				images.push(item.icon);
				setCurrentImage(images);
			}
		}
	}, [showEdit.id && api.getGames.response && api.getBrandStyles.response]);

	const handleAddBrandStyle = () => {
		if (brandStyles.length > 2) {
			toast.error('En Fazla 3 Ürün Ekleyebilirsiniz!');
		} else {
			setBrandStyles([...brandStyles, 0]);
		}
	};

	const handleRemoveBrandStyle = (index) => {
		const brandStylesTemp = [...brandStyles];
		brandStylesTemp.splice(index, 1);

		// Önce eski form değerini unregister et
		unregisterSaveGame(`brandStyleIdList[${index}]`);

		// Yeni brandStyles array'ini set et
		setBrandStyles(brandStylesTemp);

		// Kalan brandStyle'ların form değerlerini güncelle
		brandStylesTemp.forEach((brandStyle, newIndex) => {
			setValueSaveGame(`brandStyleIdList[${newIndex}]`, brandStyle);
		});
	};

	/* removeGame */

	const removeGame = (id) => {
		api.removeGame.fetch({
			method: 'POST',
			url: '/game/admin/puzzleMatch/delete',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeGame.response) {
			setShowEdit(false);
			setShowProgram(true);
			toast.success('Silindi.');
			getGames();
		}
	}, [api.removeGame.response]);

	React.useEffect(() => {
		if (api.removeGame.error) {
			toast.error(api.removeGame.error.message);
		}
	}, [api.removeGame.error]);

	if (!isLoading && !hasPermission('PUZZLE')) {
		return <Navigate to="/" />;
	}

	return (
		<Page>
			<Page.Header header="Oyunlar" title="Eşini Bul">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getGames.loading || api.getBrandStyles.loading}
				show={!api.getGames.loading && api.getGames.response}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Oyun', 'Tarih', 'Durum', '']}
						/>
						<Table.Body>
							{games?.content?.length &&
								games?.content.map((item, index) => (
									<Table.Row key={index}>
										<Table.Column>
											<span className="text-slate-400 whitespace-nowrap text-xs font-normal">
												{item.id}
											</span>
										</Table.Column>
										<Table.Column>
											<Item
												image={
													item.icon
														? item.icon
														: require('../../assets/logo-connection.png')
												}
												title={item.title}
												description={item.description}
												className="w-96 [&_img]:bg-white [&_img]:object-contain [&_img]:p-2"
											/>
										</Table.Column>
										<Table.Column>
											<Time
												dateBegin={moment(
													item.dateBegin
												).format('DD.MM.YYYY')}
												timeBegin={moment(
													item.dateBegin
												).format('HH:mm:ss')}
												dateEnd={moment(
													item.dateEnd
												).format('DD.MM.YYYY')}
												timeEnd={moment(
													item.dateEnd
												).format('HH:mm:ss')}
											/>
										</Table.Column>
										<Table.Column>
											<Switch
												id={item.id}
												active={item.active}
												list={games.content}
												onChange={setGames}
												saveList={saveGame}
												customPopUpText={false}
											/>
										</Table.Column>
										<Table.Column className="text-right [&_button]:w-20">
											<Button
												size="xs"
												variant="secondary-outline"
												className="inline-flex"
												onClick={() => {
													setShowEdit({
														id: item.id,
													});
													setShowProgram(false);
												}}
											>
												Düzenle
											</Button>
										</Table.Column>
									</Table.Row>
								))}
						</Table.Body>
					</Table>

					{/* Pagination komponenti ekleyelim */}
					<div className="mt-4">
						<Pagination
							currentPage={page}
							totalPages={totalPages}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={size}
							onPageSizeChange={(newSize) => {
								setSize(newSize);
								setPage(0); // Sayfa boyutu değiştiğinde ilk sayfaya dön
							}}
						/>
					</div>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getGames.loading ||
					api.saveGame.loading ||
					api.removeGame.loading ||
					api.getBrandStyles.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setShowProgram(true);
					setCurrentImage([]);
					setBrandStyles([]);
					resetSaveGame();
				}}
			>
				<form onSubmit={handleSubmitSaveGame(saveGame)} noValidate>
					<div className="px-6">
						{showProgram && (
							<Modal.Section title="Oyun Türü">
								<Select
									name="type"
									label="Oyun Türü"
									grid
									options={[
										{
											name: 'Tekli',
											value: 'SINGLE',
										},
										{
											name: 'Çoklu',
											value: 'MULTIPLE',
										},
									]}
									register={registerSaveGame}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									value={programType}
									errors={errorsSaveGame}
									size="lg"
									className={{ select: '!text-sm' }}
								/>

								{programType === 'MULTIPLE' && (
									<PuzzleMatchProgramInputs
										errorsSaveGame={errorsSaveGame}
										registerSaveGame={registerSaveGame}
										selectedWeekDays={selectedWeekDays}
										handleWeekDayChange={
											handleWeekDayChange
										}
										selectedMonthDays={selectedMonthDays}
										handleMonthDayChange={
											handleMonthDayChange
										}
										selectedScoreDate={selectedScoreDate}
										setSelectedScoreDate={
											setSelectedScoreDate
										}
										selectedWeekType={selectedWeekType}
										handleWeekTypeChange={
											handleWeekTypeChange
										}
										frequency={frequency}
										programType={programType}
										watchSaveGame={watchSaveGame}
										setValueSaveGame={setValueSaveGame}
									/>
								)}
							</Modal.Section>
						)}
						{programType === 'SINGLE' && (
							<>
								<Modal.Section title="İçerik">
									<Input
										type="text"
										name="title"
										label="Başlık"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="description"
										label="Açıklama"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="number"
										name="score"
										label="Puan"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>

									<DatePicker
										label="Başlangıç Tarihi"
										name="dateBegin"
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
										grid
										value={watchSaveGame('dateBegin')}
										setValue={setValueSaveGame}
									/>
									<DatePicker
										label="Bitiş Tarihi"
										name="dateEnd"
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
										grid
										value={watchSaveGame('dateEnd')}
										setValue={setValueSaveGame}
									/>
									<Select
										name="active"
										label="Durum"
										grid
										options={[
											{
												name: 'Aktif',
												value: true,
											},
											{
												name: 'Pasif',
												value: false,
											},
										]}
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ select: '!text-sm' }}
									/>
								</Modal.Section>

								<Modal.Section title="Görünüm">
									<Input
										type="text"
										name="buttonText"
										label="Düğme Metni"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="inGameDescription"
										label="Oyun İçi Açıklama"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="trueAnswerText"
										label="Doğru Yanıt Metni"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
									<Input
										type="text"
										name="backgroundPattern"
										label="Arkaplan Rengi"
										grid
										placeholder="Lütfen giriniz."
										register={registerSaveGame}
										validation={{
											required: 'Bu alan zorunludur.',
										}}
										defaultValue="#"
										errors={errorsSaveGame}
										size="lg"
										className={{ input: '!text-sm' }}
									/>
								</Modal.Section>

								<Modal.Section title="Ürünler">
									{brandStyles.length ? (
										brandStyles.map(
											(brandStyle, brandStyleIndex) => (
												<div
													className="relative pr-8"
													key={brandStyleIndex}
												>
													<Select
														name={`brandStyleIdList[${brandStyleIndex}]`}
														label={`Ürün ${
															brandStyleIndex + 1
														}`}
														grid
														options={[
															{
																name: 'Lütfen Seçiniz',
																value: 0,
															},
															...(api
																.getBrandStyles
																.response?.data
																?.content
																?.length
																? api.getBrandStyles.response.data.content
																		// Diğer selectlerde seçili olmayan ürünleri filtrele
																		.filter(
																			(
																				item
																			) => {
																				// Şu anki selectbox'ta seçili olan ürünü göster
																				if (
																					item.id ===
																					brandStyles[
																						brandStyleIndex
																					]
																				) {
																					return true;
																				}
																				// Diğer selectbox'larda seçili olan ürünleri gösterme
																				return !brandStyles.includes(
																					item.id
																				);
																			}
																		)
																		.map(
																			(
																				item
																			) => ({
																				name: item.name,
																				value: item.id,
																			})
																		)
																: []),
														]}
														defaultValue={
															brandStyles[
																brandStyleIndex
															]
														}
														onChange={(e) => {
															const newValue =
																parseInt(
																	e.target
																		.value,
																	10
																);
															const newBrandStyles =
																[
																	...brandStyles,
																];
															newBrandStyles[
																brandStyleIndex
															] = newValue;
															setBrandStyles(
																newBrandStyles
															);
															setValueSaveGame(
																`brandStyleIdList[${brandStyleIndex}]`,
																newValue
															);
														}}
														validation={{
															required:
																'Bu alan zorunludur.',
														}}
														errors={errorsSaveGame}
														size="lg"
														className={{
															select: '!text-sm',
														}}
													/>
													<button
														className="absolute right-0 top-3.5"
														type="button"
														onClick={() =>
															handleRemoveBrandStyle(
																brandStyleIndex
															)
														}
													>
														<BsFillXCircleFill className="translate bg-white border-white text-red-500 h-5 w-5 rounded-full border-2" />
													</button>
												</div>
											)
										)
									) : (
										<div className="text-slate-400 bg-slate-50 rounded-md p-3 text-center text-xs">
											Ürün bulunamadı
										</div>
									)}

									<button
										className={`text-white bg-blue-500 flex h-9 w-full items-center
											justify-center whitespace-nowrap rounded-lg border-2 border-transparent px-3.5 text-center
											text-sm font-normal leading-none transition duration-300 hover:bg-blue-600
										`}
										type="button"
										onClick={() => {
											handleAddBrandStyle();
										}}
									>
										Yeni Ürün Ekle
									</button>
								</Modal.Section>

								<Modal.Section title="Görsel">
									{/* images start */}
									<div className="group">
										<div className="grid grid-cols-3 items-center">
											<label className="text-slate-700 col-span-1 flex gap-1 text-sm font-normal">
												Simge
											</label>
											<div className="col-span-2">
												<div className="mb-4 grid grid-cols-4 gap-4">
													{currentImage &&
													currentImage.length ? (
														currentImage.map(
															(image, index) => (
																<div
																	className="relative inline-block"
																	key={index}
																>
																	<img
																		src={
																			typeof image ===
																			'object'
																				? window.URL.createObjectURL(
																						image
																					)
																				: image
																		}
																		key={
																			index
																		}
																		alt=""
																		className="bg-slate-50 aspect-square h-full
															w-full rounded-lg object-cover shadow"
																	/>
																	<button
																		type="button"
																		className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																		onClick={() =>
																			removeImage(
																				index
																			)
																		}
																	>
																		<BsFillXCircleFill
																			className="translate bg-white border-white
															text-red-500 h-5 w-5 rounded-full border-2"
																		/>
																	</button>
																</div>
															)
														)
													) : (
														<div
															className="text-slate-400 bg-slate-50 col-span-4 rounded-md
											p-3 text-center text-xs"
														>
															Görsel Bulunamadı
														</div>
													)}
												</div>
												<div className="relative">
													<input
														type="file"
														accept="image/*"
														onChange={(event) => {
															if (
																event?.target
																	?.files?.[0]
															) {
																setCurrentImage(
																	[
																		...currentImage,
																		event
																			.target
																			.files[0],
																	]
																);
															}
														}}
														className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
													/>
													{!currentImage.length && (
														<div
															className={`text-white bg-blue-500 flex h-9 w-full items-center
                                                        justify-center whitespace-nowrap rounded-lg border-2 border-transparent px-3.5 text-center
                                                        text-sm
                                                        font-normal leading-none transition duration-300 hover:bg-blue-600
                                                    `}
														>
															Yeni Görsel Ekle
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									{/* images end */}
								</Modal.Section>
							</>
						)}
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveGame}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="border-slate-100 flex items-center justify-center gap-3 border-t-2 px-4 py-3">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeGame(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveGame).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default GamePuzzleMatch;
