export const formInputsData = [
	{
		label: 'Başlık',
		name: 'title',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Açıklama',
		name: 'description',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Oyun İçi Açıklama',
		name: 'inGameDescription',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},

	{
		label: 'Buton Yazısı',
		name: 'buttonText',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Arkaplan Rengi',
		name: 'backgroundPattern',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Kalan Süre (saniye)',
		name: 'remainTime',
		type: 'number',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Doğru Yanıt Metni',
		name: 'trueAnswerText',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Yanlış Yanıt Metni',
		name: 'wrongAnswerText',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Zaman Aşımı Metni',
		name: 'timeoutAnswerText',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Bonus Puan',
		name: 'bonusScore',
		type: 'number',
		error: 'Bu alan zorunludur.',
	},
];

export const modalFormInputsData = [
	{
		label: 'Soru Başlığı',
		name: 'title',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Açıklama',
		name: 'description',
		type: 'text',
		error: 'Bu alan zorunludur.',
	},
	{
		label: 'Puan',
		name: 'score',
		type: 'number',
		error: null,
	},
];
